.form-row	{
	&:before,
	&:after { content: " "; display: table; }
	&:after { clear: both; }
	&.row,.row { margin-left:-8px; margin-right:-8px; }
	&[class^="col-"], [class^="col-"]{padding-left: 8px; padding-right: 8px; }
	.form-group {
		margin-bottom: 15px;
		&:before,
		&:after { content: " "; display: table; }
		&:after { clear: both; }
		.form-control {margin-bottom: 10px;}

		&.row,.row { margin-left:-8px; margin-right:-8px; }
		&[class^="col-"], [class^="col-"]{padding-left: 8px; padding-right: 8px; }
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
.form-container {
	padding: 32px 0;
	&:before,
	&:after { content: " "; display: table; }
	&:after { clear: both; }
}
.input-group {
	//.form-control { border-left: none; }
	&.white input {border-left: none; box-shadow: none; }
	&.white .input-group-addon { background-color: #fff; }
	.fa-search {font-size: 19px;}
	&.right .form-control { border: 1px solid #ccd0d2; border-right: none; }
	&.right .input-group-addon { padding: 0; }
	&.ghost .input-group-addon {  background-color: #fff; text-align: center; border: none;}
	&.ghost .input-group-addon img {  background-color: #fff; text-align: center; margin: 0;}
	&.ghost .form-control { border: 1px solid #ccd0d2; }
	.dark {
		background-color: #292929;
		color: #fff;
	}
	.white {
		background-color: #ffffff;
	}
}

.input-container + .input-container {margin-top: 10px;}
.form-control {border-radius:4px;}
.form-control.error { border: 1px solid #FF0000 !important; }
.form-control.inline {display:inline-block;width:auto;}
.form-row:before,
.form-row:after {content: " "; display: table; }
.form-row:after {clear: both; }
.form-error {color: #FF0000; font-size: 0.8em; padding: 0 3px; position:absolute; bottom: -30px; left: 0; }
.form-section:before,
.form-section:after {content: " "; display: table; }
.form-section:after {clear: both; }
.row-control {float: right;}
.row-control input { margin-left: 20px; }

.clearfix:before,
.clearfix:after {content: " "; display: table; }
.clearfix:after {clear: both; }

.input-group-addon.dark {background-color: #292929; }

@media (max-width:768px){

    .crm.opportunity .form-section > div[class^="col-"] {
        padding-left: 0;
        padding-right: 0;
    }

}
