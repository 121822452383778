@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
table tr.gold th, table tr.gold td {
  background-color: #FFC52F;
  color: #56565a;
}

table tr.black th, table tr.black td {
  background-color: #56565a;
  color: #ffffff;
}

table tr.no-border td {
  border: none !important;
}

table tr.no-border td.border {
  border-top: 1px solid #ddd !important;
}

table td {
  color: #808080;
}

table td.total_summary {
  text-align: right;
}

table i.fa {
  margin-right: 10px;
}

table i.fa {
  margin-right: 10px;
}

table.light-grey thead tr {
  background: #E3E3E3;
  color: #ffffff;
  color: #6e6e6e;
}

table thead tr th {
  border-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

table thead tr {
  background: #6e6e6e;
  color: #ffffff;
}

table tbody tr td {
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 15px;
}

table tbody tr:nth-child(even) {
  background: #f7f7f7;
}

table tbody tr:first-child td {
  border-top: 0;
}

table tbody tr.est-parent td {
  height: 50px;
}

table tbody tr.past-due {
  background: #f76262 !important;
}

table tbody tr.past-due td {
  color: #fff;
}

table tbody tr.past-due td span {
  color: #fff;
}

table tbody tr.past-due td a {
  color: #fff;
}

table tbody tr.status-new {
  background: #a3ffe6 !important;
}

table tbody tr.status-new td {
  height: 50px;
  vertical-align: middle;
}

table tbody tr.status-hold {
  background: #ffdede;
}

table thead th:first-child,
table tbody td:first-child {
  padding-left: 35px;
}

table thead th:last-child,
table tbody td:last-child {
  padding-right: 35px;
}

table .table-form-row .table-form > div {
  height: 34px;
  float: left;
  display: inline-block;
  padding-left: 10px;
}

table .table-form-row .table-form > div select {
  margin: -4px 0;
}

.est-child-row {
  background-color: transparent !important;
}

.est-child-row td {
  height: 30px !important;
  border-bottom: 1px solid #202020;
  border-top: none !important;
}

.est-child-row td:first-child {
  border-bottom: none;
}

.panel-tables .blue-text.left {
  cursor: pointer;
  position: relative;
  width: auto;
  right: 0;
  color: #3097D1;
  font-size: 15px;
  font-weight: normal;
  border-left: 1px solid #3097D1;
  margin-left: 22px;
  padding-left: 12px;
}

.panel-tables tr td .form-control {
  height: auto;
}

.panel-tables tr td select.form-control {
  height: 36px;
  margin-bottom: -23px;
}

.panel-tables tr th:first-child {
  width: 357px;
}

.panel-tables tr th:last-child {
  width: 50px;
  text-align: center;
  padding-right: 0px;
}

.panel-tables tr th:nth-child(2) {
  width: 639px;
}

.panel-tables tr th:last-child,
.panel-tables tr th.tax,
.panel-tables tr td.tax,
.panel-tables tr td:last-child {
  width: 50px;
  text-align: center;
  padding-right: 15px;
}

.panel-tables tr td:first-child {
  width: 357px;
}

.panel-tables td div.none:last-child {
  display: block;
}

.panel-tables th.cost,
.panel-tables th.markup,
.panel-tables th.sales {
  width: 115px;
}

.panel-tables th.tax {
  background-color: #E7DEC8 !important;
}

.panel-tables th.tax,
.panel-tables td.tax {
  width: 53px;
  text-align: center;
  color: #6e6e6e;
}

.panel-tables table.install tr th:nth-child(3),
.panel-tables table.install tr th:nth-child(4),
.panel-tables table.install tr th:nth-child(5),
.panel-tables table.install tr th:nth-child(6),
.panel-tables table.install tr th:nth-child(7) {
  background-color: #d7edf8;
}

.panel-tables table.install tr td:nth-child(3),
.panel-tables table.install tr td:nth-child(4),
.panel-tables table.install tr td:nth-child(5),
.panel-tables table.install tr td:nth-child(6),
.panel-tables table.install tr td:nth-child(7) {
  background-color: #d7edf8;
}

.panel-tables table.install th td:nth-child(3) {
  width: 70px;
}

.panel-tables table.install th td:nth-child(4) {
  width: 60px;
}

.panel-tables table.install th td:nth-child(5) {
  width: 60px;
}

.panel-tables table.install th td:nth-child(6),
.panel-tables table.install th td:nth-child(7) {
  width: 100px;
}

.panel-tables .uom {
  display: inline-block;
  float: left;
}

.panel-tables .dimension {
  float: none;
  overflow: hidden;
}

.panel-tables .dimension span:first-child,
.panel-tables .dimension span:last-child {
  width: 170px;
  text-align: center;
}

.panel-tables .dimension span {
  display: inline-block;
}

.panel-tables table tr td:first-child {
  padding-left: 8px !important;
}

table.summary td {
  padding: 0 20px;
  font-size: 13px;
}

table.green {
  margin-bottom: 22px;
  width: 100%;
  border-bottom: 1px solid #95989a;
}

table.green thead tr th {
  font-weight: normal;
  border-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #079f59;
  color: #fff;
}

table.green thead tr {
  background: #6e6e6e;
  color: #ffffff;
}

table.green thead th:first-child {
  padding-left: 15px;
}

table.green thead th:last-child {
  padding-right: 15px;
}

table.green tbody tr td {
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 15px;
}

table.green tbody tr:nth-child(even) {
  background: #f7f7f7;
}

table.green tbody tr:first-child td {
  border-top: 0;
}

table.green tbody tr.past-due {
  background: #f76262;
}

table.green tbody tr.past-due td {
  color: #fff;
}

table.green tbody tr.past-due td a {
  color: #fff;
}

table.green tbody tr.status-new {
  background: #a3ffe6;
}

table.green tbody tr.status-hold {
  background: #ffdede;
}

table.green tbody th:first-child,
table.green tbody td:first-child {
  padding-left: 15px;
}

table.green tbody th:last-child,
table.green tbody td:last-child {
  padding-right: 15px;
}

table.summary_table {
  margin: 0 30px;
  float: right;
  width: 320px;
}

table.summary_table td {
  padding: 15px;
}

tr.black th {
  font-weight: normal;
}

.list-table-container {
  float: left;
  width: 100%;
}

.list-table-container:before, .list-table-container:after {
  content: " ";
  display: table;
}

.list-table-container:after {
  clear: both;
}

.list-table-container.schedule table tr:last-child {
  border-bottom: none;
}

.list-table-container.schedule table tr:last-child td {
  border-bottom: none;
}

.list-table-container.schedule table tr.black td, .list-table-container.schedule table tr.black th, .list-table-container.schedule table tr.gold td, .list-table-container.schedule table tr.gold th {
  font-weight: 500;
  text-align: left;
}

.list-table-container.schedule table tr.old td {
  color: #b3b3b3;
}

.list-table-container.schedule table tr.past-due {
  background-color: #F2C3C3 !important;
}

.list-table-container.schedule table tbody td, .list-table-container.schedule table tbody th {
  text-align: left;
  padding: 5px;
}

.list-table-container.schedule table tbody td {
  padding: 5px 10px;
}

.list-table-container.schedule table tbody td img {
  margin-left: 0;
}

.list-table-container.schedule table tbody td input[type="checkbox"] {
  margin-left: 5px;
}

.list-table-container.schedule table tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.list-table-container.schedule table tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7;
}

.list-table-container table {
  width: 100%;
}

.list-table-container table th input, .list-table-container table td input {
  margin-top: -4px;
  margin-bottom: -4px;
}

.list-table-container table th.spacer, .list-table-container table td.spacer {
  width: 40px;
}

.list-table-container table th.black, .list-table-container table td.black {
  background-color: #56565a;
  padding: 10px;
  color: #fff;
  font-weight: normal;
}

.list-table-container table th.beige, .list-table-container table td.beige {
  border-bottom: 1px solid #ccc;
  font-weight: normal;
}

.list-table-container table th.white, .list-table-container table td.white {
  background-color: #fff !important;
}

.list-table-container table th.gold, .list-table-container table td.gold {
  background-color: #fec442 !important;
  color: #56565a;
}

.list-table-container table th .fa-bars,
.list-table-container table th .fa-close, .list-table-container table td .fa-bars,
.list-table-container table td .fa-close {
  margin-right: 7px;
  float: left;
}

.list-table-container table th .fa-bars, .list-table-container table td .fa-bars {
  color: #079f59;
  font-size: 22px;
  top: 7px;
}

.list-table-container table th .fa-close, .list-table-container table td .fa-close {
  color: #e00000;
  font-size: 18px;
  top: 2px;
}

.list-table-container table thead th {
  font-size: 13px;
  padding: 10px;
  font-weight: 500;
  vertical-align: middle;
}

.list-table-container table thead.black th {
  background-color: #56565a;
  padding: 10px;
  color: #fff;
  font-weight: normal;
}

.list-table-container table thead th:first-child {
  padding-left: 40px;
}

.list-table-container table thead th:last-child {
  padding-right: 40px;
}

.list-table-container table tbody tr {
  border-bottom: 1px solid #CACBCC;
}

.list-table-container table tbody td {
  padding: 10px 15px;
  font-size: 13px;
}

.list-table-container table tbody td:last-child {
  text-align: right;
}

.list-table-container table tbody tr.child td:first-child:before {
  content: '';
  width: 20px;
  float: left;
  display: block;
  height: 20px;
}

.list-table-container table tbody tr.header.collapsed td:first-child:after {
  content: '+';
  font-weight: bold;
  font-size: 17px;
}

.list-table-container table tbody tr.header td:first-child:before {
  content: '';
  width: 0;
  float: left;
  display: block;
  height: 20px;
}

.list-table-container table tbody tr.header td:first-child:after {
  content: '-';
  font-size: 27px;
  border: 1px solid #606060;
  display: inline-block;
  height: 18px;
  position: absolute;
  line-height: 0.5;
  border-radius: 3px;
  margin-left: 10px;
  width: 21px;
  text-align: center;
}

.list-table-container.crm-contacts tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7 !important;
}

.list-table-container.crm-contacts tbody td {
  border-bottom: 0;
}

.list-table-container.crm-contacts tbody tr td:last-child {
  max-width: 100px;
}

.list-table-container.crm-contacts tbody tr td,
.list-table-container.crm-contacts thead tr th {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.list-table-container.alternating-rows tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7 !important;
}

.list-table-container.last-column-left-align tbody tr td:last-child {
  text-align: left;
}

.list-table-container.last-column-gold > table > thead > tr > th:last-child {
  background-color: #fec442;
  color: #56565a;
  font-weight: 500;
}

.list-table-container.last-column-gold > table > tbody > tr:nth-of-type(odd) > td:last-child {
  background-color: #FFF6E0;
}

.list-table-container.last-column-gold > table > tbody > tr:nth-of-type(even) > td:last-child {
  background-color: #F8EFD9;
}

.list-table-container.no-rules tbody tr {
  border: none;
}

@media (max-width: 768px) {
  .list-table-container table thead th:first-child, .list-table-container table thead th:last-child {
    padding: 10px;
  }
  .list-table-container table tbody th:first-child, .list-table-container table tbody th:last-child {
    padding: 10px;
  }
  body.crm.opportunity.files table tr > th {
    display: none;
  }
  body.crm.opportunity.files table tr > th:first-child {
    display: block;
  }
  body.crm.opportunity.files table tbody tr > * {
    display: inline-block;
  }
  body.crm.opportunity.files table tbody tr td:last-child {
    display: table-row;
    width: 100%;
    clear: both;
    float: left;
  }
}

.contact-row-header table td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row:before, .form-row:after {
  content: " ";
  display: table;
}

.form-row:after {
  clear: both;
}

.form-row.row, .form-row .row {
  margin-left: -8px;
  margin-right: -8px;
}

.form-row[class^="col-"], .form-row [class^="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.form-row .form-group {
  margin-bottom: 15px;
}

.form-row .form-group:before, .form-row .form-group:after {
  content: " ";
  display: table;
}

.form-row .form-group:after {
  clear: both;
}

.form-row .form-group .form-control {
  margin-bottom: 10px;
}

.form-row .form-group.row, .form-row .form-group .row {
  margin-left: -8px;
  margin-right: -8px;
}

.form-row .form-group[class^="col-"], .form-row .form-group [class^="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-container {
  padding: 32px 0;
}

.form-container:before, .form-container:after {
  content: " ";
  display: table;
}

.form-container:after {
  clear: both;
}

.input-group.white input {
  border-left: none;
  box-shadow: none;
}

.input-group.white .input-group-addon {
  background-color: #fff;
}

.input-group .fa-search {
  font-size: 19px;
}

.input-group.right .form-control {
  border: 1px solid #ccd0d2;
  border-right: none;
}

.input-group.right .input-group-addon {
  padding: 0;
}

.input-group.ghost .input-group-addon {
  background-color: #fff;
  text-align: center;
  border: none;
}

.input-group.ghost .input-group-addon img {
  background-color: #fff;
  text-align: center;
  margin: 0;
}

.input-group.ghost .form-control {
  border: 1px solid #ccd0d2;
}

.input-group .dark {
  background-color: #292929;
  color: #fff;
}

.input-group .white {
  background-color: #ffffff;
}

.input-container + .input-container {
  margin-top: 10px;
}

.form-control {
  border-radius: 4px;
}

.form-control.error {
  border: 1px solid #FF0000 !important;
}

.form-control.inline {
  display: inline-block;
  width: auto;
}

.form-row:before,
.form-row:after {
  content: " ";
  display: table;
}

.form-row:after {
  clear: both;
}

.form-error {
  color: #FF0000;
  font-size: 0.8em;
  padding: 0 3px;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.form-section:before,
.form-section:after {
  content: " ";
  display: table;
}

.form-section:after {
  clear: both;
}

.row-control {
  float: right;
}

.row-control input {
  margin-left: 20px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.input-group-addon.dark {
  background-color: #292929;
}

@media (max-width: 768px) {
  .crm.opportunity .form-section > div[class^="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

body.crm.dashboard #main > .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.crm.dashboard .widget-footer {
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #01bc62 0%, #3ecc89 100%) !important;
  background: -webkit-linear-gradient(top, #01bc62 0%, #3ecc89 100%) !important;
  background: linear-gradient(to bottom, #01bc62 0%, #3ecc89 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

.projects.dashboard .dashboard-my-projects .widget-body {
  padding-bottom: 200px;
}

.reports .list-table .widget-body {
  padding: 0 10px;
}

.reports .list-table .widget-body table td {
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
}

.reports .widget {
  margin-top: 30px;
}

.reports .widget .widget-inner {
  border-radius: 6px;
  border: 1px solid #ccc;
}

.reports .widget .widget-footer {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #202020;
  font-weight: bold;
}

.widget {
  font-size: 14px;
}

.widget a {
  color: #028AFF;
}

.widget:before, .widget:after {
  content: " ";
  display: table;
}

.widget:after {
  clear: both;
}

.widget .left {
  float: left;
}

.widget .right {
  float: right;
}

.widget .none {
  float: none;
  overflow: hidden;
  display: block;
  padding-right: 10px;
}

.widget .graph-box {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.widget .fa-comment-o {
  top: 1px;
  font-size: 19px;
}

.widget .fa-comment-o.active {
  color: #2788e4;
}

.widget .notice-number {
  width: 20px;
  height: 20px;
  background-color: #2788e4;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  border-radius: 99px;
  text-align: center;
  display: inline-block;
}

.widget .widget-inner {
  border-radius: 6px;
  overflow: hidden;
}

.widget .widget-inner:before, .widget .widget-inner:after {
  content: " ";
  display: table;
}

.widget .widget-inner:after {
  clear: both;
}

.widget .widget-header {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #ccc;
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%) !important;
  background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%) !important;
  background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

.widget .widget-header:before, .widget .widget-header:after {
  content: " ";
  display: table;
}

.widget .widget-header:after {
  clear: both;
}

.widget .widget-body {
  background-color: #fff;
  overflow-y: scroll;
}

.widget .widget-body:before, .widget .widget-body:after {
  content: " ";
  display: table;
}

.widget .widget-body:after {
  clear: both;
}

.widget .widget-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.widget .widget-footer {
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
}

.widget .widget-footer:before, .widget .widget-footer:after {
  content: " ";
  display: table;
}

.widget .widget-footer:after {
  clear: both;
}

.widget .widget-footer button {
  width: auto;
  font-size: 14px;
  padding-left: 17px;
  padding-right: 17px;
}

.widget .widget-list:before, .widget .widget-list:after {
  content: " ";
  display: table;
}

.widget .widget-list:after {
  clear: both;
}

.widget .widget-list .list-item {
  width: 100%;
  font-size: 0.85em;
  border-bottom: 1px solid #a2a2a2;
  padding-left: 15px;
  padding-right: 15px;
}

.widget .widget-list .list-item:last-child {
  border-bottom: none;
}

.widget .widget-list .list-item:before,
.widget .widget-list .list-item:after {
  content: " ";
  display: table;
}

.widget .widget-list .list-item:after {
  clear: both;
}

.widget .widget-list .list-item .price-table td:first-child {
  padding-right: 8px;
  text-align: right;
}

.widget .widget-list .list-item {
  padding: 10px 15px;
}

.widget .widget-list .list-item p {
  margin-bottom: 10px;
}

.widget .widget-list .list-item input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.widget .widget-list .list-item .title {
  font-weight: bold;
}

.widget .widget-list label {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%) !important;
  background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%) !important;
  background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

.widget .widget-list label .small {
  font-size: 12px;
  color: #45A2FA;
}

.widget .widget-list label p {
  margin-bottom: 0;
}

.widget .widget-list label.pastdue {
  border: 1px solid #ca0b0b;
}

.widget .widget-list label.pastdue .title {
  font-weight: bold;
  color: #ca0b0b;
}

.widget .widget-list label.pastdue .date {
  font-weight: bold;
  color: #ca0b0b;
}

.widget.notes textarea {
  height: 100%;
  border: none;
  font-size: 16px;
  padding: 20px;
  line-height: 1.5;
  resize: none;
}

.widget.notes .widget-body {
  overflow: hidden;
}

.widget.notes .widget-footer {
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
}

.widget.tasks .widget-list {
  padding-top: 10px;
}

.widget.tasks .widget-list td:first-child {
  padding-right: 8px;
}

.widget.tasks .widget-header .all_tasks {
  width: 30%;
  display: inline-block;
  float: left;
  text-align: right;
  padding: 6px 0;
}

.widget.tasks .widget-header .task_filters {
  width: 70%;
  display: inline-block;
  float: left;
}

.widget.tasks .widget-header .form-group {
  margin-bottom: 0;
}

.widget.graph .widget-body {
  height: auto;
  overflow: hidden;
}

.widget .form-row:before, .widget .form-row:after {
  content: " ";
  display: table;
}

.widget .form-row:after {
  clear: both;
}

.widget .form-row.row, .widget .form-row .row {
  margin-left: -4px;
  margin-right: -4px;
}

.widget .form-row[class^="col-"], .widget .form-row [class^="col-"] {
  padding-left: 4px;
  padding-right: 4px;
}

.widget .form-row .form-group {
  margin-bottom: 0;
}

.widget .form-row .form-group:before, .widget .form-row .form-group:after {
  content: " ";
  display: table;
}

.widget .form-row .form-group:after {
  clear: both;
}

.widget .form-row .form-group .form-control {
  margin-bottom: 0;
}

.widget .form-row .form-group.row, .widget .form-row .form-group .row {
  margin-left: -4px;
  margin-right: -4px;
}

.widget .form-row .form-group[class^="col-"], .widget .form-row .form-group [class^="col-"] {
  padding-left: 4px;
  padding-right: 4px;
}

.widget-row {
  padding-top: 30px;
}

.widget-row:before, .widget-row:after {
  content: " ";
  display: table;
}

.widget-row:after {
  clear: both;
}

.color-wheel {
  width: 40px;
  height: 40px;
  border-radius: 99px;
  border: 2px solid #fff;
  display: inline-block;
}

.color-wheel img {
  width: 100%;
}

.color-wheel button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.crm.tasks .widget.tasks .widget-controls {
  border-bottom: none;
}

.crm.tasks .widget.notes .widget-inner {
  border: 1px solid #ccc;
}

.crm.tasks .widget .widget-footer {
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #f5f5f5 100%) !important;
  background: -webkit-linear-gradient(top, #f5f5f5 0%, #f5f5f5 100%) !important;
  background: linear-gradient(to bottom, #f5f5f5 0%, #f5f5f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

.crm.tasks .widget {
  height: auto !important;
}

.crm.tasks .widget-body {
  height: auto !important;
  overflow: visible;
}

[id^="graph-"] {
  height: 100%;
}

[id^="google-visualization-errors"] {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[id^="google-visualization-errors"] > div > span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background: #f5f5f5 !important;
  color: #202020 !important;
}

.widget.tabbed .widget-tabs .tab {
  float: left;
  display: inline-block;
  background-color: #56565a;
  color: #fefefe;
  border-radius: 3px 3px 0 0;
  padding: 13px 20px 8px;
  font-size: 13px;
  margin-right: 5px;
}

.widget.tabbed .widget-tabs .tab.current {
  color: #56565a;
  background-color: #fefefe;
}

.widget.tabbed .widget-control {
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #fefefe 0%, #f1f1f1 100%) !important;
  background: -webkit-linear-gradient(top, #fefefe 0%, #f1f1f1 100%) !important;
  background: linear-gradient(to bottom, #fefefe 0%, #f1f1f1 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
  padding: 18px;
}

.widget.tabbed .widget-control .control {
  display: inline-block;
  float: left;
  padding: 0 7px;
  height: 34px;
}

.widget.tabbed .widget-control .control span {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.widget.tabbed .widget-control .control:first-child {
  padding-left: 0;
  padding-right: 7px;
}

.widget.tabbed .widget-control .control:last-child {
  padding-left: 7px;
  padding-right: 0;
}

.widget.tabbed .widget-body {
  padding: 8px 18px 18px;
  height: 641px;
}

.widget.tabbed .widget-body .separator {
  color: #198dfc;
  padding-bottom: 5px;
  border-bottom: 3px solid #9d9d9d;
  font-weight: bold;
  margin-top: 10px;
}

.widget.tabbed .widget-body .checkblocks {
  padding-top: 3px;
}

.widget.tabbed .widget-body .checkblock {
  border: 1px solid #9d9d9d;
  border-radius: 3px;
  margin-top: 8px;
  padding: 10px;
}

.widget.tabbed .widget-body .checkblock .right {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.widget.tabbed .widget-body .checkblock .right .date {
  padding-right: 25px;
  display: inline-block;
}

.widget.tabbed .widget-body .checkblock .right i.fa {
  font-size: 22px;
  color: #9d9d9d;
}

.widget.tabbed .widget-body .checkblock .right i.fa.active {
  color: #198dfc;
}

.widget.tabbed .widget-body .checkblock .right .circle {
  border-radius: 99px;
  color: #fff;
  background-color: #198dfc;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 27px;
}

.widget.tabbed .widget-body .checkblock .none {
  line-height: 1.3;
}

.widget.tabbed .widget-body .checkblock .none .small {
  font-size: 0.8em;
}

.widget.tabbed .widget-body .checkblock input[type="checkbox"] {
  margin-right: 10px;
}

@media (min-width: 769px) {
  .widget.google-graph {
    height: auto;
  }
  .reports .widget {
    height: auto;
  }
  .widget .widget-body {
    height: 450px;
  }
  .widget.opportunities .widget-body {
    height: 450px;
  }
  .widget.messages .widget-body {
    height: 403px;
  }
  .widget.tasks .widget-body {
    height: 395px;
  }
  .notes-widget {
    padding-left: 50px;
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .widget-row {
    padding-top: 0;
  }
  .widget {
    padding-left: 0;
    padding-right: 0;
    clear: both;
    margin-bottom: 0;
  }
  .widget .widget-inner {
    border-radius: 0;
  }
  .widget .widget-header {
    font-weight: 500;
    font-size: 13px;
    border-top: 1px solid #ccc;
  }
  .widget .widget-body {
    overflow-y: visible;
    padding-bottom: 15px;
  }
  .widget .form-row .form-group .form-control {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 320px) {
  .widget.tasks .form-group {
    padding: 0;
  }
}

.red {
  color: #d60027;
}

.blue {
  color: #2091FC;
}

.grey {
  background-color: #f5f5f5;
}

.blue-meta {
  color: #3097D1;
}

.blue-link {
  color: #3097D1;
}

.blue-text {
  color: #45A2FA;
}

.green-text {
  color: #15b165;
}

.gold {
  color: #ffc52f;
}

.gold-text {
  color: #cc9a16;
}

.beige {
  background-color: #f5f5f5;
}

.row.grey {
  background-color: #f7f7f7;
}

.dark-green-text {
  color: #159a59;
}

.grey-button {
  background-color: #9a9a9a;
  color: #fff;
  padding: 5px 8px;
  text-align: center;
}

.transparent-button {
  padding: 5px 8px;
}

.highlight-green {
  background-color: #dff0d8 !important;
}

.highlight-red {
  background-color: #f2dede !important;
}

i.fa.fa-circle {
  top: 4px;
}

i.fa.fa-circle.active {
  color: #8BEBBC;
}

i.fa.fa-circle.yellow {
  color: #FCDC8C;
}

i.fa.fa-circle.inactive {
  color: #E88A8C;
}

.box-body {
  padding: 25px 15px;
}

.grey-box {
  background-color: #f5f5f5;
}

.grey-box.aside:before {
  content: '';
  min-height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  display: block;
  position: fixed;
  margin: 0 -15px;
  top: 0;
  bottom: 0;
}

.grey-box:before, .grey-box:after {
  content: " ";
  display: table;
}

.grey-box:after {
  clear: both;
}

.grey-box .grey-box-body {
  padding: 25px 15px;
}

.grey-box.padded {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
}

.grey-box.collapsed-form {
  margin-bottom: 15px;
}

.grey-box.collapsed-form > .row {
  margin-top: 10px;
}

.grey-box.collapsed-form + .collapse {
  padding-bottom: 15px;
}

.gold-bar {
  background-color: #ffc52f;
  padding-left: 15px;
  padding-right: 15px;
  height: 59px;
  z-index: 100;
}

.gold-bar .search-bar {
  width: 400px;
}

.gold-bar .search-bar .input-group-addon {
  background-color: #fff;
  border: none;
}

.gold-bar .search-bar .none {
  display: block;
}

.black-bar {
  background-color: #666;
}

.grey-bar {
  background-color: #56565a;
  padding-left: 30px;
  padding-right: 30px;
  height: 59px;
  z-index: 100;
}

.grey-bar > div {
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.grey-bar h1, .grey-bar h2, .grey-bar h3, .grey-bar h4, .grey-bar h5 {
  margin: 0;
}

.fa-close {
  color: #e00000;
  font-size: 18px;
  top: 2px;
  cursor: pointer;
}

.btn-remove-mtf {
  vertical-align: top;
  cursor: pointer;
}

.inline {
  display: inline-block;
  float: left;
}

.auto-width {
  width: auto !important;
  cursor: pointer;
}

.btn {
  font-size: 1.6rem;
  cursor: pointer;
}

button.auto-width {
  width: auto !important;
  cursor: pointer;
}

.btn-sunglow {
  background: var(--sunglow);
  color: #56565a;
  cursor: pointer;
}

.copy-estimate {
  cursor: pointer;
}

.projects.estimates .gold-bar a.button {
  padding: 7px 20px 7px 20px;
  position: relative;
  top: 5px;
  color: #fff;
}

.gold-button {
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  width: 203px;
  color: #56565a !important;
  font-size: 15px;
  border-radius: 4px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 25px;
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #ffc52f 0%, #edaa10 100%) !important;
  background: -webkit-linear-gradient(top, #ffc52f 0%, #edaa10 100%) !important;
  background: linear-gradient(to bottom, #ffc52f 0%, #edaa10 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

.gold-button:hover {
  text-decoration: none;
}

.black-button {
  background-color: #56565a;
  color: #fff;
  width: 203px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.black-button:hover {
  color: #ccc;
}

.red-button {
  background-color: #d9534f;
  color: #fff;
  width: 203px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.red-button:hover {
  color: #ccc;
}

.transparent-button {
  background-color: transparent;
  color: #56565a;
  width: 203px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: normal;
}

.green-button {
  background-color: #079f59;
  color: #fff;
  cursor: pointer;
}

.lime-button {
  background-color: #1fd984;
  color: #fff;
  width: 203px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.lime-button.inline {
  padding: 0 10px;
}

.gold-button.small {
  max-width: 100px;
}

.gold-button a {
  color: #56565a;
  cursor: pointer;
}

button.duplicate {
  font-size: 16px;
  text-align: center;
}

button.duplicate i.fa {
  margin-right: 0;
}

button.small-green {
  background: #2dce80;
  background: -webkit-linear-gradient(-45deg, #2dce80 0%, #1da461 100%);
  background: linear-gradient(135deg, #2dce80 0%, #1da461 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2dce80', endColorstr='#1da461',GradientType=1 );
  border-radius: 11px;
  color: #fff;
  border: none;
  width: 34px;
  height: 24px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 3px;
}

.header-button {
  font-size: 14px;
  padding: 5px 0;
  cursor: pointer;
}

.header-button img {
  position: relative;
  top: -1px;
  margin: 0 5px;
}

.minus-button {
  border: 1px solid #202020;
  border-radius: 2px;
  width: 17px;
  height: 17px;
  display: inline-block;
  top: 4px;
  margin-right: 6px;
  font-size: 22px;
  cursor: pointer;
}

.minus-button:before {
  content: '-';
  line-height: 14px;
  text-align: center;
  position: relative;
  display: block;
}

.minus-button.plus {
  top: 2px;
  font-size: 19px;
}

.minus-button.plus:before {
  content: '+';
  line-height: 11px;
  text-align: center;
  position: relative;
  display: block;
}

.cross-button {
  border: 1px solid #ccc;
  padding: 4px 9px;
  cursor: pointer;
}

div[class="-button"] {
  position: relative;
  cursor: pointer;
}

div[class="-button"].inline {
  display: inline-block;
  float: left;
}

.blue-arrow {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #198DFC;
  text-align: center;
  top: 4px;
  cursor: pointer;
}

.blue-arrow i {
  line-height: 32px;
}

img[src*="entry-add.svg"] {
  width: 26px;
  height: 26px;
  top: -1px;
  margin-right: 0;
  margin-left: 6px;
  cursor: pointer;
}

.person-rows img[src*="entry-add.svg"] {
  float: right;
  margin-right: 0;
}

img[src*="entry-delete.svg"] {
  width: 21px;
  height: 34px;
  cursor: pointer;
}

img[src*="cancel.svg"] {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

img[src*="lightbulb.jpg"] {
  margin-right: 12px;
}

.modal-header.dark-title {
  background-color: #292929;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

@font-face {
  font-family: 'HelveticaNeue-Heavy';
  src: url("../fonts/HelveticaNeue-Heavy.eot");
  src: url("../fonts/HelveticaNeue-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-Heavy.woff2") format("woff2"), url("../fonts/HelveticaNeue-Heavy.woff") format("woff"), url("../fonts/HelveticaNeue-Heavy.ttf") format("truetype"), url("../fonts/HelveticaNeue-Heavy.svg#HelveticaNeue-Heavy") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url("../fonts/HelveticaNeue-MediumExt.eot");
  src: url("../fonts/HelveticaNeue-MediumExt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-MediumExt.woff2") format("woff2"), url("../fonts/HelveticaNeue-MediumExt.woff") format("woff"), url("../fonts/HelveticaNeue-MediumExt.ttf") format("truetype"), url("../fonts/HelveticaNeue-MediumExt.svg#HelveticaNeue-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Condensed';
  src: url("../fonts/HelveticaNeue-Condensed.eot");
  src: url("../fonts/HelveticaNeue-Condensed.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-Condensed.woff2") format("woff2"), url("../fonts/HelveticaNeue-Condensed.woff") format("woff"), url("../fonts/HelveticaNeue-Condensed.ttf") format("truetype"), url("../fonts/HelveticaNeue-Condensed.svg#HelveticaNeue-Condensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --sunglow: #ffc52f;
}

* {
  position: relative;
}

body {
  overflow-x: hidden;
  background: #ffffff;
  font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
  font-size: 1.6rem;
  color: #56565a;
  font-size: 15px;
}

body.crm.dashboard {
  background-color: #022949;
}

body.projects.dashboard {
  background-color: #292929;
}

body.projects.dashboard #main {
  padding-top: 15px;
}

body.management:not(.estipro) {
  background-color: #292929;
}

body.management:not(.estipro) #main {
  padding-top: 15px;
}

body.estipro.scope {
  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
}

body.estipro.scope .sticky {
  position: fixed;
  top: 85px;
  width: 100%;
}

body.estipro.scope .sticky .tabs {
  display: block !important;
  background-color: white !important;
  width: 100%;
  height: 100px;
  top: -100px;
  position: absolute;
}

body.estipro.scope .sticky .tabs #main-menu {
  position: absolute;
  right: 115px;
  bottom: 0px;
}

body.estipro.scope .sticky .tabs #main-menu li::after {
  z-index: 0;
}

body.estipro.scope .sticky .tabs #main-menu li a {
  z-index: 1;
}

body.estipro.scope .sticky + #detail-controls {
  margin-top: 200px;
}

.location-panel-body:before {
  display: none;
}

.ui-datepicker {
  z-index: 1051 !important;
}

body.estipro i.fa-bars {
  color: #079f59;
}

table {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h3 {
  font-size: 26px;
}

p {
  margin-bottom: 9px;
}

p.large {
  font-size: 26px;
}

a {
  color: #45A2FA;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

button.collapse {
  display: block;
}

b, strong {
  font-weight: 600;
}

i.fa {
  cursor: pointer;
}

div:before, div:after {
  content: " ";
  display: table;
}

div:after {
  clear: both;
}

.search-button {
  cursor: pointer;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

select {
  cursor: pointer;
}

@media (min-width: 1300px) {
  body.estipro.detail {
    min-width: 1300px;
  }
}

body.projects.files #main .container-fluid {
  width: 100%;
}

tr.warning th, tr.warning td {
  background-color: #fcf8e3;
}

tr.error th, tr.error td {
  background-color: #f2dede;
}

.spacer:before, .spacer:after {
  content: " ";
  display: table;
}

.spacer:after {
  clear: both;
}

.edit-input {
  cursor: pointer;
}

.edit-link {
  color: #028aff !important;
  font-size: 16px !important;
  font-weight: normal;
  text-decoration: underline;
}

.small {
  font-size: 0.90em;
}

.left {
  float: left;
  display: inline-block;
}

.right {
  float: right;
}

.right-corner {
  position: absolute;
  right: 0;
  top: 0;
}

.none {
  float: none;
  overflow: hidden;
  display: inline-block;
}

.task-helper-task-list-item .none {
  width: 80%;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.sep {
  display: inline-block;
  width: 2px;
  height: 20px;
  background-color: #a5a5a5;
  margin: 0 7px;
  top: 5px;
}

.no-border {
  border: none;
}

.transparent {
  background-color: transparent;
}

img + label + hr {
  margin-top: 0;
}

.document-icon {
  font-size: 27px;
  margin-right: 11px;
}

.document-icon.ai-icon:after {
  content: 'Ai';
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
}

.file-folder {
  font-size: 27px;
  position: relative;
  top: 2px;
  margin-right: 11px;
}

.fa-file-o {
  font-size: 27px;
  position: relative;
  top: 2px;
  margin-right: 11px;
}

.lightning-cloud {
  font-size: 27px;
  float: right;
}

.lightning-cloud i.fa.fa-bolt {
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%) rotate(23deg);
}

tr[data-child] {
  display: none;
}

.mail-body {
  height: 100%;
  font-size: 16px;
}

.mail-header {
  background-color: #56565a;
  padding: 15px 0;
  text-align: center;
}

.mail-content {
  height: 100%;
  padding-top: 50px;
}

.mail-content h1, .mail-content h2, .mail-content h3, .mail-content h4, .mail-content p {
  margin-bottom: 30px;
}

.mail-footer {
  height: 50px;
}

.jumbotron {
  padding: 40px;
}

@media (min-width: 1200px) {
  .section {
    padding-left: 35px;
    padding-right: 35px;
  }
  .padded-2x {
    padding: 20px !important;
  }
  .padded-3x {
    padding: 30px !important;
  }
  .padded-4x {
    padding: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .padded-2x {
    padding: 15px !important;
  }
  .padded-3x {
    padding: 25px !important;
  }
  .padded-4x {
    padding: 35px !important;
  }
}

@media (min-width: 400px) and (max-width: 768px) {
  .padded-2x {
    padding: 10px !important;
  }
  .padded-3x {
    padding: 20px !important;
  }
  .padded-4x {
    padding: 30px !important;
  }
}

@media (max-width: 400px) {
  .padded-2x {
    padding: 5px !important;
  }
  .padded-3x {
    padding: 15px !important;
  }
  .padded-4x {
    padding: 25px !important;
  }
}

#approvals #apm {
  top: 12px;
  right: 40px;
}

@media (max-width: 768px) {
  #approvals #apm {
    top: -6px;
    right: 0px;
  }
}

.noxform strong {
  text-transform: none;
}

.section-heading {
  background: #6e6e6e;
  color: #ffffff;
  font-weight: 700;
  padding: 10px;
  padding-left: 40px;
}

.placeholder-button {
  visibility: hidden;
  width: 0;
  height: 34px;
}

input[disabled] + .save-rename {
  display: none;
}

input + .save-rename {
  display: inline-block;
}

.nav-tabs li.active {
  color: #56565a;
}

.popover {
  top: 30px;
}

.popover:before {
  content: '';
  position: relative;
  display: block;
  top: -10px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
}

.poppable-trigger {
  display: inline-block;
}

.popover-close {
  position: absolute;
  right: 10px;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
}

.popover-content:before {
  content: '';
  position: relative;
  display: block;
  top: -28px;
  left: -3px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid white;
}

.horizontal-rule {
  margin-bottom: 20px;
}

.horizontal-rule div {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.scope-input-group {
  float: left;
  display: inline-block;
}

/* general styles */
#masthead select.user_select {
  background-color: transparent;
  border: none;
}

#masthead select.status_select {
  background-color: transparent;
  border: none;
}

.charcoal.list {
  background-color: #444;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5px;
  overflow: hidden;
}

.charcoal.list div {
  border-bottom: 1px solid #ccc;
}

.charcoal.list div:before, .charcoal.list div:after {
  content: " ";
  display: table;
}

.charcoal.list div:after {
  clear: both;
}

.charcoal.list div span {
  margin: 4px 0;
  display: inline-block;
}

.charcoal.list .fa {
  margin-right: 10px;
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: #202020;
  display: inline-block;
  float: left;
  padding: 6px 0;
}

.charcoal.list .fa:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.datepicker-box {
  display: inline-block;
  font-family: 'FontAwesome';
}

.datepicker-box:before {
  content: "\f073";
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  color: #3097D1;
  z-index: 1;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.datepicker {
  font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
}

.add-separator-right {
  padding-right: 50px;
}

.add-separator-right:after {
  content: '';
  display: block;
  position: absolute;
  right: 24px;
  height: 100%;
  width: 2px;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.section-header {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #202020;
}

.spinner-underlay {
  position: absolute;
  right: 4px;
  z-index: 100;
  font-size: 7px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
}

#masthead .header-select, .gold-bar .header-select {
  width: 130px;
  display: inline-block;
}

.header-input {
  width: 150px;
  display: inline-block;
}

.checkbox label {
  padding-left: 24px;
  font-size: 1.4rem;
  cursor: default;
}

ul.reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
  align-items: center;
}

hr {
  margin: 12px 0;
  background-color: #c4c4c4;
  color: #c4c4c4;
  border-color: #c4c4c4;
}

/* button */
.item-link {
  cursor: pointer;
}

.item-link input {
  cursor: pointer;
}

input[disabled].dynamic-width {
  background-color: transparent;
  border-color: transparent;
  width: 100px;
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
}

.horizontal-blocks {
  margin: 20px 0;
}

.horizontal-blocks div {
  float: left;
  display: inline-block;
  margin-right: 60px;
}

div[class="col-md-12"]:before,
div[class="col-md-12"]:after {
  content: " ";
  display: table;
}

div[class="col-md-12"]:after {
  clear: both;
}

div[class*="-bar"]:before,
div[class*="-bar"]:after {
  content: " ";
  display: table;
}

div[class*="-bar"]:after {
  clear: both;
}

div[class*="-bar"] .bar-label {
  margin: 0;
  font-size: 21px;
  line-height: 36px;
}

div[class*="-bar"] .right button {
  height: 38px;
  line-height: 38px;
}

div[class*="-bar"] .right select {
  width: 160px;
  margin-right: 11px;
  cursor: pointer;
}

.brand.dash {
  height: 100%;
  width: 285px;
  display: block;
  float: left;
  overflow: hidden;
  padding: 2px;
  margin-left: 15px;
}

.brand.dash img {
  width: 100%;
  height: 30px;
}

textarea.form-control {
  height: 90px;
}

.checkbox-box {
  position: relative;
  top: 5px;
}

.padded {
  padding: 10px;
}

.navbar .container-fluid {
  height: 100%;
}

nav {
  height: 34px;
}

.navbar-nav {
  flex-direction: inherit;
  height: 100%;
  margin: 0;
}

.navbar-nav a {
  text-decoration: none;
}

.navbar-nav.navbar-right li:last-child {
  padding-right: 24px;
}

.navbar-nav.navbar-right li .fa {
  font-size: 18px;
  position: relative;
  top: 2px;
}

.navbar-nav ul {
  height: 100%;
  margin: 0;
  padding: 0;
}

.navbar-nav > li {
  height: 100%;
  padding: 7px 15px;
  display: inline-block;
  float: left;
}

.navbar-nav > li > a {
  font-size: 13px;
  padding: 0;
}

.navbar-nav li.dark ~ .dark {
  margin-left: 0;
}

.nav > li.active {
  background-color: black;
}

.nav > li.active > a {
  color: #ffc52f;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
  color: #ffc52f;
}

.jumbotron {
  border: 1px solid #c4c4c4;
}

.jumbotron.square {
  border-radius: 0 !important;
}

.jumbotron h1 {
  font-size: 42px;
}

.jumbotron + .jumbotron {
  border-top: 0;
}

.mega-menu {
  width: 580px;
  z-index: 10;
  height: 255px;
  left: 20px;
  border-radius: 4px;
  overflow: hidden;
  display: none;
  position: absolute;
}

.mega-menu .mega-inner {
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 17px -2px #aeaeae;
  height: 250px;
}

.mega-menu .mega-inner .none {
  display: block;
}

.mega-menu .mega-inner:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  top: -5px;
  left: 20px;
}

.mega-menu:hover {
  display: block;
}

.mega-menu .list-header {
  background-color: #a5a5a5;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 14px;
}

.mega-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mega-menu ul li {
  border-bottom: 1px solid #ccc;
  color: #202020;
}

.mega-menu ul li:last-child {
  border-bottom: none;
}

.mega-menu ul li a {
  padding: 6px 10px;
  color: #202020;
  display: block;
}

.mega-menu .left {
  height: 100%;
  border-right: 1px solid #ccc;
}

.mega-menu a:hover {
  text-decoration: none;
}

.mega-menu .gold-button {
  margin: 8px;
  height: 40px;
  line-height: 26px;
}

.mega-menu .gold-button strong {
  font-size: 1.5em;
  margin-right: 6px;
}

.form-heading {
  font-size: 20px;
}

.form-label {
  font-size: 15px;
}

.collapsed-form button.collapse:after {
  display: inline-block;
  content: '-';
  position: absolute;
  width: 100%;
  display: block;
  color: #666;
  height: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.collapsed-form button.collapsed:after {
  display: inline-block;
  content: '+';
  position: absolute;
  width: 100%;
  display: block;
  color: #666;
  height: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -37%);
  -o-transform: translate(-50%, -37%);
  -ms-transform: translate(-50%, -37%);
  -moz-transform: translate(-50%, -37%);
  -webkit-transform: translate(-50%, -37%);
}

.collapsed-form button.collapse,
.collapsed-form button.collapsed {
  display: inline-block;
  outline: none;
  line-height: 14px;
  padding: 1px 1px 2px 2px;
  border: 1px solid #666;
  border-radius: 4px;
  height: 21px;
  width: 21px;
  background-color: transparent;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.float-left.aside {
  float: left;
  width: 90px;
}

.float-none.aside {
  padding-left: 10px;
  float: none;
  overflow: hidden;
}

.spacer:before,
.spacer:after {
  content: " ";
  display: table;
}

.spacer:after {
  clear: both;
}

.opportunity-header .left {
  width: 300px;
  float: left;
  padding-right: 10px;
}

.opportunity-header .name {
  clear: both;
  display: block;
  font-size: 0.8em;
}

.opportunity-header input[type="button"] {
  margin: 0 10px;
}

.opportunity-header img {
  margin: 0 !important;
}

.contact-repeater {
  margin-bottom: 8px;
}

.contact-repeater .left {
  width: 90px;
}

.contact-repeater select {
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  -webkit-appearance: none;
  -webkit-border-radius: 4px 0 0 4px;
}

.contact-repeater input {
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.contact-repeater select, .contact-repeater input {
  border: 1px solid #ccc;
  cursor: pointer;
}

.contact-repeater .input-group {
  width: 100%;
}

.dot-status.gold {
  background-color: gold;
}

.dot-status {
  display: inline-block;
  float: left;
  height: 30px;
  width: 30px;
  border-radius: 99px;
  margin-right: 10px;
}

.percent-bar, .status-bar {
  display: inline-block;
}

.status-bar {
  background-color: #666;
  color: #fff;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 10px;
  width: 100%;
  text-align: center;
}

.status-bar.needs-analysis {
  background-color: #FEC442;
}

.status-bar.presentation {
  background-color: #198DFC;
}

.status-bar.on-hold {
  background-color: #DD0A16;
}

.status-bar.won {
  background-color: #1A9C5B;
}

.percent-bar {
  width: 100px;
  height: 9px;
  border: 1px solid #a2a2a2;
  border-radius: 99px;
  box-shadow: 0px 2px 9px -2px #a2a2a2 inset;
  overflow: hidden;
}

.percent-bar .bar {
  height: 100%;
  background-color: gold;
  border-radius: 0 99px 99px 0;
  box-shadow: 0px 2px 9px -2px #a2a2a2 inset;
}

.p0 .bar {
  position: absolute;
  width: 0%;
}

.p5 .bar {
  position: absolute;
  width: 5%;
}

.p10 .bar {
  position: absolute;
  width: 10%;
}

.p15 .bar {
  position: absolute;
  width: 15%;
}

.p20 .bar {
  position: absolute;
  width: 20%;
}

.p25 .bar {
  position: absolute;
  width: 25%;
}

.p30 .bar {
  position: absolute;
  width: 30%;
}

.p35 .bar {
  position: absolute;
  width: 35%;
}

.p40 .bar {
  position: absolute;
  width: 40%;
}

.p45 .bar {
  position: absolute;
  width: 45%;
}

.p50 .bar {
  position: absolute;
  width: 50%;
}

.p55 .bar {
  position: absolute;
  width: 55%;
}

.p60 .bar {
  position: absolute;
  width: 60%;
}

.p65 .bar {
  position: absolute;
  width: 65%;
}

.p70 .bar {
  position: absolute;
  width: 70%;
}

.p75 .bar {
  position: absolute;
  width: 75%;
}

.p80 .bar {
  position: absolute;
  width: 80%;
}

.p85 .bar {
  position: absolute;
  width: 85%;
}

.p90 .bar {
  position: absolute;
  width: 90%;
}

.p100 .bar {
  position: absolute;
  width: 100%;
}

.navbar {
  background: #56565a;
  margin: 0;
  color: #ffffff;
  padding: 1px;
  z-index: 101;
}

.navbar a {
  color: #ffffff;
  padding: 1px;
}

.nav .dark {
  background: #292929;
  padding-left: 30px;
}

.nav .dark:last-child {
  padding-right: 20px;
}

.slider-box {
  border-radius: 4px;
  border: 1px solid #ccc;
  position: relative;
  padding: 6px 15px;
  margin: 0;
  background-color: #fff;
}

.slider-box:before, .slider-box:after {
  content: " ";
  display: table;
}

.slider-box:after {
  clear: both;
}

.slider-box .slider-no {
  display: inline-block;
  padding: 0 0 0 6px;
}

.slider-box .slider-container {
  margin: 5px 0 0 !important;
}

.slider-box .none {
  height: 20px;
  display: block;
}

.slider-box .none:before, .slider-box .none:after {
  content: " ";
  display: table;
}

.slider-box .none:after {
  clear: both;
}

.suggestion-box {
  border: 1px solid #cecece;
  background-color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 10px;
  z-index: 10;
  border-radius: 0 0 6px 6px;
  border-width: 0 1px 1px 1px;
}

.suggestion-box .tag {
  display: block;
  padding: 3px;
  font-size: 14px;
}

.page-heading {
  color: #56565a;
  padding: 15px 20px;
}

.page-heading .page-title {
  font-size: 4.2rem;
  font-weight: 700;
  margin: 0;
}

.page-heading .page-title + select {
  margin-left: 40px;
  cursor: pointer;
}

.page-heading .btn {
  padding-top: 9px;
  padding-bottom: 10px;
}

.page-heading .form-control {
  height: 46px;
}

.stats {
  margin-left: 30px;
  height: 100%;
  font-size: 0.6em;
}

.stats small {
  font-size: 1.4em;
}

.stats strong {
  font-size: 1.5em;
  text-transform: uppercase;
  line-height: 1;
}

.stats > div {
  float: left;
  height: 100%;
  padding: 0 10px;
  border-right: 1px solid #ccc;
}

.stats > div:last-child {
  border-right: none;
}

.stats > div:first-child {
  padding-left: 0;
}

.stats li {
  vertical-align: top;
  padding-left: 10px;
  margin-left: 40px;
}

.stats big, .stats .stats strong, .stats .stats small {
  display: block;
}

.stats big {
  font-size: 2.6em;
  font-weight: 700;
  line-height: 1;
}

.stats > span {
  display: inline-block;
  margin-right: 40px;
}

.stats .files a {
  margin-right: 30px;
}

.header-icon {
  cursor: pointer;
  font-size: 15px;
  position: relative;
  display: inline-block;
  font-weight: normal;
}

.header-icon:before, .header-icon:after {
  content: " ";
  display: table;
}

.header-icon:after {
  clear: both;
}

.header-icon button {
  border-radius: 6px;
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #f5f5f5 100%) !important;
  background: -webkit-linear-gradient(top, #f5f5f5 0%, #f5f5f5 100%) !important;
  background: linear-gradient(to bottom, #f5f5f5 0%, #f5f5f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
  border-radius: 6px;
  box-shadow: none;
  border: none;
  width: auto;
  padding: 0 15px;
  margin-right: 10px;
}

.header-icon button img {
  height: 40%;
  top: -1px;
}

.header-icon img {
  top: 4px;
  margin-right: 8px;
}

.header-icon.file, .header-icon.folder {
  height: 45px;
}

.header-icon.folder {
  margin-right: 10px;
}

.header-icon:before, .header-icon:after {
  content: " ";
  display: table;
}

.header-icon:after {
  clear: both;
}

.header-icon .icon {
  line-height: 0;
  float: left;
  font-size: 34px;
  margin: 0 5px;
}

.header-icon .text {
  top: 7px;
}

.header-icon .plus {
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.header-icon .plus:before {
  content: '+';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border: 2px solid #56565a;
  border-radius: 1000px;
  padding: 1px;
  width: 18px;
  height: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 9px;
  font-size: 18px;
}

.header-icon .fa-folder-o {
  font-size: 45px;
  position: relative;
}

.contact-card {
  display: inline-block;
  float: left;
  border-radius: 8px;
}

.contact-card.active button {
  color: #56565a;
  border: 1px solid #A8A8AA;
}

.contact-card.active button strong {
  color: #3097D1;
}

.contact-card button strong {
  color: #a2a2a2;
}

.contact-card button {
  color: #a2a2a2;
  width: 100%;
  background: #d6d6d6 !important;
  background: -moz-linear-gradient(top, #eaeaea 0%, #ffffff 100%) !important;
  background: -webkit-linear-gradient(top, #eaeaea 0%, #ffffff 100%) !important;
  background: linear-gradient(to bottom, #eaeaea 0%, #ffffff 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
  border-radius: 4px;
  border: 1px solid #a2a2a2;
  padding: 5px 12px;
}

.panel-description {
  padding-left: 70px;
  margin: 0;
}

.sign-notes-controls {
  margin-bottom: 10px;
}

.sign-notes-controls:before, .sign-notes-controls:after {
  content: " ";
  display: table;
}

.sign-notes-controls:after {
  clear: both;
}

.sign-notes-controls div {
  float: left;
  display: inline-block;
  width: 160px;
}

#opportunity-requirements {
  padding-left: 13px;
  padding-right: 13px;
}

#opportunity-requirements input {
  padding-left: 25px;
}

#opportunity-requirements input[disabled]:checked {
  box-shadow: 1px 1px 3px red;
  display: none;
}

#opportunity-requirements input[disabled]:checked + label:before {
  content: " ";
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: #FEC442;
  display: inline-block;
  margin-right: 5px;
}

#summary-section .summary-total {
  margin-bottom: 29px;
}

#summary-section .summary-controls select,
#summary-section .summary-controls .btn {
  display: inline-block;
  float: right;
  cursor: pointer;
}

#summary-section .summary-controls .btn {
  margin-left: 15px;
  cursor: pointer;
}

div.darkgreen {
  background-color: #1A9E5C;
}

div.darkgreen:before, div.darkgreen:after {
  content: " ";
  display: table;
}

div.darkgreen:after {
  clear: both;
}

div.darkgreen div:last-child {
  padding-right: 0;
}

div.darkgreen div.light-green {
  background-color: #30D787;
}

div.darkgreen div.white {
  background-color: #fff;
}

div.darkgreen > div {
  padding: 0 15px;
  width: 9.09% !important;
  color: #fff;
  font-size: 11px;
  float: left;
  display: inline-block;
  height: 60px;
}

div.darkgreen > div > div {
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.collapse-section .panel, .collapsed-form .panel {
  border-radius: 0;
  border: 1px solid #fff;
  border-color: #d2d2d2;
}

.collapse-section .panel-group-header, .collapsed-form .panel-group-header {
  padding: 15px 0;
}

.collapse-section .panel-heading, .collapsed-form .panel-heading {
  padding: 24px 20px;
  position: relative;
}

.collapse-section .panel-heading + .panel-collapse > .panel-body, .collapsed-form .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
  padding-top: 0;
}

.collapse-section .panel-title .svc, .collapsed-form .panel-title .svc {
  font-size: 15px;
}

.collapse-section .panel-title a, .collapsed-form .panel-title a {
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.collapse-section .panel-title a:before, .collapsed-form .panel-title a:before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  font-size: 2.2rem;
  vertical-align: middle;
}

.collapse-section .panel-title a.collapsed:before, .collapsed-form .panel-title a.collapsed:before {
  content: '\f196';
}

.collapse-section .panel-title a:not(.collapsed):before, .collapsed-form .panel-title a:not(.collapsed):before {
  content: '\f147';
}

.collapse-section .panel-title-control, .collapsed-form .panel-title-control {
  position: absolute;
  right: 20px;
  z-index: 10;
}

.collapse-section .panel-title-control .price, .collapsed-form .panel-title-control .price {
  position: relative;
  width: auto;
  right: 0;
}

.collapse-section .panel-title-control .duplicate, .collapsed-form .panel-title-control .duplicate {
  cursor: pointer;
  position: relative;
  width: auto;
  right: 0;
  color: #3097D1;
  font-size: 16px;
  font-weight: normal;
  padding-right: 9px;
  border-right: 1px solid #3097D1;
  margin-right: 32px;
}

.collapse-section > .panel-group > .panel > .panel-heading > .panel-title, .collapsed-form > .panel-group > .panel > .panel-heading > .panel-title {
  font-size: 1.8rem;
}

.collapse-section .sub-section .panel, .collapsed-form .sub-section .panel {
  border: 0;
  box-shadow: none;
}

.collapse-section .sub-section .panel-body, .collapsed-form .sub-section .panel-body {
  padding: 0 40px;
}

.collapse-section .sub-section .panel-heading, .collapsed-form .sub-section .panel-heading {
  background: inherit;
  padding: 20px 24px;
}

.collapse-section .sub-section .panel:nth-child(odd), .collapsed-form .sub-section .panel:nth-child(odd) {
  background: #f7f7f7;
}

.collapse-section .qty, .collapsed-form .qty {
  width: 40px;
  text-align: center;
  display: inline-block;
}

.collapse-section .price, .collapsed-form .price {
  position: absolute;
  right: 20px;
  width: 100px;
}

.collapse-section .table [type=text], .collapsed-form .table [type=text] {
  max-width: 280px;
}

.collapse-section .table thead tr, .collapsed-form .table thead tr {
  background: #e3e3e3;
  color: #59595d;
}

.collapse-section .table tbody, .collapsed-form .table tbody {
  display: none;
}

.collapse-section .table.active tbody, .collapsed-form .table.active tbody {
  display: table-row-group;
}

.collapse-section .table thead tr th, .collapsed-form .table thead tr th {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #adafb0;
}

.collapse-section .table tbody tr td, .collapsed-form .table tbody tr td {
  border-color: #b9bbbc;
  padding-top: 5px;
  padding-bottom: 5px;
}

.collapse-section .table thead th:first-child, .collapsed-form .table thead th:first-child {
  padding-left: 15px;
}

.collapse-section .collapse-section .table tbody td:first-child, .collapsed-form .collapse-section .table tbody td:first-child {
  padding-left: 0;
}

.collapse-section .table thead th:last-child, .collapse-section .collapse-section .table tbody td:last-child, .collapsed-form .table thead th:last-child, .collapsed-form .collapse-section .table tbody td:last-child {
  padding-right: 15px;
}

.collapse-section .fa-times, .collapsed-form .fa-times {
  color: #e00000;
}

.stats-box {
  float: right;
}

.stats-box:before, .stats-box:after {
  content: " ";
  display: table;
}

.stats-box:after {
  clear: both;
}

.stats-box-inner {
  height: 96px;
}

.stats-box-inner:before, .stats-box-inner:after {
  content: " ";
  display: table;
}

.stats-box-inner:after {
  clear: both;
}

.stat {
  float: left;
  min-width: 160px;
  border-left: 1px solid #9d9d9d;
  height: 100%;
  padding: 25px;
}

.stat:first-child {
  border-left: none;
}

.stat.ticker {
  padding: 20px 25px;
}

.stat .button-box {
  padding: 0 45px 0 20px;
}

.stat .button-box .btn {
  font-weight: bold;
  width: 203px;
  color: #56565a;
  font-size: 17px;
  border-radius: 4px;
  background-color: #FFC52F;
  border: none;
}

.stat-bit {
  font-size: 32px;
}

.stat-bit .stat-label {
  font-size: 16px;
  text-align: left;
  line-height: 0.9;
  margin-bottom: 2px;
}

.stat-bit .stat-ratio {
  text-align: center;
}

.stat-bit .stat-price {
  color: #079f59;
  font-weight: normal;
  font-size: 23px;
  text-align: left;
}

.stat-bits.stat-bit {
  float: left;
  padding-left: 28px;
}

.stat-bits.stat-bit:first-child {
  padding-left: 0;
}

.stat-ratio + .stat-diff, .stat-ratio.half {
  float: left;
}

.stat-ratio .numerator:after {
  content: '';
  border-right: 2px solid #9d9d9d;
  display: block;
  width: 0px;
  right: -3px;
  position: absolute;
  top: 5px;
  bottom: 5px;
}

.stat-ratio .numerator {
  font-size: 32px;
  font-weight: bold;
  color: #e00000;
  padding: 0 6px;
  position: relative;
}

.stat-ratio .denominator {
  position: relative;
  top: -2px;
  font-weight: 300;
  font-size: 0.85em;
}

.stat-diff {
  font-size: 11px;
  position: relative;
  left: 6px;
}

.stat-diff.down {
  color: #e00000;
}

.stat-diff.up {
  color: #079f59;
}

.stat-diff .difficon {
  line-height: 0.3;
  float: left;
  font-size: 32px;
  top: -7px;
}

.stat-diff .diff-caption {
  position: absolute;
  top: 6px;
  left: -9px;
  width: 60px;
}

.profile {
  clear: both;
}

.profile:before, .profile:after {
  content: " ";
  display: table;
}

.profile:after {
  clear: both;
}

#signs-container, .signs-container {
  padding: 7px 0;
}

#signs-container .sign-row, .signs-container .sign-row {
  padding: 8px 15px;
}

#signs-container .sign-row:nth-of-type(even), .signs-container .sign-row:nth-of-type(even) {
  background-color: #fff;
}

#signs-container .sign-row:nth-of-type(odd), .signs-container .sign-row:nth-of-type(odd) {
  background-color: #f7f7f7;
}

.sign-details input:first-child {
  margin-bottom: 10px;
}

.quantities {
  margin-left: 15px;
}

.quantities div {
  float: left;
  margin-right: 15px;
  text-align: center;
}

.quantities div input {
  text-align: center;
  width: 60px;
  margin: 0 auto;
}

.sort-handle {
  padding: 4px 7px;
  border: 1px solid #aaa;
  border-radius: 5px;
  position: relative;
  width: 30px;
  height: 30px;
  color: #bbb;
  position: relative;
  top: 2px;
}

.container-fluid.profile {
  padding-top: 15px;
}

.task-detail-header:before, .task-detail-header:after {
  content: " ";
  display: table;
}

.task-detail-header:after {
  clear: both;
}

#contact-list:before, #contact-list:after {
  content: " ";
  display: table;
}

#contact-list:after {
  clear: both;
}

.rows-header h4 {
  font-size: 16px;
  font-weight: 600;
}

.rows-header .text-right {
  font-size: 14px;
}

.company-row a {
  color: #56565a;
}

.requirement-rows {
  border: 1px solid #b1b1b1;
  border-width: 1px 0 0 0;
  margin: 25px 0;
  border-left: 1px solid #b1b1b1;
}

.requirement-rows:before, .requirement-rows:after {
  content: " ";
  display: table;
}

.requirement-rows:after {
  clear: both;
}

.requirement-rows > div {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b1b1b1;
  background-color: #fff;
  border-right: 1px solid #b1b1b1;
}

.form-control[name$="[weighted_total]"] {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 18px;
  cursor: default;
}

.contact-company {
  margin: 25px 0 0 0;
}

.contact-company td {
  font-size: 14px;
}

.contact-row i.fa {
  border-radius: 3px;
  line-height: 15px;
  text-align: center;
  background-color: #2c3863;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 9px;
}

.contact-row i.fa:before {
  font-size: 13px;
  color: #fff;
}

.contact-row .contact {
  font-size: 13px;
  margin-bottom: 25px;
  line-height: 1.6;
}

/* login */
#login {
  margin-top: 100px;
  color: #d5d5d5;
}

#login .panel {
  border: 0;
  max-width: 500px;
  margin: auto;
}

#login .panel-heading {
  background: #f7f7f7;
}

#login .panel-body {
  background: #56565a;
  padding-top: 40px;
  padding-bottom: 40px;
}

#login a {
  color: #d5d5d5;
}

#login img {
  max-width: 360px;
  margin: 10px auto;
}

#login small {
  font-size: 12px;
}

#login [type=submit] {
  max-width: 200px;
  margin-top: 15px;
}

#login .links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

#masthead {
  background: #f5f5f5;
  height: auto;
  z-index: 100;
}

#masthead:before, #masthead:after {
  content: " ";
  display: table;
}

#masthead:after {
  clear: both;
}

#masthead img {
  max-width: 260px;
  margin-top: 9px;
  margin-bottom: 9px;
}

#masthead .navarea {
  float: right;
  text-align: right;
  padding-right: 88px;
}

#masthead .contact_name {
  font-size: 22px;
  font-weight: 500;
  color: #56565a;
  padding-right: 30px;
}

#masthead .opportunity_name {
  font-size: 22px;
  font-weight: 500;
  color: #56565a;
  padding-right: 35px;
}

#masthead .opportunity_name .edit-input {
  display: block;
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #45A2FA;
  font-family: 'fontAwesome';
  font-size: 14px;
  padding: 5px;
}

#masthead .opportunity_name input[disabled] {
  position: relative;
}

#masthead .opportunity_name input[disabled] + .edit-input span:before {
  content: "\f040";
}

#masthead .opportunity_name input + .edit-input .fa:before {
  content: "\f0c7";
}

#masthead #main-bar .container-fluid,
#masthead #main-bar .row,
#masthead #main-bar div[class^="col-"] {
  height: 100%;
}

.estipro.detail .opportunity_name .left-bar-segments .segment {
  line-height: 30px;
  border-left: 1px solid #ccc !important;
}

.estipro.detail .opportunity_name .left-bar-segments .segment:first-child {
  border-left: none !important;
}

.add-company-search-results .search-results {
  max-width: 290px;
}

#search-page {
  display: none;
}

.search-page-content .search-results {
  box-shadow: none;
  height: auto;
  overflow-y: visible;
}

.search-page-content .search-results .search-result, .search-page-content .search-results .sign-search-result {
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-results {
  z-index: 100;
  position: absolute;
  width: 500px;
  left: 0;
  background-color: #fff;
  box-shadow: 2px 2px 17px -1px #202020;
}

.search-results #replace-ml-sign-autocomplete-results .search-results {
  max-height: 400px;
  overflow-y: scroll;
  top: 35px;
}

.search-results .search-results {
  width: 100%;
  overflow-y: scroll;
  height: 600px;
}

.search-results .search-group:before, .search-results .search-group:after {
  content: " ";
  display: table;
}

.search-results .search-group:after {
  clear: both;
}

.search-results .search-header {
  background-color: #a5a5a5;
  color: #fff;
  text-transform: uppercase;
  padding: 3px 15px;
  font-size: 12px;
}

.search-results .search-header:before, .search-results .search-header:after {
  content: " ";
  display: table;
}

.search-results .search-header:after {
  clear: both;
}

.search-results .search-result, .search-results .sign-search-result {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #a5a5a5;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search-results .search-result:last-child, .search-results .sign-search-result:last-child {
  border-bottom: none;
}

.search-results .search-result:before, .search-results .search-result:after, .search-results .sign-search-result:before, .search-results .sign-search-result:after {
  content: " ";
  display: table;
}

.search-results .search-result:after, .search-results .sign-search-result:after {
  clear: both;
}

.search-results .search-result.selected, .search-results .sign-search-result.selected {
  background: #45a2fa;
}

.search-results .search-result.selected .blue-text, .search-results .sign-search-result.selected .blue-text {
  color: #fff;
}

.search-results .search-result span.small, .search-results .sign-search-result span.small {
  color: #c1c1c1;
}

#contact-container {
  padding-top: 15px;
}

#contact-container button:active,
#contact-container button:focus {
  outline-width: 0;
  outline: none;
}

#leftbar {
  float: left;
  display: inline-block;
  margin-left: 18px;
  height: 70px;
}

#leftbar > .inner {
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

#leftbar > .inner:before, #leftbar > .inner:after {
  content: " ";
  display: table;
}

#leftbar > .inner:after {
  clear: both;
}

#leftbar .segment {
  border: none;
}

#leftbar .small-name {
  line-height: 1;
}

#leftbar .big-number {
  font-size: 28px;
  font-weight: bold;
  padding-right: 8px;
}

#leftbar .blue-meta {
  display: inline-block;
  padding: 0 20px;
  border-left: 1px solid #c4c4c4;
  line-height: 1.0;
  position: relative;
  top: -4px;
}

#leftbar .small-text {
  font-size: 12px;
  margin-top: 10px;
  line-height: 1;
}

#leftbar .small-text.dd {
  padding-right: 20px;
}

#leftbar .small-text.dd:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #3097D1;
  font-size: 0;
  line-height: 0;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

#main-bar {
  float: right;
  display: inline-block;
}

#main-bar .btn {
  margin-left: 10px;
}

.design-availability {
  margin-right: 150px;
}

.design-availability .segment {
  padding-top: 10px;
  padding-bottom: 5px;
}

.segment {
  display: inline-block;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 1px solid #a1a1a1;
  height: 100%;
  font-size: 14px;
}

.segment.slim-padding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.segment.slim-padding:first-child {
  padding-left: 0 !important;
}

.segment.slim-padding:last-child {
  padding-right: 0 !important;
}

.segment i.fa {
  top: 0;
}

.segment > .inner {
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.segment:first-child {
  padding-left: 0 !important;
  border-left: none;
}

.segment:last-child {
  padding-right: 0 !important;
}

.segment.padding {
  padding: 0 25px;
}

.segment.no-border {
  border: none;
}

.segment.rating {
  border: none;
}

.segment.rating .fraction {
  color: #ffc52f;
  font-size: 21px;
}

.segment.rating .fa-star {
  font-size: 22px;
  color: #ccc;
}

.segment.rating .fa-star.active {
  color: #ffc52f;
}

.segment .green-circle {
  margin-right: 10px;
  width: auto;
  font-size: 1.7em;
  text-align: center;
  line-height: 36px;
  border-radius: 100px;
  background-color: #15b165;
  color: #fff;
  padding: 5px;
}

.segment .medium {
  font-size: 1.3em;
  font-weight: bold;
  clear: both;
  display: block;
}

.segment .green {
  color: #15b165;
}

.rating-popout {
  display: none;
  position: absolute;
  z-index: 10;
  left: 0;
  border-radius: 4px;
  overflow: hidden;
  padding: 10px;
}

.rating-popout h4 {
  margin-bottom: 4px;
}

.rating-popout hr {
  margin: 6px 0;
}

.rating-popout .rating-popout-inner {
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 17px -2px #aeaeae;
  padding: 20px;
  width: 230px;
}

.rating-popout .rating-popout-inner:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  top: -5px;
  left: 20px;
}

.rating-popout .rating-box {
  background-color: #f5f5f5;
  padding: 5px 8px;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  margin-bottom: 6px;
}

.rating-popout .rating-box i.fa {
  margin-right: 5px;
}

.rating-popout .rating-box i.fa:last-child {
  margin: 0;
}

.rating-popout:hover {
  display: block;
}

#main-menu {
  display: inline-block;
  float: right;
  /*top: 1px; */
}

#main-menu a {
  text-decoration: none;
}

#main-menu a.minor {
  display: none;
  position: absolute;
  margin: 0;
  background: #fff;
  white-space: nowrap;
  border: 0;
  border-radius: 0 0 8px 8px;
  padding: 8px 33px;
}

#main-menu a:hover {
  border-color: var(--sunglow);
}

#main-menu > li {
  margin: 0;
}

#main-menu > li.current > a {
  font-weight: 700;
}

#main-menu > li.backlink:after {
  content: '';
  border-right: 1px solid #c4c4c4;
  height: 20px;
  top: 50%;
  right: 0;
  display: block;
  position: absolute;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

#main-menu > li.backlink > a {
  font-size: 16px;
  color: #3097D1;
}

#main-menu > li:hover a.minor {
  display: block;
}

/* Message Box */
body.messaging #message-box {
  right: 0;
  position: fixed;
}

#message-box {
  border-left: 1px solid #d4d4d4;
  background: #f7f7f7;
  position: absolute;
  z-index: 9999;
  height: 100vh;
  right: -415px;
  top: 0;
  width: 415px;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -ms-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
  /*&.conversation #message-body {padding-bottom:183px;}*/
}

#message-box.conversation li {
  border-bottom: 1px solid #d4d4d4;
}

#message-box.conversation #message-footer {
  height: 183px;
}

#message-box.conversation .start, #message-box #message-box.conversation #conversation-box {
  display: block;
}

#message-box.conversation .convo-deatil {
  display: none;
}

#message-box.detail .convo {
  display: none;
}

#message-box #message-header {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-bottom: 1px solid #d4d4d4;
  height: 64px;
  overflow: hidden;
}

#message-box #message-header strong {
  font-size: 1.8rem;
  font-weight: 700;
}

#message-box #message-header .badge {
  margin-left: 10px;
  margin-top: -4px;
  padding: 6px 10px;
  border-radius: 20px;
  background: none;
  color: #d2d2d2;
  border: 1px solid #d2d2d2;
}

#message-box #message-header .badge.active {
  background: var(--sunglow);
  color: #ffffff;
  border-color: var(--sunglow);
}

#message-box #message-header .entry {
  padding: 19px 15px;
}

#message-box #message-header.conversation {
  text-align: right;
}

#message-box #message-header.conversation strong {
  float: none;
}

#message-box .convo-detail button {
  background: #000000;
  border: 0;
  height: 64px;
  color: var(--sunglow);
  width: 115px;
  margin-right: 15px;
}

#message-box .convo-detail button .fa {
  font-size: 2.4rem;
  vertical-align: middle;
  margin-top: -3px;
}

#message-box .entry {
  padding: 13px 15px;
}

#message-box .msg, #message-box #message-box .msg p {
  line-height: 1.4;
}

#message-box .avatar {
  position: absolute;
  margin-left: -60px;
}

#message-box .avatar img {
  width: 54px;
  height: 54px;
  border-radius: 54px;
}

#message-box .from {
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 4px;
  width: 95%;
}

#message-box li {
  position: relative;
  padding-left: 60px;
}

#message-box li:nth-child(even) {
  background-color: white;
}

#message-box #message-body {
  height: 100vh;
  position: absolute;
  top: 0;
  padding-top: 64px;
  /*padding-bottom: 146px;*/
  width: 100%;
}

#message-box #message-body .message-content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 0px;
}

#message-box .start {
  display: none;
  font-size: 1.4rem;
  padding: 4px 10px;
  font-weight: 700;
  position: fixed;
  bottom: 180px;
  width: 415px;
  background: #f7f7f7;
  border-top: 1px solid #d4d4d4;
}

#message-box #message-footer {
  height: 146px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

#message-box #message-footer .message-form {
  background: #ffffff;
  border-top: 1px solid #d4d4d4;
}

#message-box #message-footer .icons {
  float: left;
}

#message-box #message-footer .icons button {
  float: left;
  width: 45px;
  height: 44px;
  background: none;
  border: 0;
  border-right: 1px solid #d4d4d4;
}

#message-box #message-footer .form-control {
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

#message-box #message-footer .form {
  border-bottom: 1px solid #d4d4d4;
}

#message-box #message-footer textarea {
  height: 100px;
}

#message-box #message-footer .actions {
  height: 44px;
}

#message-box #conversation-box {
  display: none;
}

#message-box #send-message {
  padding: 3px 30px;
  margin: 5px 15px;
}

#message-button {
  background: var(--sunglow);
  color: #ffffff;
  position: absolute;
  left: -70px;
  top: 50px;
  height: 64px;
  width: 70px;
  border-radius: 4px 0 0 4px;
}

#message-button .fa {
  font-size: 3.4rem;
  margin: 14px 18px;
}

#message-button .badge {
  position: absolute;
  right: 0;
  margin: 11px;
  border-radius: 20px;
  /*width: 21px;*/
  height: 21px;
  line-height: 16px;
  background: #c50700;
  z-index: 100;
}

#detail-overview {
  margin-bottom: 10px;
  background-color: #f5f5f5;
  padding: 8px 15px;
  border-radius: 0 0 6px 6px;
}

#detail-overview .segment {
  border: none;
}

#detail-overview:before, #detail-overview:after {
  content: " ";
  display: table;
}

#detail-overview:after {
  clear: both;
}

#detail-overview .title {
  padding: 10px 0;
  margin-top: 15px;
}

#detail-overview .row {
  display: flex;
}

#detail-overview .divider {
  padding: 0;
}

#detail-overview .divider:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 1px solid #d4d4d4;
  top: 0;
}

#detail-overview .stats {
  padding: 9px 20px;
  display: inline-block;
  font-size: 0.9em;
}

#detail-overview .divider .stats {
  display: block;
}

#detail-overview .divider .stats + .stats {
  border-top: 1px solid #d4d4d4;
}

#detail-overview li.flex .stats {
  padding-left: 0;
  padding-right: 0;
}

#overview-header .stats li {
  border-left: 1px solid #c7c7c7;
  min-height: 70px;
}

#detail-controls label {
  font-weight: normal;
}

#detail-controls .btn {
  font-weight: normal;
}

#detail-controls .detail-controls-right div[class^="col-"] {
  text-align: right;
}

#masthead .opportunity-dropdowns {
  padding: 3px 20px;
}

#masthead .opportunity-dropdowns .status-bar {
  color: #45A2FA;
}

#main {
  z-index: 99;
}

#main:before, #main:after {
  content: " ";
  display: table;
}

#main:after {
  clear: both;
}

#form-requirements .row-control {
  padding: 10px 0;
}

#form-requirements .row-control.left {
  float: left;
}

#form-requirements table tr td:first-child {
  padding-left: 15px;
}

#form-requirements table tr td:last-child {
  padding-right: 1px;
}

#form-requirements table tr td {
  padding: 10px 0;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}

#form-requirements table tr:nth-of-type(odd) {
  background-color: #fff;
}

#radioshack .left, #radioshack .none {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

#scope-of-work .panel-header {
  position: relative;
}

#scope-of-work .panel-header:before, #scope-of-work .panel-header:after {
  content: " ";
  display: table;
}

#scope-of-work .panel-header:after {
  clear: both;
}

#scope-tabs .tab {
  width: 210px;
}

div.tab-panels {
  height: 100%;
}

div.tab-panels:before, div.tab-panels:after {
  content: " ";
  display: table;
}

div.tab-panels:after {
  clear: both;
}

div.tab-panels > div {
  display: none;
}

div.tab-panels textarea {
  height: 130px;
  padding-bottom: 35px;
  margin-top: 7px;
  resize: none;
}

div.tab-panels textarea + button {
  margin-top: -30px;
  float: right;
}

div.tab-panels .panel {
  height: 100%;
}

.right-panel {
  border-left: 1px solid #ccc;
}

.right-panel .tab-group {
  margin: 0;
}

.right-panel .panel {
  padding-top: 15px;
  background-color: #f5f5f5;
}

div.tab-group {
  display: block;
}

div.tab-group.vertical-tabs .tabs {
  text-align: center;
  white-space: nowrap;
  width: 40px;
}

div.tab-group.vertical-tabs .tab {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  clear: both;
  float: left;
  padding: 0;
  margin-bottom: 3px;
  font-size: 11px;
}

div.tab-group.theme-green .tab {
  background-color: #ccc;
  color: #fff;
}

div.tab-group.theme-green .tab.active {
  background-color: #1A9E5C;
  color: #fff;
}

div.tab-group.theme-blue {
  height: 38px;
  background-color: #4696FA;
  overflow: hidden;
  float: none;
}

div.tab-group.theme-blue .tab {
  display: inline-block;
  width: auto !important;
  height: 25px;
  margin-top: 13px;
  padding: 5px 9px !important;
  background-color: #BFBFBF;
  color: #fff;
  border-radius: 3px 3px 0 0;
  margin-left: 6px;
  font-size: 0.8em;
}

div.tab-group.theme-blue .tab:first-child {
  margin-left: 0;
}

div.tab-group.theme-blue .tab.active {
  font-weight: bold;
  color: #45A2FA;
  background-color: #f5f5f5;
}

div.tab-group:before, div.tab-group:after {
  content: " ";
  display: table;
}

div.tab-group:after {
  clear: both;
}

div.tab-group.one-tab button, div.tab-group.two-tab button, div.tab-group.three-tab button, div.tab-group.four-tab button, div.tab-group.five-tab button {
  width: 100%;
  min-width: 100%;
  font-size: 13px;
}

div.tab-group.one-tab .tab {
  width: 100%;
}

div.tab-group.two-tab .tab {
  width: 50%;
}

div.tab-group.three-tab .tab {
  width: 33.3333%;
}

div.tab-group.four-tab .tab {
  width: 25%;
}

div.tab-group.five-tab .tab {
  width: 20%;
}

div.tab-group .tab {
  padding-right: 10px;
  float: left;
  display: inline-block;
}

div.tab-group .tab:last-child {
  padding-right: 0;
}

div.tab-group button {
  font-size: 15px;
  outline: none;
  border: none;
  border-bottom: 4px solid #a5a5a5;
  margin-right: 10px;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
  min-width: 210px;
  color: #56565a;
  box-shadow: none;
}

div.tab-group button.active, div.tab-group button:focus {
  background-color: #ffc52f !important;
  font-weight: bold;
  outline: none !important;
}

.note-rows .note-row {
  font-size: 13pxem;
  border-top: 1px solid #ccc;
  padding-top: 15px;
  margin-top: 15px;
}

.textarea-container {
  margin-bottom: 30px;
}

.sign-type-rows {
  margin-top: 10px;
}

.sign-type-rows .sign-type-row {
  padding: 15px 25px;
}

.sign-type-rows .sign-type-row:before, .sign-type-rows .sign-type-row:after {
  content: " ";
  display: table;
}

.sign-type-rows .sign-type-row:after {
  clear: both;
}

.sign-type-rows .sign-type-row:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.sign-type-label {
  padding: 6px 10px;
  font-size: 15px;
}

.bio {
  font-size: 13px;
  margin-bottom: 30px;
}

.bio .line {
  margin-bottom: 9px;
}

.bio .image-control {
  font-size: 13px;
  color: #3097D1;
}

.bio .image-control a {
  color: #3097D1;
}

.bio .image-control .left {
  text-align: left;
}

.bio .image-control .right {
  text-align: right;
}

body.estipro.masterlist {
  min-height: 100%;
}

body.estipro.masterlist:before {
  content: '';
  display: block;
  left: 0;
  top: 0;
  background-color: #f5f5f5;
  width: 260px;
  position: absolute;
  height: 100%;
}

body.estipro.masterlist #dashboard {
  min-height: 100%;
}

body.estipro.masterlist td.match, body.estipro.masterlist td.match * {
  font-weight: bolder;
}

#dashboard {
  min-height: 100%;
}

.crm.company #dashboard {
  height: 100%;
}

.crm.opportunity.files table tbody tr.active {
  background-color: #d3f5ff;
}

.crm.tasks #main .gold-bar button {
  padding: 8px 15px;
  font-weight: bold;
}

.crm.tasks #main .gold-bar button .fa {
  font-weight: normal;
  border: 1px solid #202020;
  border-radius: 99px;
  padding: 5px;
}

.gold-bar.opportunity-details .none {
  padding-left: 40px;
}

.gold-bar {
  width: 100%;
}

.gold-bar a {
  color: #337ab7;
}

.gold-bar .segment {
  padding-left: 20px;
  padding-right: 20px;
  border-left: none;
}

.gold-bar .segment:first-child {
  padding-left: 0 !important;
}

.gold-bar .segment:last-child {
  padding-right: 0 !important;
}

#main {
  height: 100%;
  clear: both;
}

#main .container-fluid {
  height: 100%;
}

.left.sidebar:before {
  content: '';
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f5f5f5;
}

.sidebar-menu {
  padding-top: 30px !important;
  padding-right: 0 !important;
}

.sidebar {
  width: 260px;
  color: #fff;
  height: 100%;
  background-color: #f5f5f5;
}

.sidebar.left + .none {
  display: block;
}

.sidebar a {
  color: #45A2FA;
  padding: 6px 6px 6px 0;
  display: block;
}

.sidebar .children a {
  padding: 6px 6px 6px 12px;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar li.top_level {
  font-weight: 500;
  margin-bottom: 15px;
  color: #797979;
}

.sidebar li.top_level a {
  color: #797979;
}

.sidebar li.top_level.active {
  font-weight: 500;
  color: #45A2FA;
}

.sidebar li.top_level.active > a {
  color: #45A2FA;
}

.sidebar li.top_level.active ul.children {
  display: block;
}

.sidebar li.top_level.active ul.children li.active a {
  color: #45A2FA;
}

.sidebar li.active {
  font-weight: 500;
  color: #45A2FA;
}

.sidebar ul.children {
  padding-bottom: 15px;
  display: none;
}

.sidebar ul.children li {
  font-weight: normal;
  margin-top: 10px;
}

.sidebar ul.children li.active {
  font-weight: 500;
  background-color: #fff;
  border-radius: 6px 0 0 6px;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  font-weight: bold;
}

.breadcrumb a {
  font-weight: bold;
  color: #198DFC;
}

.breadcrumb a:after {
  content: "/";
  position: relative;
  top: 0;
  right: -10px;
  margin-right: 15px;
}

.breadcrumb a:last-child:after {
  content: '';
}

#search-box {
  float: left;
  display: inline-block;
  z-index: 1;
  vertical-align: middle;
  height: 70px;
}

#search-box.active .form-control {
  width: 240px;
  left: 50px;
}

#search-box .form-control {
  height: 46px;
  border-right: 0;
  box-shadow: none;
  border: none;
}

#search-box .fa {
  color: #fff;
  font-size: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

#search-box .input-group-addon {
  background: #ffc52f;
}

#search-box .input-group-addon {
  background: #ffc52f;
  height: 46px;
  width: 46px;
  border: none;
  border-radius: 0;
}

#search-box .search-button {
  display: inline-block;
  z-index: 2;
}

#search-box .form-control {
  width: 0;
  position: absolute;
  left: 22px;
  top: 0;
  transition: width 0.5s, left 0.5s;
  z-index: 1;
}

.input-group-addon.transparent {
  background: transparent;
}

.input-group-addon.transparent button {
  background: transparent;
}

.input-group-addon.transparent input[type="button"] {
  background: transparent;
}

.contact-row .contact {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-row .contact .col-sm-3 {
  padding-bottom: 4px;
  padding-top: 4px;
}

.contact-row .contact select {
  cursor: pointer;
  margin: 8px 0;
}

#search-page {
  height: 100%;
  position: absolute;
  right: 0;
}

#search-page .search-results {
  width: 100% !important;
}

#search-page .search-page-inner {
  height: 100%;
}

#search-page .search-page-header {
  padding: 10px;
  background-color: #ccc;
}

#search-page .search-page-header input {
  border-radius: 0;
}

#search-page .search-page-header .input-group-addon {
  border-radius: 0;
}

#search-page .search-page-content {
  height: 100%;
  background-color: #2e2f4a;
}

.moneyMagic:before {
  content: "$";
  position: absolute;
  left: 10px;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  color: #555;
  z-index: 1;
}

.moneyMagic input {
  margin-bottom: 0 !important;
  padding-left: 20px;
}

.task-detail-description {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dz-clickable {
  border: 2px dashed #1a8efc;
  height: 300px;
  text-align: center;
}

.dz-clickable > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-size: 18px;
}

.dz-default.dz-message {
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
}

.modal-header img[src$="cancel.svg"] {
  position: relative;
  z-index: 2;
}

#MoveFileModal .minus-button {
  color: #45A2FA;
  top: -1px;
  border: 1px solid #45A2FA;
  border-radius: 2px;
  display: inline-block;
  margin-left: -25px;
  font-size: 17px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
}

#MoveFileModal button {
  opacity: 0.5;
  cursor: not-allowed;
}

#MoveFileModal button.active {
  opacity: 1.0;
  cursor: pointer;
}

#MoveFileModal .tree-folder {
  padding: 3px 5px;
}

#MoveFileModal .tree-folder.active {
  background-color: #d3f5ff;
}

#MoveFileModal .tree-folder i.fa {
  color: #45A2FA;
}

#MoveFileModal .modal-body {
  background-color: #f5f5f5;
  padding: 35px 35px 0 35px;
}

#MoveFileModal .modal-body .modal-inner {
  background-color: #fff;
  padding: 5px 15px 25px;
  height: 250px;
  overflow: scroll;
}

#MoveFileModal .modal-body .modal-inner > ul {
  margin-left: 0;
  padding-left: 0;
}

#MoveFileModal .modal-footer {
  padding-left: 35px;
  padding-right: 35px;
  border-top: none;
  background-color: #f5f5f5;
}

.admin.masterlist #dashboard {
  height: 100%;
}

.admin.masterlist #dashboard #main {
  min-height: 100%;
}

.cost-code-group > div {
  display: inline-block;
  float: left;
  height: 34px;
  width: auto;
  padding: 6px 10px;
}

.cost-code-group .dark {
  background-color: #292929;
  color: #fff;
}

.cost-code-group .middle {
  padding: 0;
  width: 90px;
}

.cost-code-group .middle input {
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.cost-code-group .white {
  background-color: #fff;
}

.left-bar-segments {
  height: 30px;
}

.left-bar-segments .segment {
  height: 100%;
  font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
  font-weight: normal;
}

.left-bar-segments .segment h1, .left-bar-segments .segment h2, .left-bar-segments .segment h3, .left-bar-segments .segment h4, .left-bar-segments .segment h5, .left-bar-segments .segment h6 {
  margin-bottom: 0;
}

.left-bar-segments .segment span {
  font-weight: normal;
}

.striped-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.striped-list .striped-list-heading {
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
}

.striped-list .striped-list-heading h5 {
  font-size: 15px;
}

.striped-list .striped-list-body {
  margin-bottom: 30px;
}

.striped-list .striped-list-body li {
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}

#schedule-summary {
  margin-top: 15px;
}

#schedule-container {
  margin-top: 15px;
}

#schedule-container .schedule-footer {
  float: left;
  width: 100%;
  margin-top: 10px;
  border-top: 2px solid #9a9a9a;
  padding-top: 10px;
  font-size: 12px;
  padding-right: 40px;
}

#schedule-container .schedule-footer .gold-button {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 6px;
}

.schedule-calendars {
  padding-top: 30px;
  width: 100%;
}

.schedule-calendars .row {
  margin-left: -5px;
  margin-right: -5px;
}

.schedule-calendars .calendar {
  padding-left: 5px;
  padding-right: 5px;
}

.schedule-calendars .calendar span {
  font-size: 14px;
}

.schedule-calendars .calendar .heading {
  color: #fff;
  font-weight: normal;
  padding: 6px 12px;
}

.schedule-calendars .calendar .content {
  background-color: #fff;
  border: 1px solid #9a9a9a;
  border-width: 0px 1px 1px;
  padding: 6px 12px;
}

.schedule-calendars .calendar.approved .heading {
  background-color: #1A9E5C;
}

.schedule-calendars .calendar.forecast .heading {
  background-color: #DD0A16;
}

.schedule-calendars .calendar.fab-release .heading {
  background-color: #56565A;
}

.master-list-drawer {
  position: absolute;
  bottom: 50px;
  left: 6px;
  z-index: 10;
  width: 600px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 41px #333;
}

.master-list-drawer .inner-drawer {
  height: 100%;
}

.master-list-drawer .side, .master-list-drawer .scroll {
  float: left;
  display: inline-block;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 100%;
}

.master-list-drawer .side {
  width: 30%;
  border-right: 1px solid #ccc;
}

.master-list-drawer .scroll {
  width: 70%;
  padding: 0 20px;
  overflow-y: scroll;
  padding-bottom: 36px;
}

.master-list-drawer .scroll-control {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 20px;
}

.master-list-drawer .scroll-control span {
  color: #3097D1;
}

.master-list-drawer .list-item {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

.master-list-drawer .list-item.active {
  background-color: #82ffcf;
}

.master-list-drawer .list-item input[type="number"] {
  width: 30px;
}

.master-list-drawer .list-item span.name {
  display: inline-block;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.master-list-drawer .list-item span.cost {
  float: right;
  width: 80px;
  display: inline-block;
  text-align: right;
}

/*******new css 12-07-2017*****/
#schedule-container ul {
  padding: 0px;
  margin: 0px;
}

#schedule-container ul li {
  list-style: none;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid #CACBCC;
  font-size: 13px;
  color: #808080;
  overflow: hidden;
}

.m-drag {
  cursor: move;
}

.list {
  min-height: 20px;
  border-bottom: 1px solid #CACBCC;
}

.m-drag:hover {
  cursor: move;
  background-color: #CACACA;
}

#schedule-container ul li > span {
  padding-top: 5px;
  overflow: hidden;
}

#schedule-container ul li.bdr-none {
  border-bottom: none;
}

#schedule-container span.gold {
  background-color: #FFC52F;
  color: #56565a;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: 500;
}

#schedule-container span.black {
  background-color: #56565a;
  color: #ffffff;
  display: block;
  padding-bottom: 2px;
  padding-top: 5px;
  font-weight: 500;
}

#schedule-container span.black span:first-child {
  width: 30%;
  display: inline-block;
}

.item {
  padding-left: 10px;
}

.wks {
  width: 60px;
  text-align: left;
}

.schedule-form {
  display: inline-block;
  width: 60%;
  height: 22px;
  padding: 4px 12px;
  font-size: 12px;
}

.schedule-form-small {
  display: inherit;
  width: 30px;
  height: 22px;
  padding: 4px 2px;
  font-size: 12px;
}

/*******new css 12-07-2017*****/
/*****css for users listing*****/
.users table thead th:first-child {
  padding-left: 15px;
}

.person-rows .form-label {
  font-size: 12px;
}

.person-rows .form-row {
  font-size: 12px;
}

.person-rows .form-row .form-group {
  margin-bottom: 0;
}

.modal {
  top: 73px;
}

.modal-backdrop.in {
  opacity: 0.2;
}

.close {
  opacity: 1;
}

.close:hover {
  opacity: 1;
}

/*.fa-plus-square-o {
	font-size: 20px;
	color: #202020;
}
.fa-minus-square-o {
	font-size: 20px;
	color: #202020;
}*/
.circle {
  border-radius: 99px;
  opacity: 0.5;
  background-color: #666;
  height: 20px;
  width: 20px;
  display: inline-block;
  transform: translateY(5px);
  -o-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  margin: 0 5px;
}

.has-tooltip .tooltip-caret {
  display: inline-block;
  margin-left: 8px;
}

.has-tooltip .tooltip-menu {
  z-index: 10;
}

.has-tooltip .tooltip-caret:hover {
  position: relative;
}

.has-tooltip .tooltip-caret:hover .tooltip-menu {
  z-index: 10;
  display: block;
}

.tooltip-menu {
  display: none;
  position: absolute;
  top: 10px;
  left: -22px;
  padding-top: 10px;
}

.tooltip-menu .tip {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 10px 7px;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 2;
}

.tooltip-menu .tooltip-menu-inner {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 11px 0px #666;
}

.tooltip-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tooltip-menu ul li {
  border-bottom: 1px solid #ccc;
}

.tooltip-menu ul li a {
  padding: 4px 12px;
  display: block;
}

#messages-container.style1 .message {
  padding-left: 15px;
  border-bottom: none;
}

#messages-container .message {
  border-bottom: 1px solid #ccc;
  padding-top: 15px;
  padding-bottom: 20px;
}

#messages-container .message.unread .message-title {
  font-weight: bold;
  color: #2091FC;
}

#messages-container .message .message-icon {
  width: 60px;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  float: left;
}

#messages-container .message .message-body {
  margin-left: 60px;
}

#messages-container .message .message-title {
  color: #56565a;
  padding-bottom: 10px;
}

#messages-container .message.note {
  background-color: lightyellow;
}

#messages-container .message.note .message-meta .small {
  color: lightgray;
}

#messages-container .message.reply {
  padding-left: 50px;
  border-bottom: none;
  border-top: 1px solid #ccc;
  padding-bottom: 0px;
}

#messages-container .message.reply .message-body {
  padding-bottom: 10px;
}

#messages-container > .reply {
  display: none;
}

#tracking-table tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
}

#tracking-table th {
  padding: 2.5px 5px;
  text-align: left;
}

#tracking-table th .sortable-column {
  color: #fff;
  cursor: pointer;
}

#tracking-table td {
  padding: 0px 5px;
  vertical-align: middle;
  text-align: left;
}

#tracking-table td .status-bar {
  margin-left: 0px;
}

.form-group input + .popupulator, .form-group textarea + .popupulator {
  position: absolute;
  bottom: 3px;
  left: 6px;
}

/*****css for users listing*****/
@media (min-width: 992px) {
  .tab-box {
    position: absolute;
    left: -40px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    top: 0;
  }
  .profile {
    padding-top: 15px;
  }
  .gold-bar .bar-label {
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: block;
  }
  .gold-bar .middle-align .bar-label {
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  body.crm.tasks:before {
    content: '';
    width: 33.3333%;
    background-color: #f5f5f5;
    min-height: 100%;
    position: absolute;
    right: 0;
  }
  .profile-detail-pane {
    margin-left: 15px;
  }
  .container-fluid.profile:before {
    content: '';
    background-color: #ccc;
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    left: 33.333%;
    margin: 0px 19px;
  }
  .crm.tasks .profile:before {
    display: none;
  }
  .crm.tasks .task-detail-header {
    padding-left: 55px;
  }
  .crm.tasks .widget-separator {
    padding-left: 40px;
  }
  .crm.tasks .widget-notes {
    padding-left: 40px;
  }
}

@media (max-width: 991px) {
  #main-menu > li > a {
    border-bottom: 4px solid transparent;
    font-size: 1.4rem;
    color: #56565a;
    display: block;
    padding: 24px 28px;
  }
  #customer-notes {
    padding-left: 30px;
    padding-right: 30px;
  }
  .profile-detail-pane {
    padding-top: 15px;
  }
  .rows-header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .address-row .address {
    padding-left: 15px;
    padding-right: 15px;
  }
  .contact-row .contact {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #ccc;
  }
  .bio {
    padding: 0 15px;
  }
  #main .container-fluid.profile {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .segment {
    border: none;
  }
  #form-requirements table tr {
    padding-left: 150px;
    display: block;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
  #form-requirements table tr:before, #form-requirements table tr:after {
    content: " ";
    display: table;
  }
  #form-requirements table tr:after {
    clear: both;
  }
  #form-requirements table tr td {
    border-bottom: none;
  }
  #form-requirements table td:first-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
  }
  #form-requirements table td:first-child ~ td {
    display: inline-block;
    width: 50%;
    float: left;
  }
  .zero-margin-sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (min-width: 769px) {
  .left-bar-segments .segment:last-child:after {
    border-right: none;
  }
  .left-bar-segments .segment:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 85%;
    width: 100%;
    border-right: 1px solid #a1a1a1;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: block;
  }
  .full-height-right:after {
    content: '';
    height: 100%;
    position: fixed;
    width: 50%;
    right: 0;
    top: 0;
    background-color: #f7f7f7;
    z-index: -1;
  }
  .full-height-left:after {
    content: '';
    height: 100%;
    position: fixed;
    width: 50%;
    left: 0;
    top: 0;
    background-color: #f7f7f7;
    z-index: -1;
  }
  #main-bar {
    /*overflow: hidden;*/
    height: 70px;
  }
  #main-menu > li:after {
    content: '';
    width: 100%;
    height: 35px;
    display: block;
    bottom: 0;
    position: absolute;
    z-index: -1;
    border-radius: 8px 8px 0 0;
    background-color: #f5f5f5;
  }
  #main-menu li.current:after {
    background-color: #FFC52F;
  }
  #main-menu > li:last-child:after {
    display: none;
  }
  #main-menu > li {
    padding-left: 0;
    padding-right: 0;
  }
  #main-menu > li > a {
    border-bottom: 4px solid transparent;
    font-size: 1.4rem;
    color: #56565a;
    display: block;
    padding: 24px 33px 0;
    margin-top: 22px;
  }
  div[class*="-bar"] .right .header-icon button {
    line-height: 1.2;
  }
  .header-icon.toggle {
    top: 4px;
  }
  .header-icon.folder img {
    width: 45px;
  }
  .header-icon.file img {
    width: 29px;
  }
  .middle-align {
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: block;
  }
  .middle-align.none {
    display: block;
  }
  li.has-dropdown:hover .mega-menu {
    display: block;
  }
  .navbar .bars {
    display: none !important;
  }
  #search-box {
    width: 50px;
  }
  #radioshack .left, #radioshack .none {
    border-bottom: none;
  }
  #radioshack .col-md-6 {
    border-bottom: 1px solid #ccc;
  }
  .datepicker-box-box > .right {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .left-bar-segments {
    width: 100%;
  }
  .left-bar-segments .segment {
    width: 100%;
    clear: both;
    text-align: center;
  }
  .gold-bar .segment {
    clear: both;
    float: left;
    text-align: center;
    padding: 6px 0;
    width: 100%;
  }
  .gold-bar .search-bar {
    padding-bottom: 15px;
  }
  .hidden-sm {
    display: none !important;
  }
  .stats-box {
    float: none;
    margin: 0 auto;
    margin-top: 30px;
  }
  .stats-box .stat .button-box {
    padding: 0;
  }
  .stats-box .stat .button-box .button-box-inner {
    text-align: left;
  }
  .stats-box .stat {
    margin: 0 auto;
    border: none;
    float: none;
  }
  .stats-box .stat:before,
  .stats-box .stat:after {
    content: " ";
    display: table;
  }
  .stats-box .stat:after {
    clear: both;
  }
  #opportunityName {
    font-size: 19px;
  }
  .gold-bar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .gold-bar.estimates.files {
    padding-left: 0;
    padding-right: 0;
  }
  .gold-bar.estimates.files > .left {
    padding-left: 15px;
  }
  .gold-bar.estimates.files .header-icon {
    width: 100%;
    clear: both;
    border-top: 1px solid #56565a;
    height: auto;
    padding: 6px 0;
  }
  .gold-bar.estimates.files .header-icon.toggle {
    padding: 0;
  }
  .gold-bar.estimates.files .header-icon .text {
    top: 0;
  }
  .gold-bar.estimates.files .header-icon img {
    float: right;
    height: 60%;
    margin-right: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  .gold-bar.estimates.files .header-icon.folder {
    padding-left: 15px;
  }
  .gold-bar.estimates.files .header-icon.folder img {
    height: 20px;
    margin-right: 0;
  }
  .gold-bar.estimates.files .header-icon.file {
    padding-left: 15px;
  }
  .gold-bar.estimates.files .header-icon.file img {
    height: 20px;
    margin-right: 0;
  }
  .gold-bar.estimates.files .header-icon button {
    width: 100%;
    background: #d6d6d6 !important;
    background: -moz-linear-gradient(top, #FFC52F 0%, #FFC52F 100%) !important;
    background: -webkit-linear-gradient(top, #FFC52F 0%, #FFC52F 100%) !important;
    background: linear-gradient(to bottom, #FFC52F 0%, #FFC52F 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
    text-align: left;
    height: 32px;
    line-height: 32px;
    outline: none;
    border-radius: 0;
  }
  .gold-bar.estimates.files > .left,
  .gold-bar.estimates.files > .right {
    float: none;
    width: 100%;
  }
  .gold-bar.estimates.files > .left:before, .gold-bar.estimates.files > .left:after,
  .gold-bar.estimates.files > .right:before,
  .gold-bar.estimates.files > .right:after {
    content: " ";
    display: table;
  }
  .gold-bar.estimates.files > .left:after,
  .gold-bar.estimates.files > .right:after {
    clear: both;
  }
  .tab-box {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    top: 0;
  }
  .tab-group .tab {
    width: auto !important;
  }
  #masthead .navarea {
    padding-right: 0px;
    width: 100%;
    float: none;
    height: 410px;
  }
  .gold-bar.opportunity-details .none {
    padding-left: 0;
    clear: none;
    float: left;
    width: 100%;
  }
  .gold-bar.opportunity-details .segment {
    width: 100%;
    clear: both;
    padding: 8px 0;
  }
  .crm.company #masthead {
    height: 70px;
  }
  #masthead {
    width: 100%;
    float: left;
    height: auto;
  }
  #masthead > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  #masthead > .container-fluid #search-box, #masthead > .container-fluid #leftbar {
    padding-left: 15px;
    padding-right: 15px;
  }
  #masthead #leftbar.od {
    padding: 0 10px 10px;
    height: auto !important;
  }
  #masthead #leftbar.od:before, #masthead #leftbar.od:after {
    content: " ";
    display: table;
  }
  #masthead #leftbar.od:after {
    clear: both;
  }
  #masthead .opportunity-dropdowns {
    padding-left: 0;
    padding-right: 0;
    float: right;
    width: 100%;
  }
  #masthead .opportunity-dropdowns .owner-select-box {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
  }
  #masthead .opportunity-dropdowns .owner-select-box select {
    font-size: 12px;
  }
  #masthead .opportunity-dropdowns .status-select-box {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
  }
  #masthead .opportunity-dropdowns .status-select-box select {
    font-size: 12px;
  }
  #masthead .opportunity-dropdowns .status-select-box .status-bar {
    padding: 0;
  }
  #masthead .opportunity-dropdowns:before, #masthead .opportunity-dropdowns:after {
    content: " ";
    display: table;
  }
  #masthead .opportunity-dropdowns:after {
    clear: both;
  }
  #masthead .opportunity-dropdowns .none {
    float: none !important;
  }
  #masthead .opportunity-dropdowns .none:before, #masthead .opportunity-dropdowns .none:after {
    content: " ";
    display: table;
  }
  #masthead .opportunity-dropdowns .none:after {
    clear: both;
  }
  #masthead .opportunity-dropdowns .right {
    float: none !important;
  }
  #masthead .opportunity-dropdowns .right:before, #masthead .opportunity-dropdowns .right:after {
    content: " ";
    display: table;
  }
  #masthead .opportunity-dropdowns .right:after {
    clear: both;
  }
  #masthead .opportunity-dropdowns .status-bar {
    margin: 0 !important;
  }
  #masthead .opportunity_name {
    font-size: 13px;
    padding: 0;
  }
  #masthead .opportunity_name:before, #masthead .opportunity_name:after {
    content: " ";
    display: table;
  }
  #masthead .opportunity_name:after {
    clear: both;
  }
  #masthead .opportunity_name:after {
    display: none;
  }
  #masthead .col-sm-12 {
    padding: 0;
  }
  .segment:last-child {
    padding-right: 0 !important;
  }
  .navbar .bars {
    position: absolute;
    right: 7px;
    font-size: 21px;
    margin-top: -1px;
    padding: 0 8px;
  }
  ul.navbar-nav {
    display: none;
    width: 100%;
    position: absolute;
    top: 30px;
    right: 0;
    max-width: 320px;
  }
  ul.navbar-nav li {
    padding-left: 30px;
    background-color: #56565a;
    clear: both;
    width: 100%;
    display: block;
  }
  ul.navbar-nav li.dark {
    margin-left: 0;
    padding-left: 30px;
  }
  ul.navbar-nav li.has-dropdown {
    height: auto;
    padding-left: 0;
  }
  ul.navbar-nav li.has-dropdown > a.active + .mega-menu {
    display: block;
  }
  ul.navbar-nav li.has-dropdown > a:before {
    content: '\f107';
    font-family: FontAwesome;
    position: absolute;
    left: 12px;
    font-size: 16px;
    top: -4px;
  }
  ul.navbar-nav li.has-dropdown a {
    padding-left: 30px;
  }
  .mega-menu {
    margin-left: -50px;
    width: 100%;
    height: auto;
    border-radius: 0;
    display: none;
    position: relative;
    margin-left: -20px;
  }
  .mega-menu .list-header {
    padding-left: 30px;
  }
  .mega-menu .mega-inner {
    height: auto;
  }
  .mega-menu .mega-inner:before, .mega-menu .mega-inner:after {
    content: " ";
    display: table;
  }
  .mega-menu .mega-inner:after {
    clear: both;
  }
  .mega-menu .mega-inner:before {
    left: 30px;
  }
  .mega-menu .mega-inner div {
    float: left;
    width: 100%;
  }
  .mega-menu .mega-inner div ul li {
    background: #fff;
  }
  .mega-menu .mega-inner div ul li a {
    padding-left: 30px;
  }
  .mega-menu .mega-inner .left li {
    padding-left: 0;
  }
  .mega-menu .mega-inner .none.empty {
    display: none;
  }
  .mega-menu .mega-inner .gold-button {
    margin: 0;
    text-align: left;
    padding: 0;
    border-radius: 0;
  }
  .mega-menu .mega-inner .gold-button a {
    padding-left: 10px;
  }
  .mega-menu .mega-inner .gold-button a:hover {
    text-decoration: none;
  }
  .segment {
    padding-left: 0;
    border: none;
  }
  .segment .gold-button {
    font-size: 13px;
    padding-left: 11px;
    padding-right: 11px;
  }
  .dashboard #masthead > .container-fluid .segment > .inner,
  .crm.opportunities #masthead > .container-fluid .segment > .inner,
  .crm.contacts #masthead > .container-fluid .segment > .inner {
    top: 0;
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  .dashboard #main-bar,
  .crm.opportunities #main-bar {
    float: right;
    width: auto;
  }
  .crm.opportunities .gold-bar {
    height: auto;
  }
  .crm.opportunities .filters .form-control {
    width: 100%;
    float: left;
    clear: none;
    margin-bottom: 10px;
  }
  .crm.opportunity .contact-company td {
    display: block;
    width: 100%;
    clear: none;
    padding: 5px 0;
  }
  .crm.tasks .gold-bar {
    padding: 15px;
  }
  .crm.tasks .gold-bar .middle-align {
    top: 0;
    transform: translate(0);
    -o-transform: translate(0);
    -ms-transform: translate(0);
    -moz-transform: translate(0);
    -webkit-transform: translate(0);
  }
  .crm.tasks #main-bar {
    padding: 10px 0;
  }
  .crm.tasks #main-bar .segment > .inner {
    top: 0;
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  #main-bar {
    margin-right: 0;
    float: left;
    width: 100%;
    color: #f5f5f5;
    height: auto;
  }
  #main-bar:before, #main-bar:after {
    content: " ";
    display: table;
  }
  #main-bar:after {
    clear: both;
  }
  .datepicker-box-box {
    float: left;
  }
  .datepicker-box-box strong {
    clear: right;
  }
  .datepicker-box-box > div {
    width: 50%;
    float: left;
    display: inline-block;
  }
  .datepicker-box-box > div input {
    width: 100%;
  }
  .datepicker-box-box > div:first-child input {
    width: 95%;
  }
  .search-group > div:first-child {
    padding: 4px 0  4px 10px !important;
  }
  .search-group > div, .search-group a > div {
    padding-left: 10px !important;
  }
  #search-box {
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
  }
  #leftbar {
    float: none;
    overflow: hidden;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
  .estipro.schedule #leftbar .name {
    text-align: center;
  }
  .estipro.schedule #leftbar .left {
    float: none;
    width: 100%;
  }
  #main-bar.active #main-menu {
    display: block;
  }
  #main-menu {
    width: 100%;
    margin: 0;
    background-color: #56565a;
  }
  #main-menu.active li {
    display: block;
  }
  #main-menu.active li.mobile-menu-button {
    display: block;
  }
  #main-menu li {
    display: none;
  }
  #main-menu li.mobile-menu-button {
    display: block;
  }
  #main-menu > li {
    clear: both;
    width: 100%;
    border-bottom: 1px solid #999;
    padding: 0 15px;
  }
  #main-menu > li:last-child {
    border-bottom: none;
  }
  #main-menu > li.current > a, #main-menu > li:focus > a, #main-menu > li:active > a {
    color: #ffd944;
    font-weight: normal;
  }
  #main-menu > li > a {
    padding: 6px 0;
    color: #f5f5f5;
    font-size: 1.3rem;
    border-bottom: none;
  }
  #main-menu li.mobile-menu-button:after {
    content: '\f107';
    display: block;
    float: left;
    width: 100%;
    height: 20px;
    font-family: 'FontAwesome';
    color: #fff;
    text-align: center;
    padding: 13px 0;
    font-size: 20px;
    line-height: 0;
    z-index: 2;
    margin-top: 0;
    position: relative;
    top: 3px;
  }
  #main .container-fluid > br {
    display: none;
  }
  #main .container-fluid .grey-box.padded {
    border-width: 0 0 1px 0;
    border-radius: 0;
    margin-bottom: 0;
  }
  .color-wheel-container {
    display: none;
  }
  #opportunity-requirements {
    padding: 0;
  }
  #opportunity-requirements td {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  #opportunity-requirements .col-md-12 {
    padding: 0;
  }
  #radioshack > .row {
    margin-left: 0;
    margin-right: 0;
  }
  #radioshack .left, #radioshack .none {
    width: 100%;
    clear: both;
    display: block;
  }
  #radioshack .left {
    padding-left: 15px;
  }
  #radioshack .col-md-6 {
    padding-left: 0;
    padding-right: 0;
  }
  #radioshack .col-md-6:before, #radioshack .col-md-6:after {
    content: " ";
    display: table;
  }
  #radioshack .col-md-6:after {
    clear: both;
  }
  #radioshack .col-md-6:first-child {
    background-color: #fff;
  }
  #radioshack .padded {
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  #opportunity-details {
    padding-left: 0;
    padding-right: 0;
  }
  #opportunity-notes {
    padding-left: 0;
    padding-right: 0;
  }
  #form-requirements table tr td {
    display: block;
    clear: both;
    width: 100%;
  }
  .slider-box {
    margin: 0;
  }
  .gold-bar {
    height: auto;
  }
  .datepicker-box {
    display: block;
  }
  input[name="btn-send-estimate"] {
    float: right;
  }
  .gold-bar .search-bar {
    width: 100%;
  }
  .crm.opportunity #main > .container-fluid > .row > .col-sm-12,
  .crm.opportunity #main > .container-fluid > .row .contact-company {
    padding-left: 0;
    padding-right: 0;
  }
  .contact-row-header table td {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (min-width: 320px) {
  .col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xs-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xs-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xs-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xs-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xs-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xs-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xs-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xs-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xs-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xs-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xs-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xs-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xs-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xs-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xs-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xs-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}

.required {
  color: red;
}

#close-search-results {
  color: gray !important;
  position: absolute !important;
  top: 0px !important;
  left: 500px !important;
  z-index: 9999 !important;
}

#tracking-table_wrapper > .row:first-of-type {
  background-color: #ffc52f;
  padding: 5px;
}

.dataTables_wrapper > .row:first-of-type {
  background-color: #ffc52f;
  padding: 5px;
}

table.sticky-header thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

#tracking-table.sticky-header thead th {
  top: 215px;
}

.js-is-sticky {
  z-index: 101;
}

input.edit_inline {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: auto;
}

input.edit_inline:not(:disabled):hover {
  background-color: #fff0c8;
}

#projects-dashboard .widget {
  margin-bottom: 20px;
}

#projects-dashboard .exception-report table thead tr th {
  background-color: #6e6e6e;
}

#projects-dashboard .exception-report label {
  background: transparent !important;
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: none !important;
  background: none !important;
  border: none !important;
}

table.plain tbody td {
  font-size: inherit;
}

table.plain tbody td:first-child {
  padding-left: 0px;
}

@media print {
  a[href]:after {
    content: none;
  }
}

.text-white {
  color: white !important;
}

.p-0 {
  padding: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.sign-search-result {
  cursor: pointer;
}

.milestone-helper-wrapper .nav-tabs li {
  background-color: #e5e5e5;
}

.milestone-helper-wrapper .nav-tabs li.active {
  background-color: #ffc52f;
}

.milestone-helper-wrapper .nav-tabs li.active a {
  background-color: #ffc52f;
  border: none;
}

.w-25 {
  width: 25% !important;
}

/* t Editable fields */
.t-editable {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px;
}

.t-editable::before {
  display: inline-block;
  float: right;
  font: normal 14px/1 FontAwesome;
  content: "\f040";
  padding-top: 4px;
  padding-left: 5px;
  color: #ccc;
}

.t-editable:hover {
  border-color: #ccc;
}

.t-editable:hover::before {
  color: #aaa;
  cursor: text;
}

.t-editable.editing {
  border: 0;
  padding: 0;
}

.t-editable.editing::before {
  display: none;
}

.t-editable.loading input {
  background: url("/public/img/loading.gif") no-repeat right;
}
