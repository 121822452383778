body.crm.dashboard {
	#main > .container-fluid {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.crm.dashboard {
	.widget-footer {
		@include gradient( #01bc62, #3ecc89);
	}
}

.projects.dashboard {
    .dashboard-my-projects {
        .widget-body {
            padding-bottom: 200px;
        }
    }
}

.reports {
	.list-table {
		.widget-body {
			padding: 0 10px;
			table td {
				font-size: 12px;
				border-bottom: 1px solid #ccc;
				padding: 5px 0;
			}
		}
	}
	.widget {

		margin-top: 30px;

		.widget-inner {
			border-radius: 6px;
			border: 1px solid #ccc;
		}

		.widget-footer {
			font-size: 16px;
			text-transform: uppercase;
			text-align: center;
			color: #202020;
			font-weight: bold;
		}
	}
}

.widget {

	font-size: 14px;

	a { color: #028AFF; }

	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }

	.left { float: left; }
	.right { float: right; }
	.none { float: none; overflow: hidden; display: block; padding-right: 10px;}
	.graph-box {padding-left: 15px; padding-right: 15px; padding-bottom: 15px; }
	.fa-comment-o {
		top: 1px;
		font-size: 19px;
		&.active {
			color: #2788e4;
		}
	}
	.notice-number {
    width: 20px;
    height: 20px;
    background-color: #2788e4;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    border-radius: 99px;
    text-align: center;
    display: inline-block;
	}

	.widget-inner {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		border-radius: 6px; overflow: hidden;
	}

	.widget-header {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		padding-top: 10px;
		padding-bottom: 10px;
		font-weight: bold;
		font-size: 16px;
		padding-left:15px;
		padding-right:15px;
		border-bottom: 1px solid #ccc;
		@include gradient(#ffffff, #f5f5f5);
	}

	.widget-body {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		background-color: #fff; overflow-y: scroll;
	}

	.widget-controls {
		padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
	}

	.widget-footer {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		padding-left:15px;
		padding-right:15px;
		color:#fff;
		font-size: 20px;
		font-weight: normal;
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #f5f5f5;

		button {
			width: auto;
			font-size: 14px;
			padding-left: 17px;
			padding-right: 17px;
		}
	}

	.widget-list {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }

		.list-item {width:100%; font-size: 0.85em; border-bottom: 1px solid #a2a2a2; padding-left: 15px; padding-right:15px;}
		.list-item:last-child {border-bottom: none;}
		.list-item:before,
		.list-item:after {content: " "; display: table; }
		.list-item:after {clear: both; }
		.list-item .price-table td:first-child {padding-right: 8px; text-align: right;}
		.list-item { padding: 10px 15px; }
		.list-item p { margin-bottom: 10px; }
		.list-item input[type="checkbox"] {width: 20px;height: 20px;}
		.list-item .title { font-weight: bold;}

		label {
			border: 1px solid #ccc;
			border-radius: 8px;
			width: 100%;
			padding: 8px;
			@include gradient(#ffffff, #f5f5f5);
			.small { font-size: 12px; color: #45A2FA;}
			p {margin-bottom: 0;}
			&.pastdue {
				border: 1px solid #ca0b0b;
				.title {
					font-weight: bold;
					color: #ca0b0b;
				}
				.date {
					font-weight: bold;
					color: #ca0b0b;
				}
			}
		}
	}

	&.notes {
		textarea {
			height: 100%;
			border: none;
			font-size: 16px;
			padding: 20px;
			line-height: 1.5;
			resize: none;
		}
		.widget-body {
			overflow: hidden;
		}
		.widget-footer {
			border-top: 1px solid #ccc;
			background-color: #f5f5f5;
		}
	}

	&.estimates {
	}

	&.tasks {
		.widget-list {
			padding-top: 10px;
		}

		.widget-list td:first-child {
			padding-right: 8px;
		}

		.widget-header {
			.all_tasks {
				width: 30%;
				display: inline-block;
				float: left;
				text-align: right;
				padding: 6px 0;
			}

			.task_filters {
				width: 70%;
				display: inline-block;
				float: left;
			}

			.form-group {
				margin-bottom: 0;
			}
		}
	}

	&.graph {
		.widget-body {
			height: auto; overflow :hidden;
		}
	}

	&.opportunities {
		.widget-body {
		}
	}
	&.messages {
		.widget-body {
		}
	}

	.form-row	{
		&:before,
		&:after { content: " "; display: table; }
		&:after { clear: both; }
		&.row,.row { margin-left:-4px; margin-right:-4px; }
		&[class^="col-"], [class^="col-"]{padding-left: 4px; padding-right: 4px; }

		.form-group {
			margin-bottom: 0;
			&:before,
			&:after { content: " "; display: table; }
			&:after { clear: both; }
			.form-control {margin-bottom: 0;}

			&.row,.row { margin-left:-4px; margin-right:-4px; }
			&[class^="col-"], [class^="col-"]{padding-left: 4px; padding-right: 4px; }
		}
	}
}

.widget-row {
	padding-top: 30px;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

.color-wheel {
	width: 40px; height: 40px; border-radius: 99px; border: 2px solid #fff; display: inline-block;
	img { width: 100%;}
	button {position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; z-index: 10;}
}

.crm.tasks .widget {
	&.tasks .widget-controls {border-bottom: none;}
	&.notes .widget-inner {border: 1px solid #ccc;}
	.widget-footer {
		@include gradient(#f5f5f5,#f5f5f5);
	}
}

.crm.tasks {
	.widget { height: auto !important; }
	.widget-body { height: auto !important; overflow: visible;}
}
[id^="graph-"] {
	height: 100%;
}
[id^="google-visualization-errors"] {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
[id^="google-visualization-errors"] > div > span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    background: #f5f5f5 !important;
    color: #202020 !important;
}

.widget.tabbed {

	.widget-tabs {
		.tab {
			float: left;
			display: inline-block;
			background-color: #56565a;
			color: #fefefe;
			border-radius: 3px 3px 0 0;
			padding: 13px 20px 8px;
			font-size: 13px;
			margin-right: 5px;

			&.current {
				color: #56565a;
				background-color: #fefefe;
			}
		}
	}

	.widget-control {
		@include gradient(#fefefe, #f1f1f1);
		padding: 18px;
		.control {
			display: inline-block;
			float: left;
			padding: 0 7px;
			height: 34px;
			span {
				position: relative;
				display: block;
				top: 50%;
				transform: translateY(-50%);
				-o-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
			}

			&:first-child {
				padding-left: 0;
				padding-right: 7px;
			}
			&:last-child {
				padding-left: 7px;
				padding-right: 0;
			}
		}
	}

	.widget-body {
		padding: 8px 18px 18px;
		height: 641px;

		.separator {
			color: #198dfc;
			padding-bottom: 5px;
			border-bottom: 3px solid #9d9d9d;
			font-weight: bold;
			margin-top: 10px;
		}

		.checkblocks {
			padding-top: 3px;
		}

		.checkblock {
			border: 1px solid #9d9d9d;
			border-radius: 3px;
			margin-top: 8px;
			padding: 10px;

			.right {
				position: absolute;
		    right: 10px;
		    top: 50%;
		    transform: translateY(-50%);
		    -o-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    -moz-transform: translateY(-50%);
		    -webkit-transform: translateY(-50%);

		    .date {
		    	padding-right: 25px;
		    	display: inline-block;
		    }

		    i.fa {
		    	font-size: 22px;
		    	color: #9d9d9d;
		    	&.active {
						color: #198dfc;
		    	}
		    }
		    .circle {
			    border-radius: 99px;
			    color: #fff;
			    background-color: #198dfc;
			    font-weight: bold;
			    font-size: 18px;
			    display: inline-block;
			    width: 25px;
			    height: 25px;
			    text-align: center;
			    line-height: 27px;
		    }
			}

			.none {
				line-height: 1.3;
				.small {font-size: 0.8em;}
			}

			input[type="checkbox"]{
				margin-right: 10px;
			}
		}
	}
}

@media (min-width:769px){

	.widget.google-graph {
		height: auto;
	}

  .reports .widget {height: auto;}
	.widget {


		.widget-body {
			height: 450px;
		}

		&.opportunities {
			.widget-body {
				height: 450px;
			}
		}

		&.messages {
			.widget-body {
				height: 403px;
			}
		}

		&.tasks {
			.widget-body {
				height: 395px;
			}
		}
	}

	.crm.tasks {
	}

	.notes-widget {
		padding-left: 50px;
		padding-top: 20px;
	}
}

@media (max-width:768px){

	.widget-row { padding-top: 0; }
	.widget {

		padding-left: 0;
		padding-right: 0;
		clear:both;
		margin-bottom: 0;

		.widget-inner {border-radius: 0;}

		.widget-header {font-weight: 500; font-size: 13px; border-top: 1px solid #ccc;}

		.widget-body {overflow-y: visible; padding-bottom: 15px;}

		.form-row .form-group {
			.form-control {
				margin-bottom: 10px !important;
			}
		}
		//.widget-list .list-item .title {text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}

	}
}

@media (max-width:320px) {
	.widget.tasks {
		.form-group {
			padding: 0;
		}
	}
}
