table {
    tr.gold {
        th, td {
            background-color: #FFC52F;
            color: #56565a;
        }
    }
    tr.black {
        th, td {
            background-color: #56565a;
            color: #ffffff;
        }
    }
    tr.no-border {
    	td {border: none !important;

				&.border {
					border-top: 1px solid #ddd !important;
				}
    	}
    }
    td { color: #808080; }
    td.total_summary {
    	text-align: right;

    }
	i.fa {margin-right: 10px;}
	i.fa {margin-right: 10px;}

	&.light-grey {
		thead tr {background:#E3E3E3;color:#ffffff; color: #6e6e6e;}
	}
	thead tr th {border-bottom:0;padding-top:10px;padding-bottom:10px;}
	thead tr {background:#6e6e6e;color:#ffffff;}
	tbody {
		tr td {vertical-align:middle; padding-top:9px; padding-bottom:9px; font-size: 15px;}
		tr:nth-child(even) {background:#f7f7f7;}
		tr:first-child td {border-top:0;}
		tr.est-parent {
			td {height: 50px;}
		}
		tr.past-due {
            background:#f76262 !important;

			td {
                color: #fff;

                span {
                    color: #fff;
                }

                a {
                    color: #fff;
                }
            }
        }
		tr.status-new {
			background:#a3ffe6 !important;
			td {
				height: 50px;
				vertical-align: middle;
			}
		}
		tr.status-hold {background:#ffdede;}
	}

	thead th:first-child,
	tbody td:first-child {padding-left:35px;}
	thead th:last-child,
	tbody td:last-child {padding-right:35px;}

    .table-form-row {
        .table-form {
            > div {
                height: 34px;
                float: left;
                display: inline-block;
                padding-left: 10px;
                select { margin: -4px 0; }
            }
        }
    }
}



.est-child-row {
	background-color: transparent !important;
	td {
		height: 30px !important;
		border-bottom: 1px solid #202020;
		border-top: none !important;

		&:first-child {
			border-bottom: none;
		}
	}
}

.panel-tables {
	.blue-text.left {cursor: pointer; position: relative; width: auto; right: 0; color: #3097D1; font-size: 15px; font-weight: normal; border-left: 1px solid #3097D1; margin-left: 22px; padding-left: 12px; }
	//{border-bottom: 1px solid #95989a;}
	tr {
		td .form-control {height: auto;}
		td select.form-control {height: 36px; margin-bottom: -23px;}
		th:first-child {width: 357px;}
		th:last-child {width:50px;text-align: center; padding-right:0px;}
		th:nth-child(2) {width: 639px;}
		th:last-child,
		th.tax,
		td.tax,
		td:last-child {width:50px;text-align: center;padding-right:15px;}
		td:first-child {width: 357px;}
	}

	td div.none:last-child {
		display: block;
	}

	th.cost,
	th.markup,
	th.sales { width: 115px; }
	th.tax {background-color: #E7DEC8 !important;}
	th.tax,
	td.tax { width: 53px; text-align: center; color: #6e6e6e ;}
	table.install {
		tr th:nth-child(3),
		tr th:nth-child(4),
		tr th:nth-child(5),
		tr th:nth-child(6),
		tr th:nth-child(7){background-color: #d7edf8;}
		tr td:nth-child(3),
		tr td:nth-child(4),
		tr td:nth-child(5),
		tr td:nth-child(6),
		tr td:nth-child(7){background-color: #d7edf8;}
		th td:nth-child(3){width: 70px;}
		th td:nth-child(4){width: 60px;}
		th td:nth-child(5){width: 60px;}
		th td:nth-child(6),
		th td:nth-child(7){width: 100px;}
	}

	.uom { display: inline-block; float: left; }
	.dimension { float: none; overflow: hidden;  }
	.dimension span:first-child,
	.dimension span:last-child { width: 170px; text-align: center;}
	.dimension span { display: inline-block; }
	table tr td:first-child {padding-left: 8px !important;}
}

table.summary {
	td {
		padding: 0 20px;
		font-size: 13px;
	}
}

table.green {

	margin-bottom: 22px;
	width: 100%;
	border-bottom: 1px solid #95989a;
	thead tr th {font-weight:normal; border-bottom:0;padding-top:10px;padding-bottom:10px;background-color: #079f59; color: #fff;}
	thead tr {background:#6e6e6e;color:#ffffff;}
	thead {
		th:first-child{padding-left:15px;}
		th:last-child {padding-right:15px;}
	}
	tbody {
		tr td { vertical-align:middle;padding-top:9px;padding-bottom:9px; font-size: 15px; }
		tr:nth-child(even) {background:#f7f7f7;}
		tr:first-child td {border-top:0;}
		tr.past-due {background:#f76262;
			td { color: #fff; a{color: #fff}}
		}
		tr.status-new {background:#a3ffe6;}
		tr.status-hold {background:#ffdede;}
		th:first-child,
		td:first-child {padding-left:15px;}
		th:last-child,
		td:last-child {padding-right:15px;}
	}
}

table.summary_table {
	margin: 0 30px;
	float: right;
	width: 320px;
	td {
		padding: 15px;
	}
}
tr.black th {font-weight: normal;}

.list-table-container {

	float: left;
	width: 100%;

	&:before,
	&:after { content: " "; display: table; }
	&:after { clear: both; }

    &.schedule table {

        tr:last-child {
            border-bottom: none;
            td { border-bottom: none; }
        }

        tr.black, tr.gold {
            td, th { font-weight: 500; text-align: left;}
        }

        tr.old {
            td { color: #b3b3b3; }
        }

        tr.past-due {
            background-color: #F2C3C3 !important;
        }

        tbody {
            td, th {text-align: left; padding: 5px;}
            td {
                padding: 5px 10px;
                img { margin-left: 0 }
                input[type="checkbox"] {margin-left: 5px;}
            }
            tr:nth-of-type(even) {
                background-color: #fff;
            }
            tr:nth-of-type(odd) {
                background-color: #f7f7f7;
            }
        }
    }

	table {

		width: 100%;
		th, td {
			input {margin-top: -4px; margin-bottom: -4px;}

			&.spacer { width: 40px;}

			&.black {
				background-color: #56565a; padding: 10px; color: #fff; font-weight: normal;
			}

			&.beige { border-bottom: 1px solid #ccc; font-weight: normal; }
			&.white { background-color: #fff !important; }
			&.gold { background-color: #fec442 !important;  color: #56565a;}

			.fa-bars,
			.fa-close {margin-right: 7px; float: left;}
			.fa-bars {color: #079f59; font-size: 22px; top: 7px;}
			.fa-close {color: #e00000; font-size: 18px; top: 2px; }
		}

		thead {

			th {
				font-size: 13px;
				padding:10px;
				font-weight: 500;
				vertical-align: middle;
			}

			&.black th { background-color: #56565a; padding: 10px; color: #fff; font-weight: normal; }
			th:first-child { padding-left: 40px; }
			th:last-child { padding-right: 40px; }
		}

		tbody {

			tr { border-bottom: 1px solid #CACBCC; }
			td {
				padding: 10px 15px;
				font-size: 13px;

				&:last-child { text-align: right;}
			}

			tr.child {

				td:first-child:before {
					content: '';
					width: 20px;
					float: left;
					display: block;
					height: 20px;
				}
			}

			tr.header {

				&.collapsed td:first-child:after {
					content: '+';
					font-weight: bold;
					font-size: 17px;
				}

				td:first-child:before {
					content: '';
					width: 0;
					float: left;
					display: block;
					height: 20px;
				}

				td:first-child:after {
					content: '-';
			    font-size: 27px;
			    border: 1px solid #606060;
			    display: inline-block;
			    height: 18px;
			    position: absolute;
			    line-height: 0.5;
			    border-radius: 3px;
			    margin-left: 10px;
			    width: 21px;
			    text-align: center;
				}
			}
		}
	}

	&.crm-contacts {

		tbody tr:nth-of-type(odd) {background-color: #f7f7f7 !important; }
		tbody td { border-bottom: 0 }
		tbody tr td:last-child { max-width: 100px;}

		tbody tr td,
		thead tr th {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}

	}

	&.alternating-rows {

		tbody tr:nth-of-type(odd) {

			background-color: #f7f7f7 !important;

			td.green {}

		}
	}

	&.last-column-left-align {

		tbody tr td:last-child { text-align: left;}
	}

	&.last-column-gold {
		> table {

			> thead {
				> tr > th:last-child { background-color: #fec442; color: #56565a; font-weight: 500;}
			}
			> tbody {
				> tr:nth-of-type(odd) > td:last-child { background-color: #FFF6E0; }
				> tr:nth-of-type(even) > td:last-child { background-color: #F8EFD9;}
			}
		}
	}

	&.no-rules {
		tbody tr {border: none;}
	}
}

@media (max-width:768px) {
	.list-table-container {
		table thead th {
			&:first-child, &:last-child {
				padding: 10px;
			}
		}
		table tbody th {
			&:first-child, &:last-child {
				padding: 10px;
			}
		}
	}

	body.crm.opportunity.files {
		table tr > th {
			&:first-child {display: block;}
			display: none;
		}

		table tbody tr > * {
			display: inline-block;
		}

		table tbody td {
		}

		table tbody tr td:last-child {
			display: table-row;
			width: 100%;
			clear: both;
			float: left;
		}
	}

}
.contact-row-header table td {padding-left: 0 !important;padding-right: 0 !important;}
