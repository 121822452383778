.fa-close {
	color: #e00000;
	font-size: 18px;
	top: 2px;
    cursor: pointer;
}

.btn-remove-mtf { vertical-align: top;
    cursor: pointer; }

.inline { display: inline-block; float:left; }

.auto-width { width: auto !important;
    cursor: pointer; }

.btn { font-size:1.6rem;
    cursor: pointer; }

button.auto-width { width: auto !important;
    cursor: pointer; }

.btn-sunglow { background:var(--sunglow); color:#56565a;
    cursor: pointer; }

.copy-estimate { cursor: pointer;}
.projects.estimates .gold-bar a.button {
	padding: 7px 20px 7px 20px;
  position: relative;
  top: 5px;
  color: #fff;
}

.gold-button {

	&:hover {text-decoration: none; }

    cursor: pointer;
	text-align: center;
	font-weight: 500;
	width: 203px;
	color:#56565a !important;
	font-size: 15px;
	border-radius: 4px;
	border: none;
	padding-left: 15px;
	padding-right: 15px;
	line-height: 25px;
	@include gradient(#ffc52f, #edaa10);
}

.black-button {
	background-color: #56565a;
	color: #fff;
	width: 203px;
	font-size: 17px;
	border-radius: 4px;
	border: none;
  cursor: pointer;
    &:hover {
    	color: #ccc;
    }
}

.red-button {
  background-color: #d9534f;
  color: #fff;
  width: 203px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
      color: #ccc;
  }
}

.transparent-button {
	background-color: transparent;
	color: #56565a;
	width: 203px;
	font-size: 17px;
	border-radius: 4px;
	border: none;
  cursor: pointer;
  font-weight: normal;
}

.green-button {
	background-color: #079f59;
	color: #fff;
    cursor: pointer;
}

.lime-button {
	background-color: #1fd984;
	color: #fff;
	width: 203px;
	font-size: 17px;
	border-radius: 4px;
	border: none;
    cursor: pointer;

	&.inline { padding: 0 10px;}
}

.gold-button.small {

	max-width: 100px;
}

.gold-button a {

	color: #56565a;
    cursor: pointer;
}

button.duplicate {
	font-size: 16px;
	text-align: center;
	i.fa {
		margin-right: 0;
	}
}

button.small-green {
	background: #2dce80;
	background: -webkit-linear-gradient(-45deg, #2dce80 0%,#1da461 100%);
	background: linear-gradient(135deg, #2dce80 0%,#1da461 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2dce80', endColorstr='#1da461',GradientType=1 );
	border-radius: 11px;
	color: #fff;
	border: none;
	width: 34px;
	height: 24px;
	line-height: 19px;
	text-align: center;
	margin-bottom: 3px;
}

.header-button {
	font-size: 14px; padding: 5px 0;
	img {position: relative; top: -1px; margin: 0 5px;}
    cursor: pointer;
}

.minus-button {
    border: 1px solid #202020;
    border-radius: 2px;
    width: 17px;
    height: 17px;
    display: inline-block;
    top: 4px;
    margin-right: 6px;
    font-size: 22px;
    cursor: pointer;

	&:before {
        content: '-';
        line-height: 14px;
        text-align: center;
        position: relative;
        display: block;
	}

	&.plus {
		top: 2px;
		font-size: 19px
	}

	&.plus:before {
		content: '+';
		line-height: 11px;
		text-align: center;
        position: relative;
        display: block;
	}
}

.cross-button {
    border: 1px solid #ccc;
    padding: 4px 9px;
    cursor: pointer;
}

div[class="-button"] {
	position: relative;

	&.inline {
		display: inline-block;
		float: left;
	}
    cursor: pointer;
}

.blue-arrow {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #198DFC;
  text-align: center;
  top: 4px;
    cursor: pointer;
  i {line-height: 32px;}
}

img[src*="entry-add.svg"] {
	width: 26px;
	height: 26px;
	top: -1px;
	margin-right: 0;
	margin-left: 6px;
    cursor: pointer;
}

.person-rows img[src*="entry-add.svg"] {
	float:right;
	margin-right: 0;
}

img[src*="entry-delete.svg"] {
	width: 21px;
	height: 34px;
    cursor: pointer;
}

img[src*="cancel.svg"] {
	width: 34px;
	height: 34px;
    cursor: pointer;
}

img[src*="lightbulb.jpg"] {
	margin-right: 12px;
}
