$gold: #ffc52f;

.red { color: #d60027; }

.blue { color:#2091FC; }

.grey { background-color: #f5f5f5; }

.blue-meta { color: #3097D1; }

.blue-link { color: #3097D1; }

.blue-text { color: #45A2FA; }

.green-text { color: #15b165; }

.gold { color: $gold; }

.gold-text { color: #cc9a16; }

.beige { background-color: #f5f5f5; }

.row.grey { background-color: #f7f7f7; }

.dark-green-text { color:#159a59; }

.grey-button { background-color: #9a9a9a; color: #fff; padding: 5px 8px; text-align: center;}

.transparent-button { padding: 5px 8px; }

.highlight-green { background-color: #dff0d8 !important; }
.highlight-red { background-color: #f2dede !important; }

i.fa.fa-circle {
    top: 4px;
	&.active{color: #8BEBBC;}
	&.yellow{color:#FCDC8C;}
	&.inactive{color:#E88A8C;}
}

.box-body {padding: 25px 15px;}
.grey-box {

	background-color: #f5f5f5;

	&.aside:before {
    content: '';
    min-height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    display: block;
    position: fixed;
    margin: 0 -15px;
    top:0;
    bottom:0;
	}


	&:before,
	&:after { content: " "; display: table; }
	&:after { clear: both; }

	.grey-box-body { padding: 25px 15px; }
	&.padded {
		border: 1px solid #bfbfbf;
		border-radius: 5px;
	}

	&.collapsed-form {
		margin-bottom: 15px;
		> .row { margin-top: 10px;}
	}

	&.collapsed-form + .collapse {
		padding-bottom: 15px;
	}
}

.gold-bar {
	background-color: $gold;
	padding-left:15px;
	padding-right:15px;
	height: 59px;
	z-index: 100;

	.search-bar {

		width: 400px;

		.input-group {
		}

		.input-group-addon {
			background-color: #fff;
			border: none;
		}

		.none {display: block;}
	}
}

.black-bar {
    background-color: #666;
}

.grey-bar {
	background-color:#56565a;
	padding-left:30px;
	padding-right:30px;
	height: 59px;
	z-index: 100;

	> div {
		top: 50%;
		transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}

	h1, h2, h3, h4, h5 { margin: 0;}
}
