// out: ../../../public/css/app.css, sourceMap: true, autoprefixer: last 5 versions, groupMedia: true
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import '_tables';
@import '_forms';
@import '_helpers';
@import '_widgets';
@import '_colors';
@import '_buttons';
@import '_modal';
// Variables
//@import "variables";

@mixin box-sizing (){
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

@font-face {
	font-family: 'HelveticaNeue-Heavy';
	src: url('../fonts/HelveticaNeue-Heavy.eot');
	src: url('../fonts/HelveticaNeue-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue-Heavy.woff2') format('woff2'),
		url('../fonts/HelveticaNeue-Heavy.woff') format('woff'),
		url('../fonts/HelveticaNeue-Heavy.ttf') format('truetype'),
		url('../fonts/HelveticaNeue-Heavy.svg#HelveticaNeue-Heavy') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeue-Medium';
	src: url('../fonts/HelveticaNeue-MediumExt.eot');
	src: url('../fonts/HelveticaNeue-MediumExt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue-MediumExt.woff2') format('woff2'),
		url('../fonts/HelveticaNeue-MediumExt.woff') format('woff'),
		url('../fonts/HelveticaNeue-MediumExt.ttf') format('truetype'),
		url('../fonts/HelveticaNeue-MediumExt.svg#HelveticaNeue-Medium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeue-Condensed';
	src: url('../fonts/HelveticaNeue-Condensed.eot');
	src: url('../fonts/HelveticaNeue-Condensed.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue-Condensed.woff2') format('woff2'),
		url('../fonts/HelveticaNeue-Condensed.woff') format('woff'),
		url('../fonts/HelveticaNeue-Condensed.ttf') format('truetype'),
		url('../fonts/HelveticaNeue-Condensed.svg#HelveticaNeue-Condensed') format('svg');
	font-weight: normal;
	font-style: normal;
}

:root {--sunglow:#ffc52f; }

*  { position: relative; }

body { overflow-x:hidden; background:#ffffff; font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif; font-size:1.6rem; color: #56565a; font-size: 15px; }

body.crm.dashboard { background-color: #022949; }
body.projects.dashboard {
	background-color: #292929;
	#main {
		padding-top: 15px;
	}
}
body.management:not(.estipro) {
	background-color: #292929;
	#main {
		padding-top: 15px;
	}
}

body.estipro.scope {
    .sticky {
        position: fixed;
        top: 85px;
        width: 100%;

        .tabs {
            display: block !important;
            background-color: white !important;
            width: 100%;
            height: 100px;
            top: -100px;
            position: absolute;


            #main-menu {
                position: absolute;
                right: 115px;
                bottom: 0px;

                li::after {
                    z-index: 0;
                }

                li {
                    a {
                        z-index: 1;
                    }
                }
            }
        }
    }

    /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
    .sticky + #detail-controls {
        margin-top: 200px;
    }
}
.location-panel-body:before {display: none;}

.ui-datepicker {z-index: 1051 !important; }

body.estipro i.fa-bars {color: #079f59;}

table { width: 100%; }

h1, h2, h3, h4, h5, h6 {margin-top:0; @include noSelect; }

label { @include noSelect; }

th { @include noSelect; }

h3 { font-size: 26px; }

p { margin-bottom:9px;}

p.large { font-size: 26px; }

a {
	color: #45A2FA;
    cursor: pointer;
	&:hover {
		text-decoration: none;
	}
}
button.collapse {display: block;}
b, strong { font-weight: 600; }
i.fa { cursor: pointer;}
div {
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}
.search-button {
    cursor: pointer;
}
.row:before,

.row:after { content: " "; display: table; }

.row:after { clear: both; }

.clearfix {
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

//input {cursor:pointer;}
select { cursor:pointer; }

@media (min-width: 1300px) {
    body.estipro.detail {
        min-width: 1300px;
    }
}



body.projects {
	#main {
		.container-fluid {
	//		width: 1707px;
		}
	}
}

body.projects.files {
	#main {
		.container-fluid {
			width: 100%;
		}
	}
}

tr.warning th, tr.warning td { background-color: #fcf8e3;}
tr.error th, tr.error td { background-color: #f2dede;}


.spacer{

	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

.edit-input { cursor: pointer; }

.edit-link { color: #028aff !important; font-size: 16px !important; font-weight: normal; text-decoration: underline;}

.small { font-size: 0.90em;}

.left { float: left; display: inline-block;}

.right { float: right; }

.right-corner { position: absolute; right: 0; top: 0; }

.none { float: none; overflow: hidden;  display: inline-block;}
.task-helper-task-list-item .none{
    width: 80%;
}

.ellipsis {text-overflow: ellipsis; white-space: nowrap;}

.bold { font-weight: bold; }

.sep { display: inline-block; width: 2px; height: 20px; background-color: #a5a5a5; margin: 0 7px; top: 5px; }
.no-border{ border: none;}
.transparent { background-color: transparent;}
img+label+hr { margin-top: 0 }

.document-icon { font-size: 27px; margin-right: 11px;}

.document-icon.ai-icon:after { content: 'Ai'; text-align: center; display: block; position: absolute; top: 50%; left: 50%; transform:translate(-50%,-50%);font-size: 15px;}

.file-folder { font-size: 27px; position: relative; top: 2px; margin-right: 11px;}
.fa-file-o { font-size: 27px; position: relative; top: 2px; margin-right: 11px;}

.lightning-cloud { font-size: 27px; float: right;}

.lightning-cloud i.fa.fa-bolt { font-size: 13px; position: absolute; top: 50%; left: 50%; color: #fff; transform: translate(-50%,-50%) rotate(23deg); }

tr[data-child]{
	display: none;
}

.mail-body { height: 100%; font-size: 16px;}

.mail-header { background-color: #56565a; padding:15px 0; text-align: center;}

.mail-content {
	height: 100%; padding-top: 50px;
	h1, h2, h3, h4, p {
		margin-bottom: 30px;
	}
}

.mail-footer { height: 50px; }

.jumbotron { padding: 40px;}

@media (min-width: 1200px) {
    .section { padding-left:35px; padding-right:35px;}
    .padded-2x {padding:20px !important;}
    .padded-3x {padding:30px !important;}
    .padded-4x {padding:40px !important;}
}

@media (min-width: 768px) and (max-width: 1200px) {
    .padded-2x {padding:15px !important;}
    .padded-3x {padding:25px !important;}
    .padded-4x {padding:35px !important;}
}

@media (min-width: 400px) and (max-width: 768px) {
    .padded-2x {padding:10px !important;}
    .padded-3x {padding:20px !important;}
    .padded-4x {padding:30px !important;}
}

@media (max-width: 400px) {
    .padded-2x {padding:5px !important;}
    .padded-3x {padding:15px !important;}
    .padded-4x {padding:25px !important;}
}

#approvals {
    #apm {
        top: 12px;
        right: 40px;
    }
}

@media (max-width: 768px) {
    #approvals {
        #apm {
            top: -6px;
            right: 0px;
        }
    }
}


.noxform strong { text-transform:none;}

.section-heading { background:#6e6e6e;color:#ffffff;font-weight:700;padding:10px;padding-left:40px;}

.placeholder-button { visibility:hidden; width:0; height: 34px }

input[disabled] + .save-rename { display: none; }

input + .save-rename { display: inline-block; }

.nav-tabs li.active { color: #56565a; }

.popover {
	top: 30px;

	&:before {

		content: '';
		position: relative;
		display: block;
		top: -10px;
		left: 10px;
		width: 0;
		height: 0;
	  border-left: 10px solid transparent;
	  border-right: 10px solid transparent;
	  border-bottom: 10px solid rgba(0, 0, 0, .2);
	}

}
.poppable-trigger {
    display: inline-block;
}

.popover-close {
	position: absolute;
    right: 10px;
    top: 0;
    width: 20px;
    height: 20px;
    text-align: center;
}

.popover-content {
	&:before {

		content: '';
		position: relative;
		display: block;
		top: -28px;
		left: -3px;
	  width: 0;
	  height: 0;
	  border-left: 9px solid transparent;
	  border-right: 9px solid transparent;
	  border-bottom: 9px solid white;
	}
}
.horizontal-rule {
    margin-bottom: 20px;
    div { border-bottom: 1px solid #ccc; padding: 10px 0; }
}

.scope-input-group {
	float: left;
	display: inline-block;

}

/* general styles */

#masthead select.user_select {background-color: transparent; border: none;}

#masthead select.status_select {background-color: transparent; border: none;}

.charcoal.list {
	background-color: #444;
	color: #fff;
	font-size: 13px;
	font-weight: 300;
	border-radius: 5px;
	overflow: hidden;
	div {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		border-bottom: 1px solid #ccc;
		span {
			margin: 4px 0;
	  	display: inline-block;
		}
	}

	.fa {
		margin-right: 10px;
		text-align: center;
		width: 30px;
		height: 30px;
		background-color: #202020;
		display: inline-block;
		float: left;
		padding: 6px 0;
		&:before {
	    position: absolute;
	    top: 50%;
	    left:50%;
	    transform: translate(-50%,-50%);
	    -o-transform: translate(-50%,-50%);
	    -ms-transform: translate(-50%,-50%);
	    -moz-transform: translate(-50%,-50%);
	    -webkit-transform: translate(-50%,-50%);
		}
	}
}

.datepicker-box {
	display: inline-block;
	font-family: 'FontAwesome';
	&:before {
		content: "\f073";
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    color: #3097D1;
    z-index: 1;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
	}
}

.datepicker {
	//padding-left: 30px;
	font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
}

.add-separator-right {
	padding-right: 50px;

	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 24px;
		height: 100%;
		width: 2px;
		background-color: #fff;
		top: 50%;
		transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
}

.section-header {
	margin-bottom: 10px;
	padding-bottom: 5px;
	border-bottom: 2px solid #202020;
}

.spinner-underlay {
	position: absolute;
	right: 4px;
	z-index: 100;
	font-size: 7px;
	top: 50%;
	@include middleAlign;
}

#masthead, .gold-bar {
	 .header-select { width: 130px; display: inline-block; }
}

.header-input { width: 150px; display: inline-block; }

.checkbox label { padding-left:24px;font-size:1.4rem; cursor:default }

ul.reset {list-style:none;margin:0;padding:0;}

.flex {display:flex;align-items:center;}

hr { margin: 12px 0; background-color: #c4c4c4; color: #c4c4c4; border-color: #c4c4c4;}
/* button */
.item-link {
    cursor: pointer; input {
    cursor: pointer;}}
// input:enabled.dynamic-width { width: auto !important;}
input[disabled].dynamic-width {
	background-color: transparent;
	border-color: transparent;
	width: 100px;
	padding-left: 0;
	margin-left: 0;
	border-left: 0;
}

.horizontal-blocks {
	margin: 20px 0;
	div {float: left; display: inline-block; margin-right: 60px;}
}

div[class="col-md-12"] {}
div[class="col-md-12"]:before,
div[class="col-md-12"]:after {content: " "; display: table; }
div[class="col-md-12"]:after {clear: both; }


div[class*="-bar"]:before,
div[class*="-bar"]:after {content: " "; display: table; }
div[class*="-bar"]:after {clear: both; }

div[class*="-bar"] .bar-label { margin: 0; font-size: 21px; line-height: 36px;}
div[class*="-bar"] .right button { height: 38px; line-height: 38px; }
div[class*="-bar"] .right select { width: 160px; margin-right: 11px; cursor:pointer; }

.brand.dash { height: 100%; width: 285px; display: block; float: left; overflow: hidden; padding: 2px; margin-left:15px;}
.brand.dash img { width: 100%; height: 30px; }

textarea.form-control { height: 90px; }

.checkbox-box { position: relative; top: 5px;}

.padded { padding: 10px }

.navbar .container-fluid { height: 100%; }

nav { height: 34px; }

.navbar-nav {

    flex-direction: inherit;
	a {text-decoration: none;}
	height: 100%; margin: 0;
	&.navbar-right li:last-child {padding-right: 24px;}
	&.navbar-right li .fa { font-size:18px; position: relative; top: 2px;}
	ul {height: 100%; margin: 0; padding: 0;}
	> li { height: 100%; padding: 7px 15px; display: inline-block; float: left;}
	> li > a {  font-size: 13px; padding: 0;}
	li.dark {
		//margin-left:30px;
		~ .dark {margin-left: 0;}
	}
}

.nav > li.active {
    background-color: black;
    > a {
        color: #ffc52f;
    }
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: transparent;
	color: #ffc52f;
}

.jumbotron {
	border: 1px solid #c4c4c4;
	&.square { border-radius: 0 !important; }
	h1 { font-size: 42px;}
	+ .jumbotron { border-top: 0; }
}

.mega-menu {

	.mega-inner {
		margin-top: 5px;
		background-color: #fff;
		border: 1px solid #ccc;
		box-shadow: 2px 2px 17px -2px #aeaeae;
		height: 250px;

		.none {
			display: block;
		}

		&:before {
			content: '';
			 width: 0;
		  height: 0;
		  border-left: 5px solid transparent;
		  border-right: 5px solid transparent;
		  border-bottom: 5px solid white;
		  position: absolute;
		  top: -5px;
		  left: 20px;
		}

	}

	width: 580px;
	z-index: 10;
	height: 255px;
	left: 20px;
	border-radius: 4px;
	overflow: hidden;
	display: none;
	position: absolute;

	&:hover {
		display: block;
	}


	.list-header {
		background-color: #a5a5a5;
		color: #fff;
		text-transform: uppercase;
		padding: 5px 10px;
		font-size: 14px;
	}

	ul {margin: 0; padding: 0;
		list-style: none;

		li { border-bottom: 1px solid #ccc; color: #202020;

		&:last-child {border-bottom: none}

			a {padding: 6px 10px; color: #202020; display: block;}
		}
	}

	.left {height:100%;border-right: 1px solid #ccc;}

	.left li {
	}

	a:hover {text-decoration: none;}
	.gold-button {margin: 8px; height: 40px; line-height: 26px;

		strong {
	    font-size: 1.5em;
	    margin-right: 6px;
		}
	}
}

.form-heading { font-size: 20px; }

.form-label { font-size: 15px; }

.collapsed-form button.collapse:after {display: inline-block; content: '-'; position: absolute; width: 100%; display: block; color: #666; height: 100%; text-align: center; top: 50%; left: 50%; transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); }

.collapsed-form button.collapsed:after {display: inline-block; content: '+'; position: absolute; width: 100%; display: block; color: #666; height: 100%; text-align: center; top: 50%; left: 50%; transform: translate(-50%,-37%); -o-transform: translate(-50%,-37%); -ms-transform: translate(-50%,-37%); -moz-transform: translate(-50%,-37%); -webkit-transform: translate(-50%,-37%); }

.collapsed-form button.collapse,

.collapsed-form button.collapsed { display: inline-block; outline: none; line-height: 14px; padding: 1px 1px 2px 2px; border: 1px solid #666; border-radius: 4px; height: 21px; width: 21px; background-color: transparent; margin-right: 10px; position: relative; top: 2px; }

.float-left.aside {float: left; width: 90px; }

.float-none.aside {padding-left: 10px; float: none; overflow: hidden; }

.spacer:before,

.spacer:after {content: " "; display: table; }

.spacer:after {clear: both; }

.opportunity-header {
	.left {width: 300px; float: left; padding-right: 10px;}
	.name {clear: both; display: block; font-size: 0.8em;}
	input[type="button"] { margin: 0 10px; }
	img { margin: 0 !important; }
}

.contact-repeater {
	margin-bottom: 8px;
	.left { width: 90px; }
	select {cursor:pointer;
	border-radius: 4px 0 0 4px;
	-webkit-appearance: none;
  -webkit-border-radius: 4px 0 0 4px;}
	input {border-radius: 0 4px 4px 0; cursor:pointer;}
	select,input {border:1px solid #ccc;cursor:pointer;}
	.input-group { width: 100%;}
}

.dot-status.gold { background-color: gold;}

.dot-status {display: inline-block; float: left; height: 30px; width: 30px; border-radius: 99px; margin-right: 10px; }

.percent-bar, .status-bar {display: inline-block;}

.status-bar {
	background-color: #666; color: #fff; font-size: 11px; padding: 2px 5px; border-radius: 4px; margin-left: 10px;
	width: 100%; text-align: center;
	&.needs-analysis { background-color: #FEC442;  }
	&.presentation { background-color: #198DFC; }
	&.on-hold { background-color: #DD0A16;  }
	&.won { background-color: #1A9C5B;  }
}

.percent-bar {width: 100px; height: 9px; border: 1px solid #a2a2a2; border-radius: 99px; box-shadow: 0px 2px 9px -2px #a2a2a2 inset; overflow: hidden; }

.percent-bar .bar {height: 100%; background-color: gold; border-radius: 0 99px 99px 0; box-shadow: 0px 2px 9px -2px #a2a2a2 inset; }

.p0 .bar { position: absolute; width: 0%; }
.p5 .bar { position: absolute; width: 5%; }
.p10 .bar { position: absolute; width: 10%; }
.p15 .bar { position: absolute; width: 15%; }
.p20 .bar { position: absolute; width: 20%; }
.p25 .bar { position: absolute; width: 25%; }
.p30 .bar { position: absolute; width: 30%; }
.p35 .bar { position: absolute; width: 35%; }
.p40 .bar { position: absolute; width: 40%; }
.p45 .bar { position: absolute; width: 45%; }
.p50 .bar { position: absolute; width: 50%; }
.p55 .bar { position: absolute; width: 55%; }
.p60 .bar { position: absolute; width: 60%; }
.p65 .bar { position: absolute; width: 65%; }
.p70 .bar { position: absolute; width: 70%; }
.p75 .bar { position: absolute; width: 75%; }
.p80 .bar { position: absolute; width: 80%; }
.p85 .bar { position: absolute; width: 85%; }
.p90 .bar { position: absolute; width: 90%; }
.p100 .bar { position: absolute; width: 100%; }

.navbar {
	background:#56565a; margin:0;
	color: #ffffff; padding: 1px; z-index: 101;
	a { color: #ffffff; padding: 1px;}
}



.nav .dark { background:#292929; padding-left: 30px;}

.nav .dark:last-child { padding-right: 20px;}

.slider-box {
	border-radius: 4px;
  border: 1px solid #ccc;
	position: relative;
	padding: 6px 15px;
	margin: 0;
	background-color: #fff;
	&:before, &:after {content: " "; display: table; } &:after {clear: both; }
	.slider-no {
		display: inline-block;
		padding: 0 0 0 6px;
	}
	.slider-container { margin: 5px 0 0 !important;}
	.none {
		height: 20px;
		display: block;
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
	}
}

.suggestion-box {
	border: 1px solid #cecece; background-color: #fff; position: absolute; left: 0; width: 100%; margin: 0; padding: 10px; z-index: 10; border-radius: 0 0 6px 6px; border-width: 0 1px 1px 1px;
	.tag { display: block; padding: 3px; font-size: 14px;}
}

.page-heading {
	color:#56565a;padding:15px 20px;
	.page-title {font-size:4.2rem;font-weight:700;margin:0;}
	.page-title + select {margin-left:40px;cursor:pointer;}
	.btn {padding-top:9px;padding-bottom:10px;}
	.form-control {height:46px;}
}

.stats {
	margin-left: 30px;
	height: 100%;
	font-size: 0.6em;
	small {font-size:1.4em;}
	strong {font-size:1.5em;text-transform:uppercase;line-height:1;}
	>div {float: left; height: 100%; padding: 0 10px; border-right: 1px solid #ccc;
		&:last-child {border-right: none;}
		&:first-child {padding-left: 0;}
	}
	li {vertical-align:top;padding-left:10px;margin-left:40px;}
	big, .stats strong, .stats small {display:block;}
	big {font-size:2.6em;font-weight:700;line-height:1;}
	strong {}
	> span {display:inline-block;margin-right:40px;}
	.files a {margin-right:30px;}
}

.header-icon {
    cursor: pointer;

	font-size: 15px;
	position: relative;
	display: inline-block;
	font-weight: normal;

	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }

	button {
		border-radius: 6px;
		@include gradient(#f5f5f5, #f5f5f5);
		border-radius: 6px;
		box-shadow: none;
		border: none;
		width: auto;
		padding: 0 15px;
		margin-right: 10px;
	}

	button img {
    height: 40%;
    top: -1px;
	}

	img {
		top: 4px;
		margin-right: 8px;
	}

	&.file,
	&.folder { height: 45px;}

	&.folder {

		margin-right: 10px;
	}


	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }

	.icon { line-height: 0; float: left; font-size: 34px; margin: 0 5px;}
	.text { top: 7px; }
	.plus { position: absolute; width: 20px; height: 20px; display: block; font-weight: bold; top: 50%; left: 50%; transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); }
	.plus:before { content: '+'; display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); border: 2px solid #56565a; border-radius: 1000px; padding: 1px; width: 18px; height: 18px; font-weight: bold; text-align: center; line-height: 9px; font-size: 18px; }
	.fa-folder-o { font-size: 45px; position: relative; }
}

.contact-card {
	display: inline-block; float: left; border-radius: 8px;
	&.active button { color: rgb(86, 86, 90); border: 1px solid #A8A8AA;}
	&.active button strong { color: #3097D1; }
	button strong{ color: #a2a2a2; }
	button {color: #a2a2a2; width: 100%;
	@include gradient(#eaeaea, #ffffff);
	border-radius: 4px; border: 1px solid #a2a2a2; padding: 5px 12px;}
}

.panel-description {
	padding-left: 70px;
	margin: 0;
}

.sign-notes-controls {
	margin-bottom: 10px;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	div {float: left; display: inline-block; width: 160px;}
}

#opportunity-requirements {
	padding-left: 13px;
	padding-right: 13px;

    input {
        padding-left: 25px;
    }

    input[disabled] {
        &:checked{
            box-shadow: 1px 1px 3px red;
            display: none;

            &+label {

                &:before {
                    content: " ";
                    width: 15px;
                    height: 15px;
                    border-radius: 50px;
                    background-color: #FEC442;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }


}

#summary-section {
	.summary-total { margin-bottom: 29px; }
	.summary-controls {}
	.summary-controls select,
	.summary-controls .btn { display: inline-block; float: right; cursor:pointer;}
	.summary-controls .btn { margin-left: 15px;cursor:pointer;}
}

div.darkgreen {
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }

	background-color: #1A9E5C;

	div {
		&:last-child {padding-right: 0; }
		&.light-green { background-color:#30D787; }
		&.white { background-color: #fff; }
	}

	> div {
		padding: 0 15px;
		width: 9.09% !important;
		color: #fff;
		font-size: 11px;
		float: left;
		display: inline-block;
		height: 60px;

		> div {
			top: 50%;
			transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
		}

	}
}

.collapse-section, .collapsed-form {
	.panel {border-radius:0; border: 1px solid #fff;border-color:#d2d2d2;}
    .panel-group-header { padding: 15px 0; }
	.panel-heading {padding:24px 20px;position:relative;}
	.panel-heading+.panel-collapse>.panel-body {border-top:0;padding-top:0;}
    .panel-title .svc { font-size: 15px;}
	.panel-title a {text-decoration:none; display: block; font-size: 16px;}
	.panel-title a:before {font-family:'FontAwesome';margin-right:5px;font-size:2.2rem;vertical-align:middle;}
	.panel-title a.collapsed:before {content:'\f196';}
	.panel-title a:not(.collapsed):before {content:'\f147';}
	.panel-title-control { position: absolute; right: 20px; z-index: 10;}
	.panel-title-control .price {position: relative; width: auto; right: 0;}
	.panel-title-control .duplicate {cursor: pointer; position: relative; width: auto; right: 0; color: #3097D1; font-size: 16px; font-weight: normal; padding-right: 9px; border-right: 1px solid #3097D1; margin-right: 32px;}
	> .panel-group > .panel > .panel-heading > .panel-title {font-size:1.8rem;}
	.sub-section .panel {border:0;box-shadow:none;}
	.sub-section .panel-body { padding: 0 40px; }
	.sub-section .panel-heading {background:inherit;padding:20px 24px;}
	.sub-section .panel:nth-child(odd) {background:#f7f7f7;}
	.qty {width:40px;text-align:center;display:inline-block;}
	.price {position:absolute;right:20px;width:100px;}
	.table [type=text] {max-width:280px;}
	.table thead tr {background:#e3e3e3;color:#59595d;}
	.table tbody {display:none;}
	.table.active tbody {display:table-row-group;}
	.table thead tr th {padding-top:6px;padding-bottom:6px;border-bottom:1px solid #adafb0;}
	.table tbody tr td {border-color:#b9bbbc;padding-top:5px;padding-bottom:5px;}
	.table thead th:first-child {padding-left: 15px;} .collapse-section .table tbody td:first-child {padding-left: 0;}
	.table thead th:last-child, .collapse-section .table tbody td:last-child {padding-right:15px;}
	.fa-times {color:#e00000;}
}

.stats-box {
	float: right;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

.stats-box-inner {
	height: 96px;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

.stat {
	float: left; min-width: 160px; border-left: 1px solid #9d9d9d; height: 100%; padding: 25px;
	&:first-child {border-left: none;}
	&.ticker {padding: 20px 25px; }
	.button-box {padding: 0 45px 0 20px; }
	.button-box .btn {font-weight: bold; width: 203px; color:#56565a; font-size: 17px; border-radius: 4px; background-color: #FFC52F; border: none; }
}

.stat-bit {
	font-size: 32px;

	.stat-label {font-size: 16px; text-align: left; line-height: 0.9; margin-bottom: 2px; }
	.stat-ratio {text-align: center; }
	.stat-price {color: #079f59; font-weight: normal; font-size: 23px; text-align: left; }
}

.stat-bits {
	&.stat-bit {float: left; padding-left: 28px; }
	&.stat-bit:first-child {padding-left: 0; }
}

.stat-ratio {
	+ .stat-diff, &.half {float: left; }
	.numerator:after {content : ''; border-right: 2px solid #9d9d9d; display: block; width: 0px; right: -3px; position: absolute; top: 5px; bottom: 5px; }
	.numerator {font-size: 32px; font-weight: bold; color: #e00000; padding: 0 6px; position: relative; }
	.denominator {position: relative; top: -2px; font-weight: 300; font-size: 0.85em; }
}

.stat-diff {
	font-size: 11px; position: relative; left: 6px;
	&.down {color: #e00000; }
	&.up {color: #079f59; }
	.difficon {line-height: 0.3; float: left; font-size: 32px; top: -7px; }
 	.diff-caption {position: absolute; top: 6px; left: -9px; width: 60px; }
}

.profile {
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	clear:both;
}

#signs-container, .signs-container {
	padding: 7px 0;

	.sign-row {
		padding: 8px 15px;

    &:nth-of-type(even) {
        background-color: #fff;
    }
    &:nth-of-type(odd) {
        background-color: #f7f7f7;
    }
	}


}

.sign-details {
	input:first-child { margin-bottom: 10px;}
}

.quantities {
	margin-left: 15px;
	div {
		float: left;
		margin-right: 15px;
		text-align: center;
		input {
            text-align: center;
            width: 60px;
            margin: 0 auto;
        }
	}
}

.sort-handle {
	padding:  4px 7px;
	border: 1px solid #aaa;
	border-radius: 5px;
	position: relative;
	width: 30px;
	height: 30px;
	color:#bbb;
	position: relative;
	top: 2px;
}

.container-fluid.profile {
	padding-top: 15px;
}

.task-detail-header {
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

#contact-list {

	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

.rows-header {
	h4 {font-size: 16px; font-weight: 600; }
	.text-right {font-size: 14px;}
}

.company-row {

	a { color: #56565a; }
}

.requirement-rows {
	border: 1px solid #b1b1b1; border-width: 1px 0 0 0; margin: 25px 0; border-left: 1px solid #b1b1b1;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	> div { padding-top:10px; padding-bottom: 10px; border-bottom: 1px solid #b1b1b1; background-color: #fff; border-right: 1px solid #b1b1b1;}
}

.form-control[name$="[weighted_total]"]{

	background-color: transparent; border: none; box-shadow: none; font-weight: bold; font-size: 18px; cursor: default;
}

.contact-company {
	margin: 25px 0 0 0;

	td { font-size: 14px; }
}

.contact-row {

	i.fa {border-radius: 3px; line-height: 15px; text-align: center; background-color: #2c3863; width: 16px; height: 16px; margin-right: 10px; margin-top: 9px;}
	i.fa:before {font-size: 13px; color: #fff; }
	.contact {font-size: 13px; margin-bottom: 25px;line-height: 1.6;}
}

/* login */
#login {
	margin-top:100px;color:#d5d5d5;
	.panel {border:0;max-width:500px;margin:auto;}
	.panel-heading {background:#f7f7f7;}
	.panel-body {background:#56565a;padding-top:40px;padding-bottom:40px;}
	a {color:#d5d5d5;}
	img {max-width:360px;margin:10px auto;}
	small {font-size:12px;}
	[type=submit] {max-width:200px;margin-top:15px;}
	.links > a {color: #636b6f; padding: 0 25px; font-size: 12px; font-weight: 600; letter-spacing: .1rem; text-decoration: none; text-transform: uppercase;}
}

#masthead {
	background:#f5f5f5; height: auto;z-index: 100;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	img {max-width:260px;margin-top:9px;margin-bottom:9px;}
	.navarea {float:right;text-align:right;padding-right:88px;}
	.contact_name {font-size: 22px; font-weight: 500; color: rgb(86, 86, 90);padding-right: 30px;}
	.opportunity_name {font-size: 22px; font-weight: 500; color: rgb(86, 86, 90);padding-right: 35px;}
	//.opportunity_name:after {content: ''; display: block; width: 3px; background-color: rgb(86, 86, 90); height: 70%; top: 50%; position: absolute; right: 0; transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); }
	.opportunity_name .edit-input {
		display: block;
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    color: #45A2FA;
    font-family: 'fontAwesome';
    font-size: 14px;
    padding: 5px;
	}
	.opportunity_name {
		input[disabled] {
			position: relative;
			+ .edit-input span:before {
	    	content: "\f040";
			}
		}

		input + .edit-input .fa:before {
    	content: "\f0c7";
    	//font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
		}
	}

	#main-bar {

		.container-fluid,
		.row,
		div[class^="col-"],
		{height: 100%;}

	}
}

.estipro.detail .opportunity_name {
	.left-bar-segments .segment {
		&:first-child {border-left: none !important;}
		line-height: 30px;
		border-left: 1px solid #ccc !important;
	}
}

.add-company-search-results .search-results {
	max-width: 290px;
}

#search-page {

	display: none;
}

.search-page-content {
	.search-results {
		box-shadow: none;
		height: auto;
		overflow-y: visible;
		.search-result, .sign-search-result {
			padding-top: 10px;
			padding-bottom: 10px;


		}
	}
}

.search-results {
	z-index: 100;
	position: absolute;
	width: 500px;
	left: 0;
	background-color: #fff;
    box-shadow: 2px 2px 17px -1px #202020;

    #replace-ml-sign-autocomplete-results .search-results {
        max-height: 400px;
        overflow-y: scroll;
        top: 35px;
    }

	.search-results {
		width: 100%;
		overflow-y: scroll;
		height: 600px;
	}

	.search-group {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
	}

	.search-header {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		background-color: #a5a5a5;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 15px;
    font-size: 12px;
	}

	.search-result, .sign-search-result {
		padding-top:6px;
		padding-bottom:6px;
		border-bottom: 1px solid #a5a5a5;
		text-overflow: ellipsis;
		overflow: hidden;
		&:last-child {
			border-bottom: none;
		}
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		&.selected {background:#45a2fa;
			.blue-text {color:#fff;}
		}
		span.small {color: #c1c1c1;}
	}
}

#contact-container {
	padding-top: 15px;
	button:active,
	button:focus {outline-width: 0; outline: none; }
}

#leftbar {

  float: left;
  display: inline-block;
  margin-left: 18px;
  height: 70px;

	> .inner {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		top: 50%;
		transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
	.segment {border: none; }
	.small-name {line-height: 1;}
	.big-number { font-size: 28px; font-weight: bold; padding-right: 8px; }
	.blue-meta { display: inline-block; padding: 0 20px; border-left: 1px solid #c4c4c4; line-height: 1.0; position: relative; top: -4px; }
	.small-text {font-size: 12px; margin-top: 10px; line-height: 1; }
	.small-text.dd {padding-right: 20px;}
	.small-text.dd:after {content: ''; width: 0; height: 0; border-left: 4px solid transparent; border-right: 4px solid transparent; border-top: 5px solid #3097D1; font-size: 0; line-height: 0; position: absolute; right: 6px; top: 50%; transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); }
}

#main-bar {
	float:right;
	display:inline-block;
	//margin-right: 25px;
	.btn {margin-left: 10px;}
}

.design-availability{
    margin-right: 150px;

    .segment{
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

.segment {
	display: inline-block;
	float: left;
	padding-left: 15px;
	padding-right: 15px;
	border-left: 1px solid #a1a1a1;
	height: 100%;
	font-size: 14px;

	&.slim-padding {
		&:first-child {
			padding-left: 0 !important;
		}
		&:last-child {
			padding-right: 0 !important;
		}
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	i.fa {top: 0;}

	> .inner {
		top: 50%;
		transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
	&:first-child {padding-left: 0 !important; border-left: none;}
	&:last-child {padding-right: 0 !important;}
	&.padding { padding: 0 25px;}
	&.no-border{ border: none;}
	&.rating {
		border: none;
		.fraction {
			color: #ffc52f;
			font-size: 21px;
		}
		.fa-star {font-size: 22px; color: #ccc;
			&.active {color: #ffc52f}
		}
	}
	.green-circle {
		margin-right: 10px;
		//height: 35px;
		width: auto;
		font-size: 1.7em;
		text-align: center;
		line-height: 36px;
		border-radius: 100px;
		background-color: #15b165;
		color: #fff;
        padding: 5px;
	}

	.medium {
		font-size: 1.3em;
		font-weight: bold;
		clear: both;
		display: block;
	}

	.green {
		color: #15b165;
	}
}

.rating-popout {
	display: none;
	position: absolute;
	z-index: 10;
	left: 0;
	border-radius: 4px;
	overflow: hidden;
  padding: 10px;

  h4 {margin-bottom: 4px;}
  hr {margin: 6px 0}

	.rating-popout-inner {
		margin-top: 5px;
		background-color: #fff;
		border: 1px solid #ccc;
		box-shadow: 2px 2px 17px -2px #aeaeae;
		padding: 20px;
		width: 230px;

		&:before {
			content: '';
			 width: 0;
		  height: 0;
		  border-left: 5px solid transparent;
		  border-right: 5px solid transparent;
		  border-bottom: 5px solid white;
		  position: absolute;
		  top: -5px;
		  left: 20px;
		}
	}

	.rating-box {
		background-color: #f5f5f5;
		padding: 5px 8px;
		border: 1px solid #a5a5a5;
		border-radius: 5px;
		margin-bottom: 6px;

		i.fa {margin-right: 5px;
			&:last-child {margin: 0}}
	}



	&:hover {
		display: block;
	}
}

#main-menu {
	display:inline-block; float:right;
	/*top: 1px; */
	a {text-decoration:none;}
	a.minor {display:none;position:absolute;margin:0;background:#fff;white-space:nowrap;border:0;border-radius:0 0 8px 8px;padding:8px 33px;}
	a:hover {border-color:var(--sunglow);}
	> li {margin:0;}
	> li.current > a { font-weight:700; }
	> li.backlink:after { content: ''; border-right: 1px solid #c4c4c4; height: 20px; top: 50%; right: 0; display: block; position: absolute; transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); }
	> li.backlink > a { font-size: 16px; color: #3097D1;}
	> li:hover a.minor {display:block;}
}

/* Message Box */
body.messaging #message-box {

	right:0;position:fixed;
}

#message-box {

	border-left:1px solid #d4d4d4;background:#f7f7f7;position:absolute;z-index:9999;height:100vh;right:-415px;top:0;width:415px; -webkit-transition: right 0.5s ease-in-out; -moz-transition: right 0.5s ease-in-out; -ms-transition: right 0.5s ease-in-out; -o-transition: right 0.5s ease-in-out; transition: right 0.5s ease-in-out;

	&.conversation li {border-bottom:1px solid #d4d4d4;}
	/*&.conversation #message-body {padding-bottom:183px;}*/
	&.conversation #message-footer {height:183px;}
	&.conversation .start, #message-box.conversation #conversation-box {display:block;}
	&.conversation .convo-deatil {display:none;}
	&.detail .convo {display:none;}

	#message-header {position:relative;z-index:1;background:#ffffff;border-bottom:1px solid #d4d4d4;height:64px;overflow:hidden;}
	#message-header strong {font-size:1.8rem;font-weight:700;}
	#message-header .badge {margin-left:10px;margin-top:-4px;padding:6px 10px;border-radius:20px;background:none;color:#d2d2d2;border:1px solid #d2d2d2;}
	#message-header .badge.active {background:var(--sunglow);color:#ffffff;border-color:var(--sunglow);}
	#message-header .entry {padding:19px 15px;}
	#message-header.conversation {text-align:right;}
	#message-header.conversation strong {float:none;}

	.convo-detail button {background:#000000;border:0;height:64px;color:var(--sunglow);width:115px;margin-right:15px;}
	.convo-detail button .fa {font-size:2.4rem;vertical-align:middle;margin-top:-3px;}

	.entry {padding:13px 15px;}
	.msg, #message-box .msg p {line-height:1.4;}
	.avatar {position:absolute;margin-left:-60px;}
	.avatar img {width:54px;height:54px;border-radius:54px;}
	.from {font-weight:700;font-size:1.4rem;margin-bottom:4px; width: 95%; }
	li {position:relative;padding-left:60px;}
    li:nth-child(even) {
        background-color: white;
    }

	#message-body {height: 100vh; position: absolute; top: 0; padding-top: 64px; /*padding-bottom: 146px;*/ width: 100%; }
	#message-body .message-content {position:relative;height:100%;overflow-y:auto;padding-bottom:0px;}
	.start {display:none;font-size:1.4rem;padding:4px 10px;font-weight:700; position: fixed; bottom: 180px; width: 415px; background:#f7f7f7; border-top: 1px solid #d4d4d4; }

	#message-footer {height:146px;position: absolute; width: 100%; bottom: 0;}
	#message-footer .message-form {background:#ffffff;border-top:1px solid #d4d4d4;}
	#message-footer .icons {float:left;}
	#message-footer .icons button {float:left;width:45px;height:44px;background:none;border:0;border-right:1px solid #d4d4d4;}
	#message-footer .form-control {border-radius:0;border:0;box-shadow:none;}
	#message-footer .form {border-bottom:1px solid #d4d4d4;}
	#message-footer textarea {height:100px;}
	#message-footer .actions {height:44px;}
	#conversation-box {display:none;}
	#send-message {padding: 3px 30px; margin: 5px 15px;}
}

#message-button {
	background:var(--sunglow);color:#ffffff;position: absolute; left:-70px; top: 50px; height: 64px; width: 70px; border-radius: 4px 0 0 4px;
	.fa {font-size: 3.4rem; margin: 14px 18px;}
	.badge {position: absolute; right: 0; margin: 11px; border-radius: 20px; /*width: 21px;*/ height: 21px; line-height: 16px; background: #c50700; z-index: 100;}
}

#detail-overview {

	margin-bottom: 10px;
	background-color: #f5f5f5;
	padding: 8px 15px;
	border-radius: 0 0 6px 6px;

	.segment { border: none; }

	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	.title {padding:10px 0;margin-top:15px;}
	.row {display:flex;}
	.divider {padding:0;}
	.divider:before {content:''; position:absolute; height:100%; border-left:1px solid #d4d4d4; top:0; }
	.stats {padding:9px 20px;display:inline-block;font-size:0.9em;}
	.divider .stats {display:block;}
	.divider .stats + .stats {border-top:1px solid #d4d4d4;}

	li.flex .stats {padding-left:0;padding-right:0;}
}

#overview-header .stats li {

	border-left:1px solid #c7c7c7;min-height:70px;
}

#detail-controls {
	label { font-weight: normal; }
	.btn { font-weight: normal; }
	.detail-controls-right div[class^="col-"]{ text-align: right; }
}

#opportunity-notes {}

#masthead .opportunity-dropdowns {
	padding: 3px 20px;
	.status-bar {color: #45A2FA;}
}

#main {
	z-index: 99;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
}

#form-requirements {
	.row-control {
		padding: 10px 0;
		&.left { float: left;}
	}
	table tr td:first-child {padding-left: 15px;}
	table tr td:last-child {padding-right: 1px;}
	table tr td { padding: 10px 0; vertical-align: middle; border-bottom: 1px solid #ccc; }
	table tr:nth-of-type(odd) {background-color: #fff}
}

#radioshack {
	.left, .none {
		border-bottom: 1px solid #ccc;
		padding: 10px 0;
	}
}

#scope-of-work {
	.panel-header {
		position: relative;
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
	}
}

#scope-tabs .tab {

	width: 210px;
}

div.tab-panels {
	height: 100%;
	&:before,
	&:after {content: " "; display: table; }
	&:after {clear: both; }
	> div { display: none; }

	textarea {
		height: 130px;
		padding-bottom: 35px;
		margin-top: 7px;
		resize: none;
	}

	textarea + button {
		margin-top: -30px;
		float: right;
	}

	.panel {
		height: 100%;
	}
}

.right-panel {
	border-left: 1px solid #ccc;
	.tab-group {margin: 0}
	.panel {
		padding-top: 15px;
		background-color: #f5f5f5;
	}
}

div.tab-group {

	display: block;

	&.vertical-tabs {

		.tabs {
			text-align:center;
			white-space:nowrap;
			width: 40px;
		}

		.tab {
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			clear: both;
			float: left;
			padding: 0;
			margin-bottom: 3px;
			font-size: 11px;
		}
	}

	&.theme-green {
		.tab {
			background-color: #ccc;
			color :#fff;

			&.active {
				background-color: #1A9E5C;
				color: #fff;
			}
		}
	}

	&.theme-blue {
		height: 38px;
		background-color:#4696FA;
		overflow: hidden;
		float: none;

		.tab {
	    display: inline-block;
	    width: auto !important;
	    height: 25px;
	    margin-top: 13px;
	    padding: 5px 9px !important;
	    background-color: #BFBFBF;
	    color: #fff;
	    border-radius: 3px 3px 0 0;
	    margin-left: 6px;
	    font-size: 0.8em;


			&:first-child {
				margin-left:0;
			}


			&.active {
				font-weight: bold;
				color:#45A2FA;
				background-color: #f5f5f5;
			}
		}
	}

	&:before,
	&:after { content: " "; display: table; }
	&:after { clear: both; }

	&.one-tab,
	&.two-tab,
	&.three-tab,
	&.four-tab,
	&.five-tab {
		button {
			width: 100%;
			min-width: 100%;
			font-size: 13px;
		}
	}

	&.one-tab {
		.tab {
			width: 100%;
		}
	}

	&.two-tab {
		.tab {
			width: 50%;
		}
	}

	&.three-tab {
		.tab {
			width: 33.3333%;
		}
	}

	&.four-tab {
		.tab {
			width: 25%;
		}
	}

	&.five-tab {
		.tab {
			width: 20%;
		}
	}

	.tab {
		padding-right: 10px;
		float: left;
		display: inline-block;


		&:last-child {
			padding-right: 0;
		}
	}

	button {
		font-size: 15px;
		outline: none;
		border: none;
		border-bottom: 4px solid #a5a5a5;
		margin-right: 10px;
		border-radius: 0;
		background-color: transparent;
		text-align: center;
		min-width: 210px;
		color: #56565a;
		box-shadow: none;

		&.active, &:focus {
			background-color: #ffc52f !important;
			font-weight: bold;
			outline: none !important;
		}
	}
}

.note-rows {

	.note-row { font-size: 13pxem; border-top: 1px solid #ccc; padding-top: 15px; margin-top: 15px;}
}

.textarea-container {

	margin-bottom: 30px;
}

.sign-type-rows {
	margin-top: 10px;

	.sign-type-row {
		padding: 15px 25px;

		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
	}
	.sign-type-row:nth-of-type(odd) {
		background-color: #f5f5f5;
	}
}

.sign-type-label {

	padding: 6px 10px; font-size: 15px;
}

.bio {
	font-size: 13px;
	margin-bottom: 30px;
	.line {
		margin-bottom: 9px;
	}

	.image-control {
		font-size: 13px;
		 color: #3097D1;
		 a { color: #3097D1;}
		.left { text-align: left;}
		.right { text-align: right;}
	}
}

body.estipro.masterlist {
	min-height: 100%;

	&:before {
		content: '';
		display: block;
		left: 0;
		top: 0;
		background-color: #f5f5f5;
		width: 260px;
		position: absolute;
		height: 100%;
	}

	#dashboard {
		min-height: 100%;
	}

	td.match, td.match *{
		font-weight: bolder;
	}

}

#dashboard {
	min-height: 100%;
}

.crm.company #dashboard {
	height: 100%;
}

.crm.opportunity.files  {
	table tbody tr.active {
		background-color: #d3f5ff;
	}
}

.crm.tasks #main .gold-bar {
	button {
		padding: 8px 15px;
		font-weight: bold;
		.fa {
			font-weight: normal;
			border: 1px solid #202020;
	    border-radius: 99px;
	    padding: 5px;
		}
	}
}

.gold-bar.opportunity-details {
	.none {
		padding-left: 40px;
	}
}

.gold-bar {
	width: 100%;
	a {
		color: #337ab7;
	}
	.segment {
		//font-weight: bold;
		padding-left: 20px;
		padding-right: 20px;
		border-left: none;
		&:first-child {
			padding-left: 0 !important;
		}
		&:last-child {
			padding-right: 0 !important;
		}
	}
}

#main {
	height: 100%;
	clear: both;

	.container-fluid {
		height: 100%;

	}
}
.left.sidebar:before {
    content: '';
    width: 260px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #f5f5f5;
}
.sidebar-menu {
	padding-top: 30px !important;
	padding-right: 0 !important;
}

.sidebar {
	width: 260px;
	color: #fff;
	height: 100%;
	background-color: #f5f5f5;

	&.left + .none {
		display: block;
	}

	a {
		color: #45A2FA;
		padding: 6px 6px 6px 0;
		display: block;
	}

	.children {
		a {
			padding: 6px 6px 6px 12px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		&.top_level {
			font-weight: 500;
			margin-bottom: 15px;
			color: #797979;
			a {color: #797979;}
			&.active {
				font-weight: 500;
				color: #45A2FA;
				> a {color: #45A2FA; }
				ul.children {
				display: block;
				li.active {
					a {color: #45A2FA; }
				}
				}
			}
		}

		&.active {
			font-weight: 500;
			color: #45A2FA;
		}
	}

	ul.children {
		padding-bottom: 15px;
		display: none;
		li {
			font-weight: normal;
			margin-top: 10px;
			&.active {
				font-weight: 500;
				background-color: #fff;
				border-radius: 6px 0 0 6px;
			}
		}
	}
}

.breadcrumb {
	margin-bottom: 0;
	background-color: transparent;
	font-weight: bold;
	a {font-weight: bold; color:#198DFC;

		&:after {
			content: "/";
			position: relative;
			top: 0;
			right: -10px;
			margin-right: 15px;
		}


		&:last-child {
			&:after {
				content: '';
			}
		}
	}
}

#search-box {
	  float: left;
	  display: inline-block;
	  z-index: 1;
	  vertical-align: middle;
	  height: 70px;

		&.active .form-control { width: 240px; left: 50px;}
		.form-control {height:46px;border-right:0;box-shadow:none; border: none; }
		.form-control:focus,
		.form-control:active {}
		.fa {color:#fff;font-size:2.4rem;position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); }
		.input-group-addon {background:#ffc52f;}
		.input-group-addon {background: #ffc52f; height: 46px; width: 46px; border: none; border-radius: 0; }
		.search-button { display: inline-block; z-index: 2;}
		.form-control { width: 0; position: absolute; left: 22px; top: 0;  transition: width 0.5s, left 0.5s; z-index: 1; }
}
.input-group-addon.transparent {background:transparent; button {background:transparent;} input[type="button"]{background:transparent;}}
.contact-row {

	.contact {

		.col-sm-3 {padding-bottom: 4px; padding-top: 4px;}

		padding-top: 10px;
		padding-bottom: 10px;

		select { cursor:pointer; margin: 8px 0;}

	}
}

#search-page {

	height: 100%;
	position: absolute;
	right: 0;

	.search-results { width: 100% !important;}
	.search-page-inner {
		height: 100%;
	}

	.search-page-header {
		input { border-radius: 0; }
		.input-group-addon { border-radius: 0;}
		padding: 10px;
		background-color: #ccc
	}

	.search-page-content {
		height: 100%;
		background-color: #2e2f4a;
	}
}

.moneyMagic {
	&:before {
		content : "$";
		position: absolute;
		left: 10px;
		font-size: 14px;
		top: 50%;
		@include middleAlign;
		color: #555;
		z-index: 1;
	}
	input {
		margin-bottom: 0 !important;
		padding-left: 20px;
	}
}

.task-detail-description {
	padding-top: 15px;
	padding-bottom: 15px;
}

.dz-clickable {
    border: 2px dashed #1a8efc;
    height: 300px;
    text-align: center;

    > div {
    	position: relative;
    	top: 50%;
    	transform: translateY(-50%);
    	-o-transform: translateY(-50%);
    	-ms-transform: translateY(-50%);
    	-moz-transform: translateY(-50%);
    	-webkit-transform: translateY(-50%);

    	font-size: 18px;
    }
}

.dz-default.dz-message {
	top: 50%;
	@include middleAlign;
}

.modal-header {
	img[src$="cancel.svg"]{
		position: relative;
		z-index: 2;
	}
}

#MoveFileModal{
	.minus-button {
		color: #45A2FA; top: -1px;
		border: 1px solid #45A2FA;
    border-radius: 2px;
    display: inline-block;
    margin-left: -25px;
    font-size: 17px;
    position: absolute;
    top: 50%;
    @include middleAlign;
	}

	button {
		opacity: 0.5;
		cursor: not-allowed;
		&.active {
			opacity: 1.0;
			cursor: pointer;
		}
	}

	.tree-folder {
		padding: 3px 5px;
		&.active {
			background-color: #d3f5ff;
		}
		i.fa {color: #45A2FA;}
	}

	.modal-body {
		background-color: #f5f5f5;
		padding: 35px 35px 0 35px;

		.modal-inner {
			background-color: #fff;
	    padding: 5px 15px 25px;
	    height: 250px;
	    overflow: scroll;

			> ul {
				margin-left: 0;
				padding-left: 0
			}
		}
	}

	.modal-footer {
		padding-left: 35px;
		padding-right: 35px;
		border-top: none;
		background-color: #f5f5f5;
	}
}

.admin.masterlist {
	#dashboard {
		height:100%;
		#main {
			min-height: 100%;
		}
	}
}

.cost-code-group {
	> div {display: inline-block; float: left; height: 34px; width: auto; padding: 6px 10px;}
	.dark { background-color: #292929; color: #fff;}
	.middle { padding: 0; width: 90px; input {border-radius: 0; box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;}}
	.white { background-color: #fff;}
}

.left-bar-segments {

    height: 30px;

	.segment {

    h1, h2, h3, h4, h5, h6 { margin-bottom: 0; }

    height: 100%;
    font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Medium', Arial, sans-serif;
		font-weight: normal;
		span {font-weight: normal;}
	}
}

.striped-list {
	ul { margin: 0; padding: 0; list-style: none;}

	.striped-list-heading {
		margin-top: 10px;
		border-bottom: 1px solid #ccc;
		font-weight: 500;
		h5 {font-size: 15px;}
	}

	.striped-list-body {
		margin-bottom: 30px;
		li { border-bottom: 1px solid #ccc; padding: 8px 0;}
	}
}

#schedule-summary {
    margin-top: 15px;
}

#schedule-container {
    margin-top: 15px;

    .schedule-footer {
        float: left;
        width: 100%;
        margin-top: 10px;
        border-top: 2px solid #9a9a9a;
        padding-top: 10px;
			  font-size:12px;
			  padding-right:40px;
        .gold-button {
            padding-top: 3px;
            padding-bottom: 3px;
            margin-top: 6px;
        }
    }
}

.schedule-calendars {
    padding-top: 30px;
    //float: left;
    width: 100%;

    .row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .calendar {
        //width: 33.33333%;
        //float: left;
        //display: inline-block;
        padding-left:5px;
        padding-right:5px;

        span { font-size: 14px; }

        .heading {
            color: #fff;
            font-weight: normal;
            padding: 6px 12px;
        }
        .content {
            background-color: #fff;
            border: 1px solid #9a9a9a;
            border-width: 0px 1px 1px;
            padding: 6px 12px;
        }

        &.approved .heading{
            background-color: #1A9E5C;
        }
        &.forecast .heading {
            background-color: #DD0A16;
        }
        &.fab-release .heading {
            background-color: #56565A;
        }
    }
}

.master-list-drawer {
    position: absolute;
    bottom: 50px;
    left: 6px;
    z-index: 10;
    width: 600px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0px 0px 41px #333;

    .inner-drawer {
         height: 100%;
    }

    .side,.scroll {
        float: left;
        display: inline-block;
        @include box-sizing;
        height: 100%;
    }
    .side {width : 30%; border-right: 1px solid #ccc; }
    .scroll {width: 70%; padding: 0 20px; overflow-y: scroll; padding-bottom: 36px;}
    .scroll-control {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 20px;
        span {
            color: #3097D1;

        }
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding: 5px 10px;
        &.active {background-color: #82ffcf}
        input[type="number"] {
            width: 30px;
        }
        span.name {
            display: inline-block;
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        span.cost {
            float: right;
            width: 80px;
            display:inline-block;
            text-align: right;
        }
    }
}

/*******new css 12-07-2017*****/
#schedule-container ul{padding:0px; margin:0px;}
#schedule-container ul li {
	list-style:none;
	padding-bottom: 5px;
	padding-top: 5px;
	border-bottom: 1px solid #CACBCC;
	font-size:13px;
	color:#808080;
	overflow:hidden;
}
.m-drag{
	cursor: move;
}
.list{
	min-height:20px;
	border-bottom:1px solid #CACBCC;
}
.m-drag:hover{
	cursor: move;
	background-color: #CACACA
}
#schedule-container ul li>span {
	padding-top: 5px;
	overflow:hidden;
}
#schedule-container ul li.bdr-none {
	border-bottom:none;
}
#schedule-container span.gold{
	background-color: #FFC52F;
	color: #56565a;
	display: block;
	padding-bottom: 5px;
	padding-top: 5px;
	font-weight:500;
}
#schedule-container span.black{
	background-color: #56565a;
	color: #ffffff;
	display: block;
	padding-bottom: 2px;
	padding-top: 5px;
	font-weight:500;
}
#schedule-container span.black span:first-child {width:30%;display:inline-block;}
.item{
	padding-left: 10px;
}
.wks{
	width: 60px;
	text-align: left;
    //float:right !important;
}
.schedule-form {
	display:inline-block;
	width: 60%;
	height: 22px;
	padding: 4px 12px;
	font-size:12px;
}
.schedule-form-small{
	display:inherit;
	width: 30px;
	height: 22px;
	padding: 4px 2px;
	font-size:12px;
}
/*******new css 12-07-2017*****/
/*****css for users listing*****/
.users table thead th:first-child {
	padding-left: 15px;
}
.person-rows .form-label {
  font-size: 12px;
}
.person-rows .form-row {
  font-size: 12px;
}
.person-rows .form-row .form-group {
  margin-bottom: 0;
}
.modal{
	top:73px;
}
.modal-backdrop.in{
	opacity:0.2;
}
.close{
	opacity:1;
}
.close:hover{
	opacity:1;
}
/*.fa-plus-square-o {
	font-size: 20px;
	color: #202020;
}
.fa-minus-square-o {
	font-size: 20px;
	color: #202020;
}*/
.circle {
	border-radius: 99px;
	opacity: 0.5;
	background-color: #666;
	height: 20px;
	width: 20px;
	display: inline-block;
	transform: translateY(5px);
	-o-transform: translateY(5px);
	-ms-transform: translateY(5px);
	-webkit-transform: translateY(5px);
	-moz-transform: translateY(5px);
	margin : 0 5px;
}

.has-tooltip {
	.tooltip-caret {
		display: inline-block;
		margin-left: 8px;
	}
		.tooltip-menu {
			z-index: 10;
		}
	.tooltip-caret:hover {
		position: relative;
		.tooltip-menu {
			z-index: 10;
			display: block
		}
	}
}

.tooltip-menu {
		display: none;
    position: absolute;
    top: 10px;
    left: -22px;
		padding-top: 10px;

	.tip {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 7px 10px 7px;
		border-color: transparent transparent #ffffff transparent;
		position: absolute;
		top: 0px;
		left: 20px;
		z-index: 2;
	}
	.tooltip-menu-inner {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 11px 0px #666;
	}

	ul {
		list-style: none;
    margin: 0;
    padding: 0;

    li {
    	border-bottom: 1px solid #ccc;
    	a {
    		padding: 4px 12px;
    		display: block;
    	}
    }
	}
}


#messages-container {
	&.style1 {

		.message {
			padding-left: 15px;
			border-bottom: none;
		}
	}

	.message {
		border-bottom: 1px solid #ccc;
		padding-top: 15px;
		padding-bottom: 20px;

		&.unread {
			.message-title {
				font-weight: bold;
				color: #2091FC;
			}
		}

        .message-icon{
            width: 60px;
            margin: 0 auto;
            text-align: center;
            padding: 5px;
            float:left;
        }

        .message-body{
            margin-left: 60px;
        }

		.message-title {
			color: #56565a;
			padding-bottom: 10px;
		}

        &.note{
            background-color: lightyellow;

            .message-meta {
                .small {
                    color: lightgray;
                }
            }
        }

        &.reply{
            padding-left: 50px;
            border-bottom: none;
            border-top: 1px solid #ccc;
            padding-bottom: 0px;

            .message-body{
                padding-bottom: 10px;
            }
        }
	}
}

#messages-container > .reply { display: none; }
//#messages-container .reply .reply { padding-left: 40px;}

#tracking-table {
    tbody {
        height: 230px;
        overflow-y: auto;
        width: 100%;
    }
    th {
        padding: 2.5px 5px;
        text-align: left;
        //position: fixed;

        .sortable-column {
            color:#fff;
            cursor: pointer;
        }
    }
    td {
        padding: 0px 5px;
        vertical-align: middle;
        text-align: left;

        &:last-child {
            // text-align: center;
        }

        .status-bar{
            margin-left: 0px;
        }
    }
}

.form-group {
	input, textarea {
		+ .popupulator {position:absolute;bottom:3px;left:6px;}
	}
}

/*****css for users listing*****/
@media (min-width: 992px){

	.tab-box {
		position: absolute;
	  left: -40px;
	  width: 40px;
	  height: 40px;
	  text-align: center;
	  line-height: 40px;
	  top: 0;
	}

	.profile {

		padding-top: 15px;
	}

	.gold-bar .bar-label {
		top: 50%;
		z-index: -1;
		@include middleAlign;
	}

	.gold-bar .middle-align .bar-label {
		transform: translateY(0);
		-o-transform: translateY(0);
		-ms-transform: translateY(0);
		-moz-transform: translateY(0);
		-webkit-transform: translateY(0);
	}

	body.crm.tasks {
		&:before {
	   	content: '';
	    width: 33.3333%;
	    background-color: #f5f5f5;
	    min-height: 100%;
	    position: absolute;
	    right: 0;
		}
	}

	.profile-detail-pane { margin-left: 15px;}

	.container-fluid.profile {

		&:before {
		    content: '';
		    background-color: #ccc;
		    display: block;
		    position: absolute;
		    top: 0;
		    height: 100%;
		    width: 1px;
		    left: 33.333%;
		    margin: 0px 19px;
		}
	}

	.crm.tasks .profile {
		&:before {
			display: none;
		}
	}

	.crm.tasks .task-detail-header {

		padding-left: 55px;
	}

	.crm.tasks .widget-separator {

		padding-left: 40px;
	}

	.crm.tasks .widget-notes {

		padding-left: 40px;
	}
}

@media (max-width: 991px){

	#main-menu {
		> li > a { border-bottom: 4px solid transparent; font-size: 1.4rem; color: #56565a; display: block; padding: 24px 28px; }
	}
	#customer-notes { padding-left: 30px; padding-right: 30px }
	.profile-detail-pane { padding-top: 15px; }
	.rows-header { padding-left: 15px; padding-right: 15px; }
	.address-row .address { padding-left:15px; padding-right:15px; }
	.contact-row .contact { padding-left:30px; padding-right:30px;border-bottom: 1px solid #ccc; }
	.bio { padding: 0 15px; }
	#main .container-fluid.profile {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
}

@media (min-width: 769px) and (max-width:991px){
	.segment { border: none; }

	#form-requirements {
		table {
			tr {
				padding-left: 150px;
				display: block;
				position: relative;
				border-bottom: 1px solid #ccc;

				&:before,
				&:after {content: " "; display: table; }
				&:after {clear: both; }
				td { border-bottom: none;}
			}
			td:first-child {
				position: absolute;
				left: 0;
				top: 0;
				width: 150px;
				~ td {
					display: inline-block;
					width: 50%;
					float: left;
				}
			}
		}
	}

	.zero-margin-sm { margin-left:0 !important; margin-right: 0 !important; }
}

@media (min-width: 769px) {

    .left-bar-segments {
        .segment {
            &:last-child:after {
                border-right: none;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                height: 85%;
                width: 100%;
                border-right: 1px solid #a1a1a1;
                @include middleAlign;
            }
        }
    }

    .full-height-right:after {
        content: '';
        height: 100%;
        position: fixed;
        width: 50%;
        right: 0;
        top: 0;
        background-color: #f7f7f7;
        z-index: -1;
    }

    .full-height-left:after {
        content: '';
        height: 100%;
        position: fixed;
        width: 50%;
        left: 0;
        top: 0;
        background-color: #f7f7f7;
        z-index: -1;
    }

	#main-bar {
        /*overflow: hidden;*/
		height: 70px;
	}

	#main-menu {
		> li:after { content: ''; width: 100%; height: 35px; display: block; bottom: 0; position: absolute; z-index: -1; border-radius: 8px 8px 0 0; background-color: #f5f5f5;}
		li.current:after {background-color: #FFC52F; }
		> li:last-child:after { display: none;}
        > li {padding-left: 0; padding-right: 0;}
		> li > a { border-bottom: 4px solid transparent; font-size: 1.4rem; color: #56565a; display: block; padding: 24px 33px 0; margin-top: 22px; }
	}

	.gold-bar .search-bar {
		> .right, .input-group {
			//top: 50%;
			//@include middleAlign;
		}
	}

	div[class*="-bar"] .right .header-icon button {

	  line-height: 1.2;
	}

	.header-icon {
		&.toggle {
			top: 4px;
		}

		&.folder {
			img { width: 45px; }
		}

		&.file {
			img {width: 29px;}
		}
	}

	.middle-align {
		&.none {
			display: block;
		}
		top: 50%;
		@include middleAlign;
	}

	li.has-dropdown:hover {
		.mega-menu {
			display: block;
		}
	}

	.navbar {
		.bars {
			  display: none !important;
		}
	}

	#search-box {

	 width: 50px;
	}

	#radioshack {

		.left, .none {
			border-bottom: none;
		}

		.col-md-6 {
			border-bottom: 1px solid #ccc;
		}
	}

	.datepicker-box-box > .right {

		padding-left: 10px;
	}
}

@media (max-width: 768px) {

    .left-bar-segments {
        width: 100%;
        .segment {
            width: 100%;
            clear: both;
            text-align: center;
        }
    }

    .gold-bar .segment {
        clear: both;float: left;
        text-align: center; padding: 6px 0;
        width: 100%;
    }

    .gold-bar .search-bar {padding-bottom: 15px;}

    .hidden-sm {display: none !important;}

    .stats-box { float: none; margin: 0 auto; margin-top: 30px; }

    .stats-box .stat .button-box { padding: 0; }

    .stats-box .stat .button-box .button-box-inner { text-align: left; }

    .stats-box .stat { margin: 0 auto; border: none; float: none; }

    .stats-box .stat:before,

    .stats-box .stat:after { content: " "; display: table; }

    .stats-box .stat:after { clear: both; }

    #opportunityName { font-size: 19px;}

    .gold-bar {
		padding-top: 10px;
		padding-bottom: 10px;
	}

    .gold-bar.estimates.files {
		padding-left: 0;
		padding-right: 0;

		>.left {
			padding-left: 15px;
		}

		.header-icon {
			width: 100%;
			clear: both;
			border-top: 1px solid #56565a;
			height: auto;
			padding: 6px 0;

			&.toggle {
				padding: 0;
				button {
					//padding:6px 0;
				}
			}
			.text {
				top: 0;
			}

			img {
				float: right;
		    height: 60%;
		    margin-right: 0;
		    position: absolute;
		    top: 50%;
		    right: 15px;
		    transform: translateY(-50%);
			}

			&.folder {
				padding-left:15px;
				img {
					height: 20px;
    			margin-right: 0;
				}
			}

			&.file {
				padding-left:15px;
				img {
					height: 20px;
	    		margin-right: 0;
				}
			}

			button {
				width: 100%;
				@include gradient(#FFC52F,#FFC52F);
				text-align: left;
				height: 32px;
				line-height: 32px;
				outline: none;
				border-radius: 0;
			}
		}
		> .left,
		> .right {
			float: none;
			width: 100%;
			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }
		}
	}

	.tab-box {
		position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    top: 0;
	}

	.tab-group .tab {

		width: auto !important;
	}

	#masthead .navarea {
		padding-right: 0px;
		width: 100%;
		float: none;
		height: 410px;
	}

	.gold-bar.opportunity-details {
		.none {
	    padding-left: 0;
		  clear: none;
		  float: left;
		  width: 100%;
		}

		.segment {
			width: 100%;
			clear: both;
			padding: 8px 0;
		}
	}

	.crm.company #masthead {

		height: 70px;
	}

	#masthead {
		width: 100%;
		float: left;
		height: auto;

		> .container-fluid {
			padding-left: 0;
			padding-right: 0;

			#search-box, #leftbar {padding-left: 15px; padding-right: 15px;}
		}

		#leftbar.od {
			padding: 0 10px 10px;
			height: auto !important;

			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }
		}

		.opportunity-dropdowns {

	    padding-left: 0;
		  padding-right: 0;
		  float: right;
		  width: 100%;

			.owner-select-box {
				padding-left: 0;
				padding-right: 0;
			cursor:pointer;
				select {font-size: 12px;}
			}
			.status-select-box {
				padding-left: 0;
				padding-right: 0;
				cursor:pointer;
				select {font-size: 12px;}

				.status-bar {
					padding: 0;
				}

			}

			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }

			.none { float: none !important;
				&:before,
				&:after {content: " "; display: table; }
				&:after {clear: both; }
			}
			.right { float: none !important;
				&:before,
				&:after {content: " "; display: table; }
				&:after {clear: both; }
			 }
			.status-bar { margin: 0 !important;}
		}

		.opportunity_name {

			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }
			font-size: 13px;
			padding: 0;

			&:after {
				display: none;
			}
		}

		.col-sm-12 {

			padding: 0;
		}
	}

	.segment:last-child {

		padding-right: 0 !important;
	}

	.navbar {
		.bars {
			  position: absolute;
		    right: 7px;
		    font-size: 21px;
		    margin-top: -1px;
		    padding: 0 8px;
		}
	}

	ul.navbar-nav {
		display: none;
		width: 100%;
    position: absolute;
    top: 30px;
    right: 0;
    max-width: 320px;

		li {
			padding-left: 30px;

			&.dark {
				margin-left: 0;
				padding-left: 30px;
			}

			&.has-dropdown {
				height: auto;
				padding-left: 0;

				> a {
						&.active {

							+ .mega-menu {
								display: block;
							}
						}
						&:before {
							content: '\f107';
	    				font-family: FontAwesome;
	    				position: absolute;
	    				left: 12px;
	    				font-size: 16px;
	    				top: -4px;
						}
				}

				a {
					padding-left:30px;
				}
			}

    	background-color: #56565a;
			clear: both;
			width: 100%;
			display: block;
		}
	}

	.mega-menu {
		margin-left:-50px;
		width: 100%;
		height: auto;
		border-radius: 0;
		display: none;
		position: relative;
		margin-left: -20px;

		.list-header {padding-left:30px;}

		.mega-inner {
			height: auto;

			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }

			&:before { left:30px; }
			div {
				float: left;
				width: 100%;
				ul li {
					background: #fff;
					a {padding-left:30px;}
				}
			}

			.left {
				li { padding-left: 0;}
			}

			.none.empty {
					display: none;
			}

			.gold-button {
				margin: 0;
				text-align: left;
				padding: 0;
				border-radius: 0;
		    a {
		    	padding-left:10px;
		    	&:hover { text-decoration: none; }
		  	}
			}
		}
	}

	.segment {
		padding-left: 0;
		border: none;
		.gold-button {
			font-size: 13px;
			padding-left: 11px;
			padding-right: 11px;
		}
	}

	.dashboard #masthead,
	.crm.opportunities #masthead,
	.crm.contacts #masthead{

		>.container-fluid {

			.segment > .inner {
				top: 0;
				transform: translateY(0);
				-o-transform: translateY(0);
				-ms-transform: translateY(0);
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
			}
		}
	}

	.dashboard #main-bar,
	.crm.opportunities #main-bar {
		float: right;
		width: auto;
	}

	.crm.opportunities {
		.gold-bar {
			height: auto;
		}

		.filters .form-control {
			width: 100%;
			float: left;
			clear: none;
			margin-bottom: 10px;
		}
	}

	.crm.opportunity {

		.contact-company {
			td {
				display: block;
				width: 100%;
				clear: none;
				padding: 5px 0;
			}
		}
	}

	.crm.tasks .gold-bar {
		padding: 15px;
		.middle-align {
			top: 0;
			transform: translate(0);
			-o-transform: translate(0);
			-ms-transform: translate(0);
			-moz-transform: translate(0);
			-webkit-transform: translate(0);
		}
	}

	.crm.tasks #main-bar {
		padding: 10px 0;

		.segment > .inner {
			top: 0;
			transform: translateY(0);
			-o-transform: translateY(0);
			-ms-transform: translateY(0);
			-moz-transform: translateY(0);
			-webkit-transform: translateY(0);
		}
	}

	#main-bar {
		&:before,
		&:after {content: " "; display: table; }
		&:after {clear: both; }
		margin-right: 0;
		float: left;
		width: 100%;
		color: #f5f5f5;
		height: auto;
	}

	.datepicker-box-box {
		float: left;

		strong {
			clear: right;
		}

		> div {
			width: 50%; float: left; display: inline-block;

			input { width: 100%;}
			&:first-child {
				input {width: 95%;}
			}
		}
	}

	.search-group {
		> div:first-child { padding: 4px 0  4px 10px !important; }
		> div, a > div {
			padding-left: 10px !important;
		}
	}

	#search-box {
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	#leftbar {
		float: none;
		overflow: hidden;
		margin-left: 0;
		padding-right: 0;
		padding-left: 0;
        width: 100%;
		.clearfix {
		}
	}

    .estipro.schedule {
        #leftbar {
            .name {
                text-align: center;
            }
            .left {
                float: none;
                width: 100%;
            }
        }
    }

	#main-bar.active {
		#main-menu {
			display: block;
		}
	}

	#main-menu {
		width: 100%;
		margin: 0;
		background-color: #56565a;

		&.active {
			li {
				display: block;
			}
			li.mobile-menu-button {
				display: block;
			}
		}

		li {
			display: none;


			&.mobile-menu-button {
				display: block;
			}
		}

		> li {

			clear: both;
			width: 100%;
			border-bottom: 1px solid #999;
			padding: 0 15px;

			&:last-child { border-bottom: none; }

			&.current,&:focus,&:active {
				> a {
					color: #ffd944;
					font-weight: normal;
				}
			}

			> a {
				padding: 6px 0;
				color: #f5f5f5;
				font-size: 1.3rem;
				border-bottom: none;
			}
		}

		li.mobile-menu-button {

			&:after {
				content: '\f107';
		    display: block;
		    float: left;
		    width: 100%;
		    height: 20px;
		    font-family: 'FontAwesome';
		    color: #fff;
		    text-align: center;
		    padding: 13px 0;
		    font-size: 20px;
		    line-height: 0;
		    z-index: 2;
		    margin-top: 0;
		    position: relative;
		    top: 3px;
			}
		}
	}

	#main .container-fluid {
		> br {display: none;}
		.grey-box.padded {
			border-width: 0 0 1px 0;
			border-radius: 0;
			margin-bottom: 0;
		}
	}

	.color-wheel-container {display: none;}

	#opportunity-requirements {
		padding: 0;

		td {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
		.col-md-12 {
			padding: 0;
		}
	}

	#radioshack {

		> .row {
			margin-left: 0; margin-right: 0;

		}

		.left, .none {
			width: 100%;
			clear: both;
			display: block;
		}

		.left { padding-left: 15px;}

		.col-md-6 {
			&:before,
			&:after {content: " "; display: table; }
			&:after {clear: both; }
			&:first-child {
				background-color: #fff;

			}
			padding-left: 0;padding-right: 0;
		}

		.padded { padding-left: 15px; padding-right: 15px; display: block; }
	}

	#opportunity-details {
			padding-left: 0;
			padding-right: 0;
	}

	#opportunity-notes {
			padding-left: 0;
			padding-right: 0;
	}

	#form-requirements {
		table {
			tr {

				td {
					display: block;
					clear: both;
					width: 100%;
				}
			}
		}
	}

	.slider-box {

		margin : 0;
	}

	.gold-bar {
		height: auto;
	}

	.datepicker-box { display: block;}

	input[name="btn-send-estimate"] {

		float: right;
	}

	.gold-bar .search-bar {

		width: 100%;
	}

	.crm.opportunity #main > .container-fluid > .row > .col-sm-12,
	.crm.opportunity #main > .container-fluid > .row .contact-company {
		padding-left: 0;
		padding-right: 0;
	}

	.contact-row-header table td {padding-left: 15px !important;padding-right: 15px !important;}
}



@media (min-width: 320px) {
    .col-xs {
        -ms-flex-preferred-size:0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xs-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xs-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-xs-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-xs-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xs-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-xs-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-xs-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xs-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-xs-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-xs-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xs-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-xs-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-xs-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xs-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-xs-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-xs-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-xs-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-xs-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-xs-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-xs-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-xs-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-xs-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-xs-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-xs-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-xs-12 {
        -ms-flex-order: 12;
        order: 12
    }
}

.required { color: red; }

#close-search-results {
    color: gray !important;
    position: absolute !important;
    top: 0px !important;
    left: 500px !important;
    z-index: 9999 !important;
}

#tracking-table_wrapper > .row:first-of-type {
    background-color: $gold;
    padding: 5px;
}

.dataTables_wrapper > .row:first-of-type {
    background-color: $gold;
    padding: 5px;
}

table.sticky-header thead th {
  position: sticky;
  top: 0;
  z-index: 10;
  // To not have transparent background.
  // background-color: white;
}

#tracking-table.sticky-header thead th {
    top: 215px;
}

.js-is-sticky {
    z-index: 101;
}

input.edit_inline {
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: auto;
}

input.edit_inline:not(:disabled):hover {
    background-color: lighten($gold, 30%);
}

#projects-dashboard {
    .widget {
        margin-bottom: 20px;
    }

    .exception-report {
        table {
            thead {
                tr {
                    th {
                        background-color: #6e6e6e;
                    }
                }
            }
        }

        label {
            background: transparent !important;
            border: none;
        }
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0px !important;

    &:hover {
        background-color: none !important;
        background: none !important;
        border: none !important;
    }
}

table.plain {
    tbody {
        td {

            font-size: inherit;
            &:first-child {
                padding-left: 0px;
            }
        }
    }
}

@media print {
    a[href]:after {
        content: none;
    }
}

.text-white {
    color: white !important;
}

.p-0 {
    padding: 0px;
}

.pl-0 {
    padding-left: 0px;
}

.sign-search-result {
    cursor: pointer;
}

.milestone-helper-wrapper .nav-tabs li {
    background-color: #e5e5e5;
}

.milestone-helper-wrapper {
    .nav-tabs {
        li.active {
            background-color: #ffc52f;

            a {
                background-color: #ffc52f;
                border: none;
            }
        }
    }
}

.w-25 {
	width: 25% !important;
}

/* t Editable fields */
.t-editable{display:inline-block;border:1px solid #ddd;border-radius:5px;padding:3px;}
.t-editable::before{
	display:inline-block;
	float:right;
	font:normal 14px/1 FontAwesome;
	content: "\f040";
	padding-top:4px;
	padding-left:5px;
	color:#ccc;
}
.t-editable:hover{border-color:#ccc;}
.t-editable:hover::before{color:#aaa;cursor:text;}
.t-editable.editing{border:0;padding:0;}
.t-editable.editing::before{display:none;}
.t-editable.loading input{background:url('/public/img/loading.gif') no-repeat right;}
