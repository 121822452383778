@mixin gradient($color1, $color2) {
	background: #d6d6d6 !important; 
	background: -moz-linear-gradient(top,  $color1 0%, $color2 100%) !important; 
	background: -webkit-linear-gradient(top, $color1 0%, $color2 100%) !important; 
	background: linear-gradient(to bottom,  $color1 0%, $color2 100%) !important; 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0) !important;
}

@mixin middleAlign(){
	transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	display: block;
}

@mixin noSelect(){
	-webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
}